<div *ngIf="products?.length > 0; else noProducts">
  <div *ngFor="let product of products">
    <app-purchase-detail-product-item
      [product]="product"
      [details]="merch"
    ></app-purchase-detail-product-item>
  </div>
</div>
<ng-template #noProducts>
  <p>No hay productos asociados a esta compra</p>
</ng-template>
