import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FileUpload } from "primeng/fileupload";
import { MediaService } from '../../services/media.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  @Input('multiple') multiple: boolean = false;
  @Input('url') url: string[];
  @Input('alt') alt: string;
  @Input('recommendedProp') recommendedProp: string;
  @Input('recommendedHeight') recommendedHeight: string;
  @Output() onUpload: EventEmitter<string> = new EventEmitter();
  @Output() onRemove: EventEmitter<string> = new EventEmitter();
  @ViewChild('fubauto') imageUpload!: FileUpload;
  uploadedFiles: any[] = [];
  uploading: boolean = false;

  constructor(private mediaService: MediaService) {
  }

  ngOnInit(): void {
  }

  myUploader(files: any) {

    this.uploading = true;

    for (let file of files.files) {

      const imageName: string = self.crypto.randomUUID();
      const mimeType = file.type;

      this.mediaService.getSignedUrl(imageName, mimeType).subscribe(signedUrl => {
        this.mediaService.uploadImage(signedUrl.signedUrl, mimeType, file).subscribe(response => {
          this.imageUpload.clear();
          this.uploading = false;
          if (response.status !== undefined && response.status == 200) {
            this.uploadedFiles.push(file);
            this.onUpload.emit(signedUrl.destinationUrl);
          }
        }, () => {
          this.imageUpload.clear();
          this.uploading = false;
        });
      });
    }
  }

  removeImage(image: string): void {
    this.uploadedFiles = [];
    this.imageUpload.clear();
    this.onRemove.emit(image);
  }

}
