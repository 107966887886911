import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PriceChange } from 'src/app/event/model/price-change.interface';
import { PriceUtil } from 'src/app/event/util/price.util';

@Component({
  selector: 'app-price-input',
  templateUrl: './price-input.component.html',
  styleUrls: ['./price-input.component.scss']
})
export class PriceInputComponent implements OnInit, OnChanges {

  @Input() defaultPrice: number;
  @Input() includeTaxes: boolean;
  @Input() fee: number = 0;
  @Input() tax: number = 0;
  @Input() disabled: boolean = false;
  @Output() onPriceChange: EventEmitter<PriceChange> = new EventEmitter();

  ticketPrice: number = 0;
  userPrice: number = 0;
  receivedPrice: number = 0;
  organizerPaysFees: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['fee']) {
      this.onFinalPriceChange();
    }

    if (changes['defaultPrice']) {
      this.ticketPrice = changes['defaultPrice'].currentValue;
      this.onFinalPriceChange();
    }

    if (changes['includeTaxes']) {
      this.organizerPaysFees = changes['includeTaxes'].currentValue;
      this.onFinalPriceChange();
    }
  }

  onFinalPriceChange() {

    const price = this.ticketPrice * 100;

    if (this.organizerPaysFees) {

      this.userPrice = price;
      this.receivedPrice = (price - PriceUtil.applyFees(price, this.fee, this.tax));
    } else {

      this.userPrice = (price + PriceUtil.applyFees(price, this.fee, this.tax));
      this.receivedPrice = price;
    }

    this.onPriceChange.emit({
      basePrice: PriceUtil.getPrice(this.receivedPrice),
      finalPrice: PriceUtil.getPrice(this.userPrice),
      includeTaxes: this.organizerPaysFees
    });

  }

  reset(): void {
    this.ticketPrice = 0;
    this.userPrice = 0;
    this.receivedPrice = 0;
    this.organizerPaysFees = false;
  }

}
