import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { EventStreaming, Stream } from 'src/app/event/model/event-streaming.inferface';
import { EventService } from 'src/app/event/service/event.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-event-detail-streaming',
  templateUrl: './event-detail-streaming.component.html',
  styleUrls: ['./event-detail-streaming.component.scss']
})
export class EventDetailStreamingComponent implements OnInit, OnChanges {

  @Input() eventId: string;
  @Input() streaming: EventStreaming;
  streamingForm: FormGroup;
  streamingTypes = [
    {label: 'YouTube', value: 'youtube'},
  ];

  constructor(private eventService: EventService, private messageService: MessageService, private formBuilder: FormBuilder) {
    this.initializeForm();
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['streaming'] && changes['streaming'].currentValue) {
      this.streaming = changes['streaming'].currentValue;
      this.loadEventStreaming();
    }

  }

  private initializeForm(): void {
    this.streamingForm = this.formBuilder.group({
      type: ['youtube', Validators.required],
      streams: this.formBuilder.array([]),
    });

  }

  get streams(): FormArray {
    return this.streamingForm.get('streams') as FormArray;
  }

  private loadEventStreaming(): void {
    this.streams.clear();
    this.streaming.streams.forEach(stream => {
      this.streams.push(this.createStreamGroup(stream));
    });
  }

  createStreamGroup(stream?: Stream): FormGroup {
    return this.formBuilder.group({
      name: [stream?.name || '', Validators.required],
      code: [stream?.code || '', Validators.required]
    });
  }

  addStreaming(): void {
    this.streams.push(this.createStreamGroup());
  }

  deleteStreaming(index: number): void {
    this.streams.removeAt(index);
  }

  saveStreaming(): void {

    if (this.streamingForm.valid) {
      this.eventService.updateEventStreaming(this.eventId, this.streamingForm.value).subscribe({
        next: () => {
          this.messageService.add({ severity: 'success', summary: 'Guardado con éxito', detail: '' });
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Revisa los datos y vuelve a intentarlo' });
        }
      });
    }
  }

}