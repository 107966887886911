<div *ngIf="tickets?.length > 0; else noTickets">
  <div *ngFor="let ticket of tickets">
    <app-purchase-detail-ticket-item
      [ticket]="ticket"
      [details]="ticketTypes"
    ></app-purchase-detail-ticket-item>
  </div>
</div>
<ng-template #noTickets>
  <p>No hay tickets asociados a esta compra</p>
</ng-template>
