<div *ngIf="displayDialog" class="dimmed-background"></div>

<p-dialog header="Asignar personalización" [(visible)]="displayDialog" [modal]="true"
  [style]="{ width: '50vw', height: '50vh' }" (onHide)="onDialogHide()">
  <form [formGroup]="customizationForm">
    <div class="field">
      <label for="name" class="block">Personalización</label>
      <p-dropdown [options]="experiences" appendTo="body" formControlName="customization" optionLabel="name"
        optionValue="id" placeholder="Selecciona una opción">
        <ng-template let-type pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>
              <p>{{ type.name }}</p>
              <p class="sub">
                {{ type.stock }} disponibles
              </p>
            </div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="field" *ngIf="allowMultiple">
      <label for="quantity" class="block">Cantidad</label>
      <p-inputNumber id="quantity" formControlName="quantity"></p-inputNumber>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button (click)="cancelCustomization()" label="Cancelar" styleClass="p-button-text p-button-danger"></p-button>
    <p-button (click)="confirmCustomization()" label="Asignar personalización" icon="pi pi-send" styleClass="p-button"
      [disabled]="customizationForm.invalid || loading || selectedExperienceStock <= 0"></p-button>
  </ng-template>
</p-dialog>