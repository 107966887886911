export const environment = {
  production: true,
  appName: 'admin',
  apiUrl: 'https://api.nevent.es',
  authUrl: 'https://auth.nevent.es/auth',
  logoutUrl: 'https://auth.nevent.es/logout',
  maps: {
    apiKey: 'AIzaSyCP0bPgMQBjO6HhKGK9B9qKCc8zX7Wm6Rk'
  },
  info: {
    terms: 'https://nevent.es/terms/',
    improvePlan: 'https://nevent.es/formulario-artistas/?source=business'
  },
  cookie: {
    path: '/',
    origin: 'nevent.es',
    secure: true,
    sameSite: 'Lax',
    maxAge: 86400
  },
  sentry: {
    tunnel: "https://api.nevent.es/diagnostics"
  },
  charts: {
    totalSold: '263f6cf3-6e1e-4c2b-b000-cf3c0468cdee',
    totalBilled: 'd2106e2a-4b63-4173-82b5-3a55399ad0ba',
    totalUpselling: '65787cee-067e-4658-8b1b-446740c85de4',
    tokensByType: 'b9fbe7df-71e3-4f06-a8c4-c06993d0ffb3',
    salesByAffiliate: 'e40876bc-0471-4ac3-86d2-389a90ab27e4',
    experiencesByEvent: 'e50fcf42-e51b-4b72-9aae-cc08a473b9d7',
    productsByType: '3487377d-962a-421c-8def-f2fa72d004ef',
    user: {
      chartTotalTokens: 'f550bc75-81a0-406f-b1fd-6f130e7602a1',
      chartTotalPurchases: 'db51a076-e635-4b3a-a30e-d6f4db19ff6e',
      chartTotalPurchased: 'bc684714-cb42-42e5-a247-f7aa45c3375f',
      chartTotalUpselling: 'b7f988a4-e82e-422b-92b1-ccb9904c02d8'
    },
    home: {
      chartTotalFans: '662fbdc7-27e2-4f59-8fa6-9009949ee4ed',
      chartTotalEventsActive: '662fbe01-8720-421d-85ed-45ca9e2a24a2',
      chartTotalEventsBilling: '662fbf0b-b468-4e3d-85a9-7f0eef2b851e',
      chartTokensByEvent: '662fc351-a821-4ac4-83ad-a10232749b95',
      chartBilledByEvent: '662fc1d5-b03a-4c63-8192-caa37a402223',
    }
  },
  analytics: {
    trackingCode: 'G-RCHKV2HLS7'
  }
};
