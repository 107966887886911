<div style="width: 100%;">
    <p-card>
      <ng-template pTemplate="title"> Momentos </ng-template>
      <ng-template pTemplate="subtitle">
        Comparte contenido multimedia con los asistentes y deja un recuerdo para siempre.
      </ng-template>
      <ng-template pTemplate="content">
        <p-fileUpload
          #fileUpload
          [customUpload]="true" 
          name="files" 
          customUpload="true" 
          multiple="multiple" 
          accept="image/*" 
          maxFileSize="50000000"
          dragDrop="true" 
          chooseLabel="Elegir"
          uploadLabel="Subir"
          cancelLabel="Cancelar"
          (uploadHandler)="onUpload($event)"
          (onSelect)="onSelect($event)"
          (onClear)="onClear()"
          [disabled]="isUploading">
          <ng-template pTemplate="content">
            <div *ngIf="!hasFiles" class="file-upload-placeholder">
              <h5>¡Arrastra tus momentos aquí! 😎</h5>
            </div>
          </ng-template>
        </p-fileUpload>

        <div class="flex justify-content-between align-items-center">
          <h3>Momentos subidos</h3>
          <button pButton type="button" label="Borrar todos los momentos" 
                  class="p-button-danger" 
                  (click)="deleteAllMoments()" 
                  [disabled]="isDeletingAll || moments.length === 0">
          </button>
        </div>
        <p-table 
        [value]="moments" 
        [paginator]="true" 
        [rows]="pageSize" 
        [totalRecords]="totalElements"
        [lazy]="true" 
        (onLazyLoad)="onPageChange($event)" 
        [responsive]="true"
        [showFirstLastIcon]="true"
        [rowsPerPageOptions]="[50, 100, 200]"
      >
          <ng-template pTemplate="header">
            <tr>
              <th>Imagen</th>
              <th>Fecha de subida</th>
              <th>Acciones</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-moment>
            <tr>
              <td>
                <img [src]="moment.thumbnailUrl" alt="Moment" style="max-width: 100px; max-height: 100px;">
              </td>
              <td>{{moment.createdAt | date:'medium'}}</td>
              <td>
                <button pButton type="button" icon="pi pi-trash" class="p-button-text p-button-danger" (click)="deleteMoment(moment.id)" [disabled]="isDeleting(moment.id)"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </ng-template>
    </p-card>
  </div>

  <div *ngIf="isUploading || isDeletingAll" class="loading-overlay">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <p>{{isUploading ? uploadProgress : 'Borrando todos los momentos...'}}</p>
  </div>