<p-dialog header="Crear usuario" [modal]="true" [(visible)]="displayCreateDialog"
    [style]="{ width: '50vw', height: '80vh' }" (onHide)="onDialogHide()">

    <div>
        <form [formGroup]="createForm">
            <div class="field">
                <div class="field mt-5">
                    <span class="p-float-label">
                        <input id="email" type="email" aria-describedby="email-help" pInputText formControlName="email"
                            class="w-full" />
                        <label for="email" class="form-label">Email *</label>
                    </span>
                </div>
                <div class="field mt-5">
                    <span class="p-float-label">
                        <input id="name" type="name" aria-describedby="name-help" pInputText formControlName="name"
                            class="w-full" />
                        <label for="name" class="form-label">Nombre</label>
                    </span>
                </div>
                <div class="field mt-5">
                    <span class="p-float-label">
                        <input id="surname1" type="surname" aria-describedby="surname1-help" pInputText
                            formControlName="surname1" class="w-full" />
                        <label for="surname1" class="form-label">Apellidos</label>
                    </span>
                </div>
                <div class="field mt-5">
                    <span class="p-float-label">
                        <input id="fiscalId" type="fiscalId" aria-describedby="fiscalId-help" pInputText
                            formControlName="fiscalId" class="w-full" />
                        <label for="fiscalId" class="form-label">Identificador fiscal (DNI / NIF / NIE)</label>
                    </span>
                </div>
                <div class="field mt-5">
                    <span class="p-float-label">
                        <input id="phone" type="phone" aria-describedby="phone-help" pInputText formControlName="phone"
                            class="w-full" />
                        <label for="phone" class="form-label">Teléfono</label>
                    </span>
                </div>
                <div class="field mt-5">
                    <span class="p-float-label">
                        <input id="instagram" type="instagram" aria-describedby="instagram-help" pInputText
                            formControlName="instagram" class="w-full" />
                        <label for="instagram" class="form-label">Instagram</label>
                    </span>
                </div>
                <div class="field mt-5">
                    <span class="p-float-label">
                        <input id="twitter" type="twitter" aria-describedby="twitter-help" pInputText
                            formControlName="twitter" class="w-full" />
                        <label for="twitter" class="form-label">Twitter</label>
                    </span>
                </div>
                <div class="field mt-5">
                    <span class="p-float-label">
                        <input id="linkedin" type="linkedin" aria-describedby="linkedin-help" pInputText
                            formControlName="linkedin" class="w-full" />
                        <label for="linkedin" class="form-label">LinkedIn</label>
                    </span>
                </div>
                <div class="field mt-5">
                    <p-checkbox id="inviteMail" [binary]="true" styleClass="mr-2"
                        formControlName="inviteMail"></p-checkbox>
                    <label for="inviteMail" class="form-label">Enviar email de invitación</label>
                </div>
            </div>

            <button pButton pRipple *ngIf="!loading" [disabled]="!createForm.valid" label="Añadir contenido"
                class="w-full" (click)="addUser()"></button>
            <button pButton pRipple *ngIf="loading" [disabled]="loading" icon="pi pi-spin pi-spinner" iconPos="right"
                label="Añadir contenido" class="w-full"></button>
            <div style="color: red" [innerHTML]="errorMessage"></div>
        </form>
    </div>
</p-dialog>