<p-card>
  <ng-template pTemplate="title">Perfil público</ng-template>
  <ng-template pTemplate="subtitle">Datos públicos del fan</ng-template>
  <div class="flex flex-column">
    <div>
      <span class="mb-2 font-semibold">Headline</span>
      <p *ngIf="user?.headline; else empty">{{ user?.headline }}</p>
    </div>
    <div>
      <span class="mb-2 font-semibold">Bio</span>
      <p *ngIf="user?.description; else empty">{{ user?.description }}</p>
    </div>
    <div>
      <span class="mb-2 font-semibold">LinkedIn</span>
      <p *ngIf="user?.linkedin; else empty">{{ user?.linkedin }}</p>
    </div>
    <div>
      <span class="mb-2 font-semibold">Twitter</span>
      <p *ngIf="user?.twitter; else empty">{{ user?.twitter }}</p>
    </div>
    <div>
      <span class="mb-2 font-semibold">Instagram</span>
      <p *ngIf="user?.instagram; else empty">{{ user?.instagram }}</p>
    </div>
  </div>
  <ng-template #empty>
    <p>Desconocido</p>
  </ng-template>
</p-card>