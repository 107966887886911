import { Component, Input, OnInit } from '@angular/core';
import { Purchase, PurchaseState } from '../../model/purchase.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-purchase-history-item',
  templateUrl: './purchase-history-item.component.html',
  styleUrls: ['./purchase-history-item.component.scss']
})
export class PurchaseHistoryItemComponent implements OnInit {

  @Input() purchase: Purchase;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  getSeverity(state: PurchaseState): string {

    if (state === PurchaseState.COMPLETE || state === PurchaseState.SUCCEEDED) return 'success';
    if (state === PurchaseState.FAILED) return 'danger';

    return 'info';
  }

  openPurchaseDetail(): void {
    this.router.navigate([this.purchase.userId, 'purchases', this.purchase.id]);
  }

}
