export class EventState {

    static getState(state: string): string {
        
        switch (state) {
            case "ACTIVE":
                return "Programado";
            case "PAUSED":
                return "Pausado";
            case "SOLD_OUT":
                return "Vendido";
            case "CANCELLED":
                return "Cancelado";
            case "DRAFT":
                return "Borrador";
            default:
                return "Activo";
        }
    }

    static getSeverity(state: string): string {

        switch (state) {
            case "ACTIVE":
                return 'success';
            case "PAUSED":
                return 'danger';
            case "SOLD_OUT":
                return 'warning';
            case "CANCELLED":
                return 'danger';
            default:
                return 'info';
        }
    }
}