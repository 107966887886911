<p-table
  #couponTable
  [value]="coupons"
  responsiveLayout="scroll"
  styleClass="p-datatable-sm"
  [globalFilterFields]="['name', 'creationTime', 'code']"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <button
        pButton
        pRipple
        type="button"
        label="Añadir cupón"
        icon="pi pi-plus"
        (click)="addCoupon()"
      ></button>
      <button
        pButton
        label="Limpiar Filtros"
        class="p-button-outlined ml-3"
        icon="pi pi-filter-slash"
        (click)="clear(couponTable)"
      ></button>
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (input)="couponTable.filterGlobal($event.target.value, 'contains')"
          placeholder="Search"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="code">
        Código de cupón
        <p-sortIcon field="code"></p-sortIcon>
        <p-columnFilter type="text" field="code" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="name">
        Nombre de cupón
        <p-sortIcon field="name"></p-sortIcon>
        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="creationTime">
        Fecha de creación
        <p-sortIcon field="creationTime"></p-sortIcon>
        <p-columnFilter type="date" field="creationTime" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="currentUses">
        Número de usos
        <p-sortIcon field="currentUses"></p-sortIcon>
        <p-columnFilter type="text" field="currentUses" display="menu"></p-columnFilter>
      </th>
      <th>Acciones</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-coupon>
    <tr>
      <td><span class="coupon-code">{{ coupon.code }}</span></td>
      <td>{{ coupon.name }}</td>
      <td>{{ coupon.createdAt | date:'medium' }}</td>
      <td>{{ coupon.usesLimit > 0 ? coupon.currentUses + ' / ' + coupon.usesLimit : coupon.currentUses + ' / ∞' }}</td>
      <td>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-copy"
          class="p-button-rounded p-button-text"
          (click)="copyCoupon(coupon.code)"
          pTooltip="Copiar al portapapeles"
          tooltipPosition="top"
        ></button>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-pencil"
          class="p-button-rounded p-button-text"
          (click)="editCoupon(coupon)"
          pTooltip="Editar cupón"
          tooltipPosition="top"
        ></button>
        <button
        pButton
        pRipple
        type="button"
        icon="pi pi-trash"
        class="p-button-rounded p-button-danger p-button-text"
        (click)="deleteCoupon(coupon.id)"
        pTooltip="Borrar cupón"
        tooltipPosition="top"
      ></button>
      </td>
    </tr>
  </ng-template>
</p-table>

<app-add-coupon-dialog
  [displayDialog]="displayAddCouponDialog"
  [couponToEdit]="selectedCoupon"
  [experiences]="experiences"
  [ticketTypes]="ticketTypes"
  [complements]="complements"
  [existingCoupons]="coupons"
  (hideDialog)="hideAddCouponDialog()"
  (saveCoupon)="onCouponSaved($event)"
></app-add-coupon-dialog>
