<div class="grid mb-4">
    <div class="col-6">
      <div class="grid h-full">
        <div class="col-4" *ngFor="let kpi of kpiData">
          <p-card styleClass="h-full">
            <div class="kpi-card">
              <div class="kpi-title">{{ kpi.title }}</div>
              <div class="kpi-value">{{ kpi.value }}</div>
            </div>
          </p-card>
        </div>
      </div>
    </div>
    <div class="col-6">
      <p-card styleClass="h-full">
        <div class="chart-container">
          <div class="chart-header">
            <h4 class="chart-title">Cronología de ventas</h4>
            <p class="chart-subtitle">Ventas a lo largo del tiempo</p>
          </div>
          <div #chart class="chart" style="height: 200px;"></div>
        </div>
      </p-card>
    </div>
  </div>

  <!-- Charts Row 1 -->
  <div class="grid mb-4">
    <div class="col-6">
      <p-card>
        <div class="chart-container">
          <div class="chart-header">
            <h4 class="chart-title">Resumen de tickets</h4>
            <p class="chart-subtitle">Tickets vendidos por tipo</p>
          </div>
          <div #ticketsChart class="tickets-chart" style="height: 300px;"></div>
        </div>
      </p-card>
    </div>
    <div class="col-6">
      <p-card>
        <div class="chart-container">
          <div class="chart-header">
            <h4 class="chart-title">Resumen de personalización</h4>
            <p class="chart-subtitle">Personalizaciones vendidas por tipo</p>
          </div>
          <div #upgradesChart class="upgrades-chart" style="height: 300px;"></div>
        </div>
      </p-card>
    </div>
  </div>

  <!-- Charts Row 2 -->
  <div class="grid">
    <div class="col-6">
      <p-card>
        <div class="chart-container">
          <div class="chart-header">
            <h4 class="chart-title">Resumen de merchandising</h4>
            <p class="chart-subtitle">Merchandising vendido por tipo</p>
          </div>
          <div #merchChart class="merch-chart" style="height: 300px;"></div>
        </div>
      </p-card>
    </div>
    <div class="col-6">
      <p-card>
        <div class="chart-container">
          <div class="chart-header">
            <h4 class="chart-title">Ventas por afiliados</h4>
            <p class="chart-subtitle">Total vendido por tus afiliados</p>
          </div>
          <div #rpSalesChart class="rp-sales-chart" style="height: 300px;"></div>
        </div>
      </p-card>
    </div>
  </div>