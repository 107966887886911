<div class="message-container">
    <!-- Columna de Fecha y Hora -->
    <div class="flex-none date-time">
        <p class="date">{{ message.publishedAt | date: 'shortDate' }}</p>
        <p class="time">{{ message.publishedAt | date: 'shortTime' }}</p>
    </div>
    <!-- Columna de Contenido -->
    <div class="flex-grow-1 ml-3 content">
        <span [innerHTML]="message.content"></span>
    </div>
    <!-- Columna de Acciones -->
    <div class="flex-none ml-3 actions">
        <button pButton type="button" icon="pi pi-trash" (click)="deleteMessage()" class="delete-button"></button>
    </div>
</div>