<div class="grid">
  <!-- Primera columna: Tabla de asistentes, siempre visible -->
  <div class="col-8 p-3 attendants-table-container"
    [ngClass]="{'col-12': !selectedAttendant, 'col-8': selectedAttendant}">
    <p-table
      #attendantsTable
      [value]="loading ? skeletonRows : attendants" 
      [lazy]="true"
      [paginator]="true"
      [rows]="pageSize"
      [totalRecords]="totalRecords"
      (onLazyLoad)="onLazyLoad($event)"
      selectionMode="single"
      [(selection)]="selectedAttendant"
      (onRowSelect)="openUser($event)"
      responsiveLayout="scroll"
      scrollable="true"
      scrollHeight="flex"
      styleClass="p-datatable-sm"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} asistentes"
      [rowsPerPageOptions]="rowsPerPageOptions"
      [globalFilterFields]="[
        'ticketId',
        'ticketTypeId',
        'email',
        'name',
        'purchasedAt',
        'validated'
      ]"
      dataKey="ticketId"
      [sortField]="sortField"
      [sortOrder]="sortOrder"
      >
      <ng-template pTemplate="caption">
        <div class="flex">
          <button [disabled]="blockDownload" pButton pRipple type="button"
            [label]="selectedAttendant?'':'Exportar listado'" icon="pi pi-file-excel" (click)="exportList()"
            pTooltip="Exportar listado" tooltipPosition="bottom"></button>
          <button pButton pRipple class="ml-3" type="button" [label]="selectedAttendant?'': 'Invitar asistentes'"
            icon="pi pi-plus" (click)="showInviteDialog()" pTooltip="Invitar asistentes"
            tooltipPosition="bottom"></button>
            <span class="p-input-icon-left w-21rem ml-auto">
              <i class="pi pi-search"></i>
              <input 
                pInputText 
                type="text" 
                class="w-full"
                [value]="searchTerm"
                (input)="onSearch($event)" 
                placeholder="Buscar por nombre, email y/o tipo" />
            </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="email">
            Email <p-sortIcon field="email"></p-sortIcon>
          </th>
          <th pSortableColumn="name">
            Nombre <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="ticketTypeId">
            Tipo <p-sortIcon field="ticketTypeId"></p-sortIcon>
          </th>
          <th pSortableColumn="purchasedAt" *ngIf="!selectedAttendant">
            Fecha <p-sortIcon field="purchasedAt"></p-sortIcon>
          </th>
          <th pSortableColumn="validated" *ngIf="!selectedAttendant">
            Asistencia
            <p-sortIcon field="validated"></p-sortIcon>
          </th>
          <th *ngIf="!selectedAttendant"></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-attendant>
        <tr [pSelectableRow]="attendant">
          <td>
            <div *ngIf="loading">
              <p-skeleton width="100%" height="24px"></p-skeleton>
            </div>
            <ng-container *ngIf="!loading">
              <a [routerLink]="['/users/' + attendant.userId]">{{ attendant.email }}</a>
            </ng-container>
          </td>
          <td>
            <div *ngIf="loading">
              <p-skeleton width="100%" height="24px"></p-skeleton>
            </div>
            <ng-container *ngIf="!loading">{{ attendant.fullName }}</ng-container>
          </td>
          <td>
            <div *ngIf="loading">
              <p-skeleton width="80%" height="24px"></p-skeleton>
            </div>
            <ng-container *ngIf="!loading">{{ attendant.ticketTypeName }}</ng-container>
          </td>
          <td *ngIf="!selectedAttendant">
            <div *ngIf="loading">
              <p-skeleton width="100%" height="24px"></p-skeleton>
            </div>
            <ng-container *ngIf="!loading">{{ attendant.purchasedAt | date : "short" }}</ng-container>
          </td>
          <td *ngIf="!selectedAttendant">
            <div *ngIf="loading">
              <p-skeleton width="40px" height="24px"></p-skeleton>
            </div>
            <div *ngIf="!loading" class="flex justify-content-center">
              <i *ngIf="attendant.validated === true" [pTooltip]="attendant.validatedAt | date : 'dd/MM/yyyy HH:mm'"
                tooltipPosition="top" class="pi pi-check" style="color: green; font-weight: 600"></i>
              <i *ngIf="attendant.validated === false" class="pi pi-clock" style="color: orange; font-weight: 600"
                pTooltip="Pendiente" tooltipPosition="top"></i>
            </div>
          </td>
          <td>
            <div *ngIf="loading">
              <p-skeleton width="40px" height="24px"></p-skeleton>
            </div>
            <ng-container *ngIf="!loading">
              <i class="pi pi-angle-right"></i>
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">Todavía no hay asistentes.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="col-4 detail-panel-container">
    <app-attendant-detail-panel [eventId]=" eventId" [selectedAttendant]="selectedAttendant" (assistantDeleted)="onAssistantDeleted($event)">
    </app-attendant-detail-panel>
  </div>

  <p-sidebar [(visible)]="showInvite" position="right" [baseZIndex]="10000" styleClass="custom-sidebar"
    [style]="{ width: '50vw' }" (onHide)="cancelInvite()">
    <ng-template pTemplate="header">
      <h3>Invitar asistentes</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form [formGroup]="inviteForm" class="p-fluid">
        <div class="p-field-checkbox">
          <p-checkbox
            formControlName="notifyUser"
            binary="true"
            inputId="notifyUser"
            label="Quiero enviar una notificación a los usuarios seleccionados para que sepan que están siendo invitados"
          ></p-checkbox>
        </div>
        <!-- Invitation Type Selector -->
        <div class="field mt-5">
          <span class="p-float-label">
            <p-dropdown [options]="invitationTypes" formControlName="invitationType" placeholder="Selecciona un tipo"
              optionLabel="name" optionValue="id"></p-dropdown>
            <label for="invitationType">Tipo de Invitación</label>
          </span>
        </div>
        <div class="field mt-5">
          <span class="p-float-label">
            <p-dropdown [options]="getFilteredTicketTypes()" appendTo="body" formControlName="ticketType"
              optionLabel="name" optionValue="id" placeholder="Selecciona una opción">
              <ng-template let-type pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div>
                    <p>{{ type.name }}</p>
                    <p class="sub">
                      {{ type.ticketsNumber - type.ticketsSold }} disponibles
                    </p>
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
            <label for="name" class="block">Tipo de entrada</label>
          </span>
        </div>
        <!-- Conditional UI based on the selected invitation type -->
        <section *ngIf="inviteForm.get('invitationType')?.value === 'individual'">
          <div class="field mt-5">
            <span class="p-float-label">
              <p-autoComplete [(ngModel)]="selectedUser" [suggestions]="results" (completeMethod)="search($event)"
                (onSelect)="selectResult($event)" field="email">
                <ng-template let-value pTemplate="item">
                  <div class="flex flex-column">
                    <span class="form-header">{{value.fullName}}</span>
                    <span class="form-label">{{value.email}}</span>
                  </div>
                </ng-template>
              </p-autoComplete>
              <label for="name" class="form-label">Buscar usuario por email</label>
            </span>
          </div>
        </section>
        <section *ngIf="inviteForm.get('invitationType')?.value === 'bulk'">
          <div>
            <h4>Paso 1: Prepara la subida</h4>
            <p style="color: red">Asegúrate que el número de asistentes es menor o igual al número de entradas
              disponibles.</p>
            <p>Formatos permitidos: csv</p>
            <p>Cabecera del CSV: name,surname1,surname2,email,phone</p>
            <p>Campos obligatorios: email</p>
            <button pButton pRipple label="Descarga la plantilla CSV" icon="pi pi-download" type="button"
              class="p-button-text" (click)="downloadTemplate()"></button>
          </div>
          <div>
            <h4>Paso 2: Sube tu fichero</h4>
            <div class="field">
              <p-fileUpload name="myfile[]" mode="basic" accept="text/csv" chooseLabel="Seleccionar fichero"
                [auto]="true" [customUpload]="true" (uploadHandler)="myUploader($event)"></p-fileUpload>
              <p *ngIf="file">Archivo seleccionado: {{ file?.name }}</p>
            </div>
          </div>
        </section>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button (click)="confirmInvite(inviteForm.get('invitationType')?.value)" label="Enviar invitacion"
        icon="pi pi-send" styleClass="p-button" [disabled]="!inviteForm.valid"></p-button>
    </ng-template>
  </p-sidebar>
</div>
