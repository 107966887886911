export interface Purchase {
    id: string;
    eventName: string;
    state: PurchaseState;
    movements: any[];
    tickets: any[];
    ticketOwners: any[];
    products: any[];
    experiences: any[];
    totalPrice: number;
    createdAt: Date;
    purchasedAt: Date;
    paymentMethod: string;
    eventId: string;
    userId: string;
}

export enum PurchaseState {
    PENDING = "PENDING",
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED",
    COMPLETE = "COMPLETE"
}