import { Component, Input, OnChanges, OnInit, Sanitizer, SimpleChange, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { EventService } from '../../service/event.service';
import { CreatedEntity } from '../../model/created-entity.interface';

@Component({
  selector: 'app-event-qr',
  templateUrl: './event-qr.component.html',
  styleUrls: ['./event-qr.component.scss']
})
export class EventQrComponent implements OnChanges {

  @Input() eventId: string;
  @Input() filename: string;
  @Input() showImage: boolean;

  eventQRCode: SafeResourceUrl;

  constructor(private eventService: EventService, private sanitizer: DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventId'] && changes['eventId'].currentValue) {
      this.eventService.code(this.eventId).subscribe((response: CreatedEntity) => {
        this.eventQRCode = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + response.created);
      });
    }
  }

}
