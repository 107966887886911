export class PriceUtil {

    /**
     * Format price to be stored in database
     * @param originalPrice the original price
     * @returns the price to be stored in database
     */
    static getPrice(originalPrice: number): number {

        const truncated = Math.trunc(originalPrice);

        return truncated;
    }

    static applyFees(price: number, fee: number, tax: number): number {

        const fees = Math.trunc(price * (fee / 100));
        const taxes = Math.trunc(fees * (tax / 100));

        return fees + taxes;

    }

    /**
     * Format price to be displayed
     * @param originalPrice the price stored on database
     * @returns the price to be displayed
     */
    static toDisplay(originalPrice: number): number {

        return originalPrice / 100;
    }
}
