import { Component, Input, OnInit } from '@angular/core';
import { Table } from 'primeng/table';
import { Sale } from 'src/app/event/model/sale.interface';
import { DeliveryService } from '../../services/delivery.service';
import { MessageService } from 'primeng/api';
import { ExportResponse } from '../../model/export-response.interface';
import { MerchService } from 'src/app/merch/service/merch.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-sale-delivery-table',
  templateUrl: './sale-delivery-table.component.html',
  styleUrls: ['./sale-delivery-table.component.scss']
})
export class SaleDeliveryTableComponent implements OnInit {

  displayAddProductDialog: boolean = false; 

  blockDownload: boolean = false;
  merchDeliveryData: any;
  showDeliveryDialog: boolean = false;

  @Input() eventId: string;
  @Input() sales: Sale[];
  @Input() tokenId: string;

  loading = false;
  skeletonRows = Array(25).fill({});
  totalRecords: number = 0;
  rows: number = 25;
  first: number = 0;
  rowsPerPageOptions = [25, 50, 100];

  constructor(private deliveryService: DeliveryService, private messageService: MessageService, private merchService: MerchService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const page = parseInt(params['page'] || '0');
      this.rows = parseInt(params['size'] || '25');
      this.first = page * this.rows;
      this.loadSales();
    });
  }

  loadSales(): void {
    this.loading = true;
    const page = Math.floor(this.first / this.rows);
    
    this.deliveryService.retrieveSales(this.eventId, page, this.rows)
      .pipe(finalize(() => this.loading = false))
      .subscribe(response => {
        if ('content' in response) {
          this.sales = response.content;
          console.log(this.sales);
          this.totalRecords = response.totalElements;
        }
      });
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
    
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { 
        page: Math.floor(event.first / event.rows),
        size: event.rows
      },
      queryParamsHandling: 'merge'
    });
  }

  deliver(deliveryId: string): void {

    this.deliveryService.deliverMerch(deliveryId).subscribe(() => {

      this.messageService.add({ severity: 'success', summary: 'Producto entregado', detail: '' });
      const saleIndex = this.sales.findIndex(a => a._id === deliveryId);

      this.sales[saleIndex] = {
        _id: this.sales[saleIndex]._id,
        tokenId: this.sales[saleIndex].tokenId,
        purchaseId: this.sales[saleIndex].purchaseId,
        productId: this.sales[saleIndex].productId,
        name: this.sales[saleIndex].name,
        quantity: this.sales[saleIndex].quantity,
        variant: this.sales[saleIndex].variant,
        ownedBy: this.sales[saleIndex].ownedBy,
        ownedByName: this.sales[saleIndex].ownedByName,
        ownedByEmail: this.sales[saleIndex].ownedByEmail,
        delivered: true,
        deliveredAt: new Date()
      }
    }, () => {
      this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: '' });
    });
  }

  undeliver(deliveryId: string): void {

    this.messageService.add({ severity: 'success', summary: 'Se ha deshecho la entrega', detail: '' });
    this.deliveryService.undeliverMerch(deliveryId).subscribe(() => {
      const saleIndex = this.sales.findIndex(a => a._id === deliveryId);

      this.sales[saleIndex] = {
        _id: this.sales[saleIndex]._id,
        tokenId: this.sales[saleIndex].tokenId,
        purchaseId: this.sales[saleIndex].purchaseId,
        productId: this.sales[saleIndex].productId,
        name: this.sales[saleIndex].name,
        quantity: this.sales[saleIndex].quantity,
        variant: this.sales[saleIndex].variant,
        ownedBy: this.sales[saleIndex].ownedBy,
        ownedByName: this.sales[saleIndex].ownedByName,
        ownedByEmail: this.sales[saleIndex].ownedByEmail,
        delivered: false,
        deliveredAt: undefined
      }
    }, () => {
      this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: '' });
    });
  }

  clear(table: Table) {
    table.clear();
  }

  exportList(): void {
    this.blockDownload = true;
    this.deliveryService.export(this.eventId).subscribe((response: ExportResponse) => {
      window.open(response.url, '_blank');
      this.blockDownload = false;
    }, () => {
      this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: '' });
      this.blockDownload = false;
    })
  }

  showInfo(sale: Sale): void {
    this.merchService.getMerchDeliveryInfo(sale.productId, sale.purchaseId).subscribe((response: any) => {
      this.merchDeliveryData = response;
      this.showDeliveryDialog = true;
    });
  }

  hideAddProductDialog(): void {
    this.displayAddProductDialog = false;
  }

}
