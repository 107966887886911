import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContentUpload } from 'src/app/shared/model/content-upload.interface';
import { environment } from 'src/environments/environment';
import { JobStatus } from '../../model/job-status.interface';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent implements OnInit, OnDestroy {

  @Input() displayImportDialog: boolean;
  @Output() hideDialog: EventEmitter<void> = new EventEmitter();

  file: File;
  errorMessage: string;
  uploadJob: ContentUpload;
  intervalId: any;
  jobStatus: JobStatus;
  loading: boolean;

  importForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    mediaType: new FormControl('', [Validators.required]),
    size: new FormControl('', [Validators.required])
  });

  constructor(private userService: UserService) {

  }

  ngOnInit(): void {

  }

  addContent(): void {

    this.loading = true;

    this.userService.getSignedURL().subscribe((response: ContentUpload) => {
      this.uploadJob = response;
      if (this.uploadJob.signedUrl !== undefined) {
        this.userService.uploadContent(response.signedUrl, this.file).subscribe((response: any) => {
          if (response.status !== undefined && response.status == 200) {
            this.userService.executeImport(this.uploadJob.id, null, null, false).subscribe(() => {
              const intervalId = setInterval(() => {

                this.userService.getImportStatus(this.uploadJob.id).subscribe((response: JobStatus) => {
                  this.jobStatus = response;
                  if (response.state === 'FINISH_OK' || response.state === 'FINISH_KO') {
                    clearInterval(intervalId);
                    this.hideDialog.emit();
                    this.loading = false;
                  }
                }, () => {
                  clearInterval(intervalId);
                  this.loading = false;
                });

              }, 3000);
              this.intervalId = intervalId;
            }, (err: HttpErrorResponse) => {
              this.loading = false;
              switch (err.status) {
                case 409:
                  this.errorMessage = '';
                  break;
                case 413:
                  this.errorMessage = ``
                  break;
                default:
                  this.errorMessage = '';
                  break;
              }
              // Handle errors
              console.log(err);
            })
          }
        }, () => this.loading = false);
      } else {
        // If the back-end could not generate a signed url for any reason, disable loading
        this.loading = false;
      }
    }, () => this.loading = false);

  }

  myUploader(files: any): void {

    this.file = files.files[0];

    this.importForm.patchValue({
      name: this.file.name,
      mediaType: this.file.type,
      size: this.file.size
    });
  }

  downloadTemplate(): void {
    window.open('/assets/templates/import.csv', '_blank');
  }

  onDialogHide(): void {
    this.displayImportDialog = false;
    this.hideDialog.emit(undefined);
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}
