<div>
  <p-inputNumber
    id="regularPrice"
    mode="currency"
    currency="EUR"
    locale="es-ES"
    class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full"
    [(ngModel)]="ticketPrice"
    (ngModelChange)="onFinalPriceChange()"
    [ngModelOptions]="{ updateOn: 'change' }"
    [disabled]="disabled"
  ></p-inputNumber>
  <small
    >Tu recibes
    <b>{{ receivedPrice / 100 | currency : "EUR" : "symbol" : "1.2-2" }}</b> y
    el usuario pagará
    <b>{{ userPrice / 100 | currency : "EUR" : "symbol" : "1.2-2" }}</b></small
  >
</div>
<div class="flex align-items-center justify-content-between mb-6 mt-3">
  <div class="flex align-items-center">
    <p-checkbox
      id="organizerPaysFees"
      [binary]="true"
      styleClass="mr-2"
      [(ngModel)]="organizerPaysFees"
      (onChange)="onFinalPriceChange()"
      [disabled]="disabled"
    ></p-checkbox>
    <label for="organizerPaysFees" class="text-900"
      >Incluir gastos de gestión en el precio</label
    >
  </div>
</div>
