import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EChartsOption } from 'echarts';
import { getLoggedInUser } from 'src/app/auth/store';
import { EventSummary } from '../../model/event-summary.interface';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-detail-summary',
  templateUrl: './event-detail-summary.component.html',
  styleUrls: ['./event-detail-summary.component.scss']
})
export class EventDetailSummaryComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('chartSalesStatus') chartSalesStatusContainer: ElementRef;
  @ViewChild('chartTotalBilled') chartTotalBilledContainer: ElementRef;
  @ViewChild('chartTotalUpselling') chartTotalUpsellingContainer: ElementRef;
  @ViewChild('chartTokensByType') chartTokensByTypeContainer: ElementRef;
  @ViewChild('chartSalesByAffiliate') chartSalesByAffiliateContainer: ElementRef;
  @ViewChild('chartExperiencesByType') chartExperiencesByTypeContainer: ElementRef;
  @ViewChild('chartProductsByType') chartProductsByTypeContainer: ElementRef;

  @Input() eventId: string;

  organizationId: string;

  selectedTimeframe = 'all-time';
  selectedReport = 'general';

  summary: EventSummary;

  sdk: ChartsEmbedSDK;

  timeframeOptions = [
    { label: 'Todos los tiempos', value: 'all-time' },
    { label: 'Último día', value: 'last-day' },
    { label: 'Últimos 3 días', value: 'last-3-days' },
    { label: 'Última semana', value: 'last-week' },
    { label: 'Último mes', value: 'last-month' }
  ];

  reportOptions = [
    { label: 'Resumen general', value: 'general' },
    { label: 'Mercado secundario', value: 'secondary' }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {
    this.sdk = new ChartsEmbedSDK({
      baseUrl: "https://charts.mongodb.com/charts-project-0-jikbu"
    });
  }

  ngOnInit(): void {

    this.store.select(getLoggedInUser).subscribe(observer => {
      this.organizationId = observer?.id;
    });

    this.route.queryParams.subscribe(params => {
      if (params['timeframe']) {
        this.selectedTimeframe = params['timeframe'];
      }
      if (params['report']) {
        this.selectedReport = params['report'];
      }
      this.loadData();
    });
  }

  ngAfterViewInit(): void {
    if (this.eventId) {
      this.refreshCharts();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['eventId'] && changes['eventId'].currentValue) {

      this.eventId = changes['eventId'].currentValue;

      if (this.eventId) {
        this.refreshCharts();
      }
    }

  }

  refreshCharts(): void {

    this.loadChartSalesStatus();
    this.loadChartTotalBilled();
    this.loadChartTotalUpselling();
    this.loadChartTokensByType();
    this.loadChartSalesByAffiliate();
    this.loadChartExperiencesByType();
    this.loadChartProductsByType();
  }

  private async loadChartProductsByType() {

    if (this.chartProductsByTypeContainer) {

      const chartProductsByType = this.sdk.createChart({
        chartId: environment.charts.productsByType,
        height: "400px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartProductsByType.render(this.chartProductsByTypeContainer.nativeElement);
    }
  }

  private async loadChartExperiencesByType() {

    if (this.chartExperiencesByTypeContainer) {

      const chartExperiencesByType = this.sdk.createChart({
        chartId: environment.charts.experiencesByEvent,
        height: "400px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartExperiencesByType.render(this.chartExperiencesByTypeContainer.nativeElement);
    }
  }

  private async loadChartSalesByAffiliate() {

    if (this.chartSalesByAffiliateContainer) {
      const chartSalesByAffiliate = this.sdk.createChart({
        chartId: environment.charts.salesByAffiliate,
        height: "400px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartSalesByAffiliate.render(this.chartSalesByAffiliateContainer.nativeElement);
    }
  }

  private async loadChartTokensByType() {
    if (this.chartTokensByTypeContainer) {
      const chartTokensByType = this.sdk.createChart({
        chartId: environment.charts.tokensByType,
        height: "400px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartTokensByType.render(this.chartTokensByTypeContainer.nativeElement);
    }
  }

  private async loadChartTotalUpselling() {
    if (this.chartTotalUpsellingContainer) {
      const chartTotalUpselling = this.sdk.createChart({
        chartId: environment.charts.totalUpselling,
        height: "200px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartTotalUpselling.render(this.chartTotalUpsellingContainer.nativeElement);
    }
  }

  private async loadChartTotalBilled() {
    if (this.chartTotalBilledContainer) {
      const chartTotalBilled = this.sdk.createChart({
        chartId: environment.charts.totalBilled,
        height: "200px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartTotalBilled.render(this.chartTotalBilledContainer.nativeElement);
    }
  }

  private async loadChartSalesStatus(): Promise<void> {
    if (this.chartSalesStatusContainer) {
      const chartSalesStatus = this.sdk.createChart({
        chartId: environment.charts.totalSold,
        height: "200px",
        showAttribution: false,
        filter: { "eventId": this.eventId }
      });

      chartSalesStatus.render(this.chartSalesStatusContainer.nativeElement);
    }
  }

  loadData(): void {
    // Implement your data loading logic here
    // You can use this.selectedTimeframe and this.selectedReport to fetch appropriate data
  }

  onTimeframeChange(newTimeframe: string): void {
    this.selectedTimeframe = newTimeframe;
    this.updateUrl();
    this.loadData();
  }

  onReportChange(newReport: string): void {
    this.selectedReport = newReport;
    this.updateUrl();
    this.loadData();
  }

  private updateUrl(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        timeframe: this.selectedTimeframe,
        report: this.selectedReport
      },
      queryParamsHandling: 'merge'
    });
  }
}