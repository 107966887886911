import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    constructor(private router: Router, private activatedRoute: ActivatedRoute) { }
    getBreadcrumbs(): Observable<any[]> {
        return this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.buildBreadcrumbs(this.activatedRoute.root))
        );
    }
    private buildBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: any[] = []): any[] {
        const children: ActivatedRoute[] = route.children;
        if (children.length === 0) {
            return breadcrumbs;
        }
        for (const child of children) {
            const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
            if (routeURL !== '') {
                url += `/${routeURL}`;
            }
            const label = this.getLabel(child);
            if (label) {
                breadcrumbs.push({ label, url });
            }
            // Recursively build breadcrumbs for child routes
            this.buildBreadcrumbs(child, url, breadcrumbs);
        }
        return breadcrumbs;
    }
    private getLabel(route: ActivatedRoute): string {
        const data = route.snapshot.data;
        const paramName = data['breadcrumbParam'];
        if (paramName) {
            const paramValue = route.snapshot.params[paramName];
            if (paramValue) {
                return `${data['breadcrumb']}: ${paramValue}`;
            }
        }
        return data['breadcrumb'];
    }
}