import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { User } from '../../model/user.interface';
import { UserService } from '../../service/user.service';
import { LinkUtils } from 'src/app/event/util/link.util';
import { Observable } from 'rxjs';
import { Purchase } from '../../model/purchase.interface';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { getLoggedInUser } from 'src/app/auth/store';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  @ViewChild('chartTotalTokens') chartTotalTokensContainer: ElementRef;
  @ViewChild('chartTotalPurchases') chartTotalPurchasesContainer: ElementRef;
  @ViewChild('chartTotalPurchased') chartTotalPurchasedContainer: ElementRef;
  @ViewChild('chartTotalUpselling') chartTotalUpsellingContainer: ElementRef;

  section: string;
  user: User;
  fullName: string;
  events$: Observable<any[]>;
  purchases$: Observable<Purchase[]>;

  items: MenuItem[] = [];

  sdk: ChartsEmbedSDK;

  constructor(private activatedRoute: ActivatedRoute, private userService: UserService, private store: Store, private router: Router) {

    this.sdk = new ChartsEmbedSDK({
      baseUrl: "https://charts.mongodb.com/charts-project-0-jikbu"
    });

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {

      const id = params.get('id');

      this.items = [
        { label: 'Resumen', routerLink: [`/users/${id}/summary`], icon: 'pi pi-chart-line' },
        { label: 'Datos personales', routerLink: [`/users/${id}/profile`], icon: 'pi pi-align-justify' },
        { label: 'Perfil público', routerLink: [`/users/${id}/public`], icon: 'pi pi-calendar' },
        { label: 'Clasificación', routerLink: [`/users/${id}/tier`], icon: 'pi pi-tag' },
        { label: 'Entradas', routerLink: [`/users/${id}/tokens`], icon: 'pi pi-ticket' },
        { label: 'Compras', routerLink: [`/users/${id}/purchases`], icon: 'pi pi-shopping-cart' }
      ];

      this.userService.getUserById(id).subscribe((response: User) => {
        this.user = response;
        this.fullName = this.getFullName();
        this.initCharts();
      });

      this.events$ = this.userService.getUserEventsById(id);

      this.purchases$ = this.userService.getUserPurchasesById(id);

    });

    // FIXME: do it with a secondary outlet
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const parts = document.location.href.split('/');
        if (parts.length === 6) {
          this.section = parts[parts.length - 1].split('?')[0];
        }
      }
    });
  }

  initCharts(): void {

    this.store.select(getLoggedInUser).subscribe(result => {

      const chartTotalTokens = this.sdk.createChart({
        chartId: environment.charts.user.chartTotalTokens,
        height: "200px",
        showAttribution: false,
        filter: { "ownedBy": this.user?.id, "tenant": result.id }
      });

      chartTotalTokens.render(this.chartTotalTokensContainer.nativeElement);

      const chartTotalPurchases = this.sdk.createChart({
        chartId: environment.charts.user.chartTotalPurchases,
        height: "200px",
        showAttribution: false,
        filter: { "userId": this.user?.id, "tenantId": result.id }
      });

      chartTotalPurchases.render(this.chartTotalPurchasesContainer.nativeElement);

      const chartTotalPurchased = this.sdk.createChart({
        chartId: environment.charts.user.chartTotalPurchased,
        height: "200px",
        showAttribution: false,
        filter: { "userId": this.user?.id, "tenantId": result.id }
      });

      chartTotalPurchased.render(this.chartTotalPurchasedContainer.nativeElement);

      const chartTotalUpselling = this.sdk.createChart({
        chartId: environment.charts.user.chartTotalUpselling,
        height: "200px",
        showAttribution: false,
        filter: { "ownedBy": this.user?.id, "tenant": result.id }
      });

      chartTotalUpselling.render(this.chartTotalUpsellingContainer.nativeElement);

    });
  }

  ngOnInit(): void {
  }

  getFullName(): string {

    if (!this.user) {
      return '';
    }

    let fullName = this.user.name;

    if (this.user.surname1) {
      fullName += ' ' + this.user.surname1;
    }

    if (this.user.surname2) {
      fullName += ' ' + this.user.surname2;
    }

    if (!fullName) {
      return 'Desconocido';
    }

    return fullName;
  }

  openEvent(url: string): void {

    window.open(url, '_blank');
  }

  formatDate(dateString: string): string {
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATETIME_SHORT);
  }

  getState(state: string): string {
    switch (state) {
      case 'ACTIVE':
        return 'Activo';
      case 'INVITED':
        return 'Invitado';
      case 'INVITED_BY_ADMIN':
        return 'Invitado';
      case 'REGISTERED':
        return 'Registrado';
      default:
        return '';
    }
  }

  copyToClipboard(text: string): void {
    LinkUtils.copy(text);
  }

  onLabelAdd($event: string): void {

    let labels = this.user?.labels;

    if (labels === undefined || labels === null) {
      labels = [];
    }

    this.userService.addLabel(this.user.id, $event).subscribe(() => {

      labels.push($event);
    }, () => {

      console.log('Error');
    });
  }

  onLabelRemove($event: string): void {

    let labels = this.user?.labels;

    if (labels === undefined || labels === null) {
      labels = [];
    }

    this.userService.removeLabel(this.user?.id, $event).subscribe(() => {

      const index = labels.indexOf($event);

      labels.splice(index, 1);

      this.user.labels = labels;
    }, () => {

      console.log('Error');
    });



  }

}
