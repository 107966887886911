import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class NameUtil {

    constructor() { }

    fullName(name: string, surname1: string, surname2: string): Observable<string> {

        return new Observable<string>(observer => {

            let fullName = name;

            if (surname1 && surname1 != '') {
                fullName += ` ${surname1.trim()}`;
            }

            if (surname2 && surname2 != '') {
                fullName += ` ${surname2.trim()}`;
            }

            observer.next(fullName)
            observer.complete();

        });

    }

    shortId(id: string): Observable<string> {

        return new Observable<string>(observer => {

            const sanitizedInput = String(id);
            const sanitizedLength = Math.max(0, sanitizedInput.length);

            let value = '';

            if (sanitizedInput && sanitizedInput != '') {

                if (sanitizedLength > 6) {

                    value = `${sanitizedInput.substring(0, 4)} **** ${sanitizedInput.substring(sanitizedLength - 4, sanitizedLength)}`

                } else {

                    value = sanitizedInput;
                }

            }

            observer.next(value)
            observer.complete();

        });

    }
}