<p-dialog
  header="Añadir contenido"
  [modal]="true"
  [(visible)]="displayAddContentDialog"
  [style]="{ width: '50vw', height: '80vh' }"
  (onHide)="onDialogHide()"
>
  <form [formGroup]="contentForm">

    <div class="field flex flex-column">
      <label for="contentType">Tipo de contenido *</label>
      <p-dropdown
        id="contentType"
        [options]="contentTypeOptions"
        [(ngModel)]="selectedContentType"
        (onChange)="contentTypeChange()"
        formControlName="contentType"
        placeholder="Selecciona una opción"
        optionLabel="value"
        optionValue="id"
      >
        <ng-template let-option pTemplate="item">
          <div class="flex flex-row align-items-center">
            <div class="p-2">
              <i class="{{ option.icon }}"></i>
            </div>
            <div>{{ option.value }}</div>
          </div>
        </ng-template></p-dropdown
      >
    </div>

    <div *ngIf="selectedContentType === 'external_url'" class="field">
      <label for="link">Enlace *</label>
      <input
        id="link"
        formControlName="url"
        type="text"
        pInputText
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
      />
    </div>

    <div *ngIf="selectedContentType === 'file'" class="field">
      <label for="link">Subir archivo *</label>
      <p>Formatos permitidos: jpeg, png, pdf, mp4</p>
      <p-fileUpload 
      name="myfile[]"
      mode="basic"
      [auto]="true"
      [customUpload]="true"
      (uploadHandler)="myUploader($event)"></p-fileUpload>
      <p *ngIf="file">Archivo seleccionado: {{file?.name}}</p>
    </div>

    <div>
      <label for="name">Nombre *</label>
      <input
        id="name"
        formControlName="name"
        type="text"
        pInputText
        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
      />
    </div>

    <div class="field flex flex-column">
      <label for="accessControl">Control de acceso *</label>
      <p-dropdown
        id="accessControl"
        [options]="accessControlOptions"
        formControlName="accessControl"
        placeholder="Selecciona una opción"
        optionLabel="value"
        optionValue="id"
      >
        <ng-template let-option pTemplate="item">
          <div class="flex flex-row align-items-center">
            <div class="p-2">
              <i class="{{ option.icon }}"></i>
            </div>
            <div>{{ option.value }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <button
      pButton
      pRipple
      *ngIf="!loading"
      [disabled]="!contentForm.valid"
      label="Añadir contenido"
      class="w-full"
      (click)="addContent()"
    ></button>
    <button
        pButton
        pRipple
        *ngIf="loading"
        [disabled]="loading"
        icon="pi pi-spin pi-spinner"
        iconPos="right"
        label="Añadir contenido"
        class="w-full"
      ></button>
    <div style="color:red"[innerHTML]="errorMessage"></div>
  </form>
</p-dialog>
