<div class="stepsdemo-content">
  <div class="flex flex-row actions-row">
    <button pButton class="p-button-primary cta-button" label="Añadir sponsor" (click)="addSponsor()"
      icon="pi pi-plus"></button>
  </div>
  <p-card>
    <ng-template pTemplate="title"> Sponsors </ng-template>
    <ng-template pTemplate="subtitle">
      Dale visibilidad a los patrocinadores de tu experiencia
    </ng-template>
    <ng-template pTemplate="content">
      <div class="flex flex-column" *ngIf="sponsors && sponsors.length > 0; else noSponsors">
        <div *ngFor="let sponsor of sponsors">
          <app-sponsor-item [eventId]="eventId" [sponsor]="sponsor"
            (onRemove)="removeSponsorFromList($event)"></app-sponsor-item>
        </div>
      </div>
      <ng-template #noSponsors>
        <p>No hay sponsors</p>
      </ng-template>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="grid grid-nogutter justify-content-between"></div>
    </ng-template>
  </p-card>
</div>

<p-sidebar [(visible)]="display" position="right" [baseZIndex]="10000" styleClass="custom-sidebar"
  (onHide)="closeSidebar()">
  <ng-template pTemplate="header">
    <h3>Añadir sponsor</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <form [formGroup]="sponsorForm" class="p-fluid">
      <div class="field">
        <label for="url">Enlace</label>
        <input id="url" formControlName="url" type="text" pInputText
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
        <small *ngIf="
              this.sponsorForm.get('url')?.invalid &&
              (this.sponsorForm.get('url')?.dirty || this.sponsorForm.get('url')?.touched)
              " id="name-help" class="p-error block">El enlace no es válido</small>
      </div>
      <div class="field flex-grow-1">
        <label for="image">Logotipo</label>
        <app-image-upload [url]="this.sponsorForm.get('image')?.value" alt="Image" recommendedHeight="100px"
          recommendedProp="1:1" (onUpload)="onUpload($event)" (onRemove)="removeImage()"></app-image-upload>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton *ngIf="!creatingSponsor; else createBlocked" [disabled]="sponsorForm.invalid || !sponsorForm.dirty"
      class="p-button-success" label="Añadir sponsor" (click)="createSponsor()"></button>
    <ng-template #createBlocked>
      <button pButton [disabled]="true" class="p-button-success" label="Añadir sponsor" icon="pi pi-spin pi-spinner"
        iconPos="right"></button>
    </ng-template>
  </ng-template>
</p-sidebar>