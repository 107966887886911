import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  Router,
} from '@angular/router';
import { UserService } from '../../service/user.service';
import { Token } from '../../model/token.interface';
import { TicketOwnerDetail } from '../../model/ticket-owner-detail.interface';
import { TokenMovement } from '../../model/token-movement.interface';
import {
  AdditionalExperience,
  AdditionalItem,
} from '../../model/ticket-detail.interface';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { Message } from 'src/app/event/model/message.interface';
import { DeliveryService } from 'src/app/shared/services/delivery.service';
import { Sale } from 'src/app/event/model/sale.interface';
import { EventService } from 'src/app/event/service/event.service';
import { getEventSectionsSuffixes } from 'src/app/shared/util/MessageUtil';

@Component({
  selector: 'app-token-detail',
  templateUrl: './token-detail.component.html',
  styleUrls: ['./token-detail.component.scss'],
})
export class TokenDetailComponent implements OnInit {
  section: string;
  items: MenuItem[] = [];

  token: Token;
  tokenExperiencesIds: string[] = [];
  assignationInfo: TicketOwnerDetail;
  movements: TokenMovement[] = [];
  displayAddDialog: boolean = false;
  displayAddCustomizationDialog: boolean = false;
  displayAssignDialog: boolean = false;
  reassignMail: string;
  reassignMailCheck: boolean = false;
  public experiences: AdditionalExperience[] = [];
  public products: AdditionalItem[] = [];

  tokenUrls: { [key: string]: string };

  newMessage: string = '';
  loading: boolean = false;

  fallbackQR: string = '';

  accessControl: string = 'attendant';

  messages: Message[] = [];

  sales: Sale[] = [];

  showToken: boolean = false;
  showErrorQR: boolean = false;

  displayRedirectionDialog: boolean = false;
  services: string[] = [];
  serviceOptions: SelectItem[] = [];
  customRedirection: boolean = false;
  selectedService: string = '/messages';

  sending: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private messageService: MessageService,
    private deliveryService: DeliveryService,
    private router: Router,
    private eventService: EventService
  ) {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const tokenId = params.get('tokenId');
      const userId = params.get('id');

      this.items = [
        {
          label: 'Resumen',
          routerLink: [`/users/${userId}/tokens/${tokenId}/summary`],
          icon: 'pi pi-ticket',
        },
        {
          label: 'Asignación',
          routerLink: [`/users/${userId}/tokens/${tokenId}/assignation`],
          icon: 'pi pi-user',
        },
        {
          label: 'Personalización',
          routerLink: [`/users/${userId}/tokens/${tokenId}/experiences`],
          icon: 'pi pi-angle-double-up',
        },
        {
          label: 'Productos',
          routerLink: [`/users/${userId}/tokens/${tokenId}/products`],
          icon: 'pi pi-shopping-cart',
        },
        {
          label: 'Cashless',
          routerLink: [`/users/${userId}/tokens/${tokenId}/wallet`],
          icon: 'pi pi-euro',
        },
        {
          label: 'Mensajes',
          routerLink: [`/users/${userId}/tokens/${tokenId}/messages`],
          icon: 'pi pi-comments',
        },
      ];
      this.userService.getTokenById(tokenId).subscribe((response: Token) => {
        this.token = response;
        this.fetchSectionsSuffixes(response);
        if (this.token.experiences)
          this.tokenExperiencesIds = this.token.experiences.map((e) => e.id);
      });
      this.userService
        .getTokenAssignationInfoById(tokenId)
        .subscribe(
          (response: TicketOwnerDetail) => (this.assignationInfo = response)
        );
      this.userService
        .getTokenMovementsById(tokenId)
        .subscribe((response: TokenMovement[]) => (this.movements = response));
      this.userService
        .getTokenAdditionalExperiencesById(tokenId)
        .subscribe(
          (response: AdditionalExperience[]) => (this.experiences = response)
        );
      this.deliveryService
        .retrieveSalesByToken(tokenId)
        .subscribe((sales: Sale[]) => (this.sales = sales));
      this.userService
        .getMessages(tokenId)
        .subscribe((response: Message[]) => (this.messages = response));
    });

    // FIXME: do it with a secondary outlet
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const parts = document.location.href.split('/');
        if (parts.length === 8) {
          this.section = parts[parts.length - 1].split('?')[0];
        }
      }
    });
  }

  ngOnInit(): void {}

  showRedirectionDialog(): void {
    if (!this.newMessage || this.newMessage.trim().length === 0) {
      return; // Do nothing if the message is not valid
    }
    this.displayRedirectionDialog = true;
  }

  fetchSectionsSuffixes(token: Token): void {
    this.eventService.getServices(token.eventId).subscribe((response: any) => {
      this.serviceOptions = getEventSectionsSuffixes(response);
    });
  }

  hideDialog(): void {
    this.displayAddDialog = false;
  }

  hideCustomizationDialog(): void {
    this.displayAddCustomizationDialog = false;
  }

  sendMessage(): void {

    if (this.sending || !this.newMessage || this.newMessage.trim().length === 0 || this.loading) {
      return;
    }

    this.sending = true;
    this.loading = true;
    this.userService
      .postMessage(this.token.id, {
        content: this.newMessage,
        redirectToSuffix: this.selectedService,
      })
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Mensaje publicado correctamente',
          });
          this.messages.unshift({
            id: '',
            content: this.newMessage,
            publishedAt: new Date(),
          });
          this.loading = false;
          this.newMessage = '';
          this.displayRedirectionDialog = false;
          this.sending = false;
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Ha ocurrido un error',
            detail: 'Por favor, vuelve a intentarlo',
          });
          this.sending = false;
          this.loading = false;
        }
      );
  }

  deleteMessage(messageId: string): void {
    this.userService.deleteMessage(this.token.id, messageId).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Mensaje borrado correctamente',
        });
      },
      () =>
        this.messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: 'Por favor, vuelve a intentarlo',
        })
    );
  }

  generateTicket(): void {
    this.userService
      .regenerateTicket(this.token.id, this.token.eventId, this.token.typeId)
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Ticket regenerado',
            detail: 'La imagen del ticket fue regenerada correctamente',
          });
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Ticket no regenerado',
            detail: 'La imagen del ticket no pudo ser regenerada',
          });
        }
      );
  }

  showTokenDialog() {
    // Call the service to load images for the given ticket ID
    this.userService.getTokenUrls(this.token.id).subscribe(
      (urls) => {
        this.tokenUrls = urls;
        this.showToken = true; // Show the dialog after images are loaded
      },
      (error) => {
        console.error('Error fetching ticket images', error);
        this.fallbackQR = this.token.validationUrl; // Set fallback QR data
        this.showToken = true; // Still show the dialog, but with the QR code instead
        this.showErrorQR = true; // Show QR code on error
      }
    );
  }

  resendEmail(): void {
    this.userService.resendToken(this.token.id).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Email enviado',
          detail: 'El email fue enviado correctamente',
        });
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: 'No se pudo reenviar el email',
        });
      }
    );
  }

  isBoolean(value: any): value is boolean {
    return typeof value === 'boolean';
  }

  showAssignDialog(): void {
    this.reassignMail = undefined;
    this.reassignMailCheck = false;
    this.displayAssignDialog = true;
  }

  reassignTicket(): void {
    this.userService.reassignToken(this.token.id, this.reassignMail).subscribe(
      (response: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Token reasignado',
          detail: 'El token se reasignó correctamente',
        });
        this.displayAssignDialog = false;
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: 'No se pudo reasignar el token',
        });
        this.displayAssignDialog = false;
      }
    );
  }

  refund(purchaseId: string) {
    this.userService.refundExpense(this.token.id, purchaseId).subscribe(
      (response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Gasto reembolsado',
          detail: 'El importe se ha reembolsado',
        });
        this.displayAssignDialog = false;
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: 'No se pudo reembolsar el movimiento',
        });
        this.displayAssignDialog = false;
      }
    );
  }
}
