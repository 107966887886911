import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EventExperience } from '../../model/event-detail.interface';
import { EventService } from '../../service/event.service';

@Component({
  selector: 'app-experience-item',
  templateUrl: './experience-item.component.html',
  styleUrls: ['./experience-item.component.scss']
})
export class ExperienceItemComponent implements OnInit {

  @Input() eventId: string;
  @Input() experience: EventExperience;
  @Output() onUpdate: EventEmitter<EventExperience> = new EventEmitter();
  @Output() onRemove: EventEmitter<string> = new EventEmitter();

  messageDialogVisible: boolean = false;

  constructor(private eventService: EventService, private confirmationService: ConfirmationService, private messageService: MessageService) { }

  ngOnInit(): void {
  }

  editExperience() {
    this.onUpdate.emit(this.experience);
  }

  removeExperience() {
    this.confirmationService.confirm({
      message: '¿Estás seguro de que quieres eliminar la experiencia?',
      accept: () => {
        this.eventService.deleteExperience(this.eventId, this.experience.id).subscribe(() => {
          this.onRemove.emit(this.experience.id);
          this.messageService.add({ severity: 'success', summary: 'Experiencia borrada correctamente', detail: '' });
        }, () => {
          this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Vuelve a intentarlo en unos minutos' });
        });
      },
      reject: () => {
        this.confirmationService.close();
      }
    });

  }

  createCampaign(): void {

    this.messageDialogVisible = true;
  }

  onMessageDialogHide($event: any): void {

    this.messageDialogVisible = false;
  }

}
