import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { EventComponent } from './event.component';
import { CreateEventComponent } from './components/create-event/create-event.component';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import { IsConfiguredGuard } from '../auth/guard/is-configured.guard';
import { EventDetailAttendeesComponent } from './components/event-detail-attendees/event-detail-attendees.component';
import { EventDetailDescriptionComponent } from './components/event-detail-description/event-detail-description.component';
import { EventDetailVenueComponent } from './components/event-detail-venue/event-detail-venue.component';
import { EventDetailTicketsComponent } from './components/event-detail-tickets/event-detail-tickets.component';
import { EventDetailExperiencesComponent } from './components/event-detail-experiences/event-detail-experiences.component';
import { EventDetailContentComponent } from './components/event-detail-content/event-detail-content.component';
import { EventDetailSalesComponent } from './components/event-detail-sales/event-detail-sales.component';
import { EventDetailSponsorsComponent } from './components/event-detail-sponsors/event-detail-sponsors.component';
import { EventDetailMessagesComponent } from './components/event-detail-messages/event-detail-messages.component';
import { EventDetailSummaryComponent } from './components/event-detail-summary/event-detail-summary.component';
import { EventDetailStreamingComponent } from './components/event-detail/event-detail-streaming/event-detail-streaming.component';
import { EventDetailMomentsComponent } from './components/event-detail/event-detail-moments/event-detail-moments.component';
import { EventDetailCouponsComponent } from './components/event-detail/event-detail-coupons/event-detail-coupons.component';

const routes: Routes = [
  {
    path: '', component: EventComponent, canActivate: [IsConfiguredGuard],
    data: { breadcrumb: 'Todas las experiencias' }
  },
  {
    path: 'create', component: CreateEventComponent, canActivate: [IsConfiguredGuard],
    data: { breadcrumb: 'Crear experiencia' }
  },
  {
    path: ':id', component: EventDetailComponent, canActivate: [IsConfiguredGuard],
    data: { breadcrumb: 'Experiencia', breadcrumbParam: 'id' }, children: [
      {
        path: 'summary', component: EventDetailSummaryComponent,
        data: { breadcrumb: 'Resumen' }
      },
      {
        path: 'description', component: EventDetailDescriptionComponent,
        data: { breadcrumb: 'Descripción' }
      },
      {
        path: 'venue', component: EventDetailVenueComponent,
        data: { breadcrumb: 'Venue' }
      },
      {
        path: 'tickets', component: EventDetailTicketsComponent,
        data: { breadcrumb: 'Entradas' }
      },
      {
        path: 'experiences', component: EventDetailExperiencesComponent,
        data: { breadcrumb: 'Personalización' }
      },
      {
        path: 'sponsors', component: EventDetailSponsorsComponent,
        data: { breadcrumb: 'Sponsors' }
      },
      {
        path: 'sales', component: EventDetailSalesComponent,
        data: { breadcrumb: 'Ventas' }
      },
      {
        path: 'attendees', component: EventDetailAttendeesComponent,
        data: { breadcrumb: 'Asistentes' }
      },
      {
        path: 'messages', component: EventDetailMessagesComponent,
        data: { breadcrumb: 'Mensajes' }
      },
      {
        path: 'content', component: EventDetailContentComponent,
        data: { breadcrumb: 'Contenido' }
      },
      {
        path: 'public-relations', component: EventDetailContentComponent,
        data: { breadcrumb: 'RRPP' }
      },
      {
        path: 'streaming', component: EventDetailStreamingComponent,
        data: { breadcrumb: 'Streaming' }
      },
      {
        path: 'moments', component: EventDetailMomentsComponent,
        data: { breadcrumb: 'Momentos' }
      },
      {
        path: 'coupons', component: EventDetailCouponsComponent,
        data: { breadcrumb: 'Cupones' }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventRoutingModule {
}
