<p-table
  #salesTable
  [value]="loading ? skeletonRows : sales"
  responsiveLayout="scroll"
  styleClass="p-datatable-sm"
  [lazy]="true"
  [paginator]="true"
  [rows]="rows"
  [totalRecords]="totalRecords"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} ventas"
  (onPage)="onPageChange($event)"
  [first]="first"
  [globalFilterFields]="[
    'name',
    'variant',
    'quantity',
    'ownedByEmail',
    'ownedByName'
  ]"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <button
        class="p-button"
        icon="pi pi-plus"
        label="Asignar producto"
        pButton
        pRipple
        type="button"
        (click)="displayAddProductDialog = true"
      ></button>
      <button
        [disabled]="blockDownload"
        class="ml-3"
        pButton
        pRipple
        type="button"
        label="Exportar listado"
        icon="pi pi-file-excel"
        (click)="exportList()"
      ></button>
      <button
        pButton
        label="Limpiar filtros"
        class="p-button-outlined ml-3"
        icon="pi pi-filter-slash"
        (click)="clear(salesTable)"
      ></button>
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (input)="salesTable.filterGlobal($event.target.value, 'contains')"
          placeholder="Buscar"
        />
      </span>
    </div>
  </ng-template>
  
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="name" *ngIf="eventId">
        Producto
        <p-sortIcon field="name"></p-sortIcon>
        <p-columnFilter
          type="text"
          field="name"
          display="menu"
        ></p-columnFilter>
      </th>
      <th pSortableColumn="variant">
        Variante
        <p-sortIcon field="variant"></p-sortIcon>
        <p-columnFilter
          type="text"
          field="variant"
          display="menu"
        ></p-columnFilter>
      </th>
      <th pSortableColumn="quantity">
        Cantidad
        <p-sortIcon field="quantity"></p-sortIcon>
        <p-columnFilter
          type="text"
          field="quantity"
          display="menu"
        ></p-columnFilter>
      </th>
      <th pSortableColumn="ownedByEmail">
        Email
        <p-sortIcon field="ownedByEmail"></p-sortIcon>
        <p-columnFilter
          type="text"
          field="ownedByEmail"
          display="menu"
        ></p-columnFilter>
      </th>
      <th pSortableColumn="ownedByName">
        Nombre
        <p-sortIcon field="ownedByName"></p-sortIcon>
        <p-columnFilter
          type="text"
          field="ownedByName"
          display="menu"
        ></p-columnFilter>
      </th>
      <th pSortableColumn="delivered">
        Entregado
        <p-sortIcon field="delivered"></p-sortIcon>
        <p-columnFilter
          field="delivered"
          matchMode="in"
          display="menu"
          [showMatchModes]="false"
          [showOperator]="false"
          [showAddButton]="false"
        >
          <ng-template pTemplate="header">
            <div class="px-3 pt-3 pb-0">
              <span class="font-bold">Entregado</span>
            </div>
          </ng-template>
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect
              [ngModel]="value"
              [options]="[
                { id: true, name: 'Si' },
                { id: false, name: 'No' }
              ]"
              placeholder="Any"
              (onChange)="filter($event.value)"
              optionLabel="name"
              optionValue="id"
            >
              <ng-template let-option pTemplate="item">
                <div class="inline-block vertical-align-middle">
                  <span class="ml-1 mt-1">{{ option.name }}</span>
                </div>
              </ng-template>
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-sale>
    <tr>
      <td *ngIf="eventId">
        <ng-container *ngIf="!loading">
          <a [routerLink]="['/products/' + sale.productId]">{{ sale.name }}</a>
        </ng-container>
        <p-skeleton *ngIf="loading" width="150px" height="21px"></p-skeleton>
      </td>
      <td>
        <ng-container *ngIf="!loading">
          <span *ngIf="sale.variant; else nosize">{{ sale.variant }}</span>
          <ng-template #nosize>-</ng-template>
        </ng-container>
        <p-skeleton *ngIf="loading" width="60px" height="21px"></p-skeleton>
      </td>
      <td>
        <ng-container *ngIf="!loading">
          {{ sale.quantity }}
        </ng-container>
        <p-skeleton *ngIf="loading" width="40px" height="21px"></p-skeleton>
      </td>
      <td>
        <ng-container *ngIf="!loading">
          <a [routerLink]="['/users', sale.ownedBy]">{{ sale.ownedByEmail }}</a>
        </ng-container>
        <p-skeleton *ngIf="loading" width="180px" height="21px"></p-skeleton>
      </td>
      <td>
        <ng-container *ngIf="!loading">
          {{ sale.ownedByName || '-' }}
        </ng-container>
        <p-skeleton *ngIf="loading" width="180px" height="21px"></p-skeleton>
      </td>
      <td>
        <ng-container *ngIf="!loading">
          <div class="flex flex-column align-items-center" *ngIf="sale?.delivered">
            <i class="pi pi-check" 
               style="color: green; font-weight: 600" 
               [pTooltip]="sale?.deliveredAt | date : 'dd/MM/yyyy HH:mm'"
               tooltipPosition="top">
            </i>
          </div>
          <i *ngIf="!sale?.delivered"
             class="pi pi-clock"
             style="color: orange; font-weight: 600"
             pTooltip="Pendiente"
             tooltipPosition="top">
          </i>
        </ng-container>
        <p-skeleton *ngIf="loading" width="24px" height="24px" styleClass="mx-auto"></p-skeleton>
      </td>
      <td>
        <ng-container *ngIf="!loading">
          <button
            *ngIf="!eventId"
            pButton
            pRipple
            type="button"
            pTooltip="Mostrar información"
            tooltipPosition="top"
            icon="pi pi-info-circle"
            class="p-button-text"
            (click)="showInfo(sale)">
          </button>
          <button
            *ngIf="!sale?.delivered"
            pButton
            pRipple
            type="button"
            pTooltip="Confirmar"
            tooltipPosition="top"
            icon="pi pi-check-circle"
            class="p-button-text"
            (click)="deliver(sale._id)">
          </button>
          <button
            *ngIf="sale?.delivered"
            pButton
            pRipple
            type="button"
            pTooltip="Invalidar"
            tooltipPosition="top"
            icon="pi pi-undo"
            class="p-button-text"
            (click)="undeliver(sale._id)">
          </button>
        </ng-container>
        <div *ngIf="loading" class="flex gap-2">
          <p-skeleton shape="circle" size="2rem" styleClass="mt-2"></p-skeleton>
          <p-skeleton shape="circle" size="2rem" styleClass="mt-2"></p-skeleton>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog
  header="Información de envío"
  [style]="{ width: '50vw' }"
  [modal]="true"
  [(visible)]="showDeliveryDialog"
>
  <div
    class="flex flex-column justify-content-start align-items-start"
    *ngIf="merchDeliveryData; else noDelivery"
  >
    <div class="flex flex-row justify-content-start align-items-start">
      <div class="mr-3">
        <h4>Dirección (linea 1)</h4>
        <p>{{ merchDeliveryData?.addressLine1 }}</p>
      </div>
      <div>
        <h4>Dirección (linea 2)</h4>
        <p>{{ merchDeliveryData?.addressLine2 }}</p>
      </div>
    </div>
    <div class="flex flex-row justify-content-start align-items-start">
      <div>
        <h4>Notas para el repartidor</h4>
        <p>{{ merchDeliveryData?.additionalNotes }}</p>
      </div>
    </div>
    <div class="flex flex-row justify-content-start align-items-start">
      <div class="mr-3">
        <h4>País</h4>
        <p>{{ merchDeliveryData?.country }}</p>
      </div>
      <div class="mr-3">
        <h4>Código postal</h4>
        <p>{{ merchDeliveryData?.postalCode }}</p>
      </div>
      <div class="mr-3">
        <h4>Provincia</h4>
        <p>{{ merchDeliveryData?.province }}</p>
      </div>
      <div class="mr-3">
        <h4>Población</h4>
        <p>{{ merchDeliveryData?.village }}</p>
      </div>
    </div>
  </div>
  <ng-template #noDelivery> El producto se entregará en el evento </ng-template>
</p-dialog>

<app-add-product-dialog
  [tokenId]="tokenId"
  [displayDialog]="displayAddProductDialog"
  (hideDialog)="hideAddProductDialog()"
></app-add-product-dialog>
