<!-- list-item.component.html -->
<div class="flex flex-row align-items-center main-col">
    <!-- Primera columna: Imagen -->
    <div class="flex-none image-wrapper" *ngIf="image">
        <img [src]="image" alt="Item Image" />
    </div>
    <!-- Segunda columna: Título, subtítulo e información adicional -->
    <div class="flex-grow-1 ml-3">
        <h3 *ngIf="title">{{ title }}</h3>
        <h4 *ngIf="subtitle">{{ subtitle }}</h4>
        <div *ngIf="additionalInfo">{{ additionalInfo }}</div>
    </div>
    <!-- Tercera columna: Precio y unidades -->
    <div class="flex-none ml-3 price-units" *ngIf="stock">
        <h4>{{ price }}</h4>
        <p class="units-info">
            Vendidas: {{ sold }} / {{ stock }}
        </p>
    </div>
    <!-- Cuarta columna: Acciones -->
    <div class="flex-none ml-3">
        <ng-content select="[item-actions]"></ng-content>
    </div>
</div>