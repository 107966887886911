import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-ticket-change-dialog',
  templateUrl: './ticket-change-dialog.component.html',
  styleUrls: ['./ticket-change-dialog.component.scss']
})
export class TicketChangeDialogComponent implements OnChanges {

  @Input() visible: boolean = false;
  @Input() currentTickets: number = 0;
  @Input() maxRemovable: number = 0;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() apply = new EventEmitter<number>();

  newTotal: number = 0;
  minTotal: number = 0;
  maxTotal: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['currentTickets'] || 
      changes['maxRemovable'] || 
      (changes['visible'] && changes['visible'].currentValue)
    ) {
      this.newTotal = this.currentTickets;
      this.minTotal = Math.max(0, this.currentTickets - this.maxRemovable);
      this.maxTotal = this.currentTickets + 1000;
    }
  }

  get difference(): number {
    return this.newTotal - this.currentTickets;
  }

  get isValid(): boolean {
    return this.newTotal >= this.minTotal && this.newTotal <= this.maxTotal;
  }

  get errorMessage(): string {
    if (this.newTotal < this.minTotal) return `No puedes eliminar más de ${this.maxRemovable} entradas.`;
    if (this.newTotal > this.maxTotal) return `No puedes añadir más de 100 entradas a la vez.`;
    return '';
  }

  onCancel(): void {
    this.visibleChange.emit(false);
  }

  onApply(): void {
    this.apply.emit(this.difference);
  }

}
