<div style="width: 100%;">
  <p-card>
    <ng-template pTemplate="title"> Streaming </ng-template>
    <ng-template pTemplate="subtitle">
      Configura el streaming de tu evento
    </ng-template>
    <ng-template pTemplate="content">
      <p>Indica a continuación el código del streaming.</p>
      <p style="font-size: 12px">Ejemplo: https://www.youtube.com/watch?v=<b>jfKfPfyJRdk</b></p>
      <br>
      <div>
        <form [formGroup]="streamingForm" (ngSubmit)="saveStreaming()">
          <div class="field">
            <label for="type">Tipo</label>
            <p-dropdown [options]="streamingTypes" optionLabel="label" formControlName="type"></p-dropdown>
          </div>
          <div formArrayName="streams">
            <div class="flex flex-row mt-3" *ngFor="let stream of streams.controls; let i=index" [formGroupName]="i">

              <span class="p-float-label">
                <input pInputText type="text" id="name" formControlName="name" class="form-control">
                <label for="float-input">Nombre</label>
              </span>

              <span class="p-float-label ml-3">
                <input pInputText type="text" id="code" formControlName="code" class="form-control">
                <label for="float-input">Código</label>
                <small class="p-error" *ngIf="stream.get('code').errors?.required && stream.touched">El código de
                  streaming es obligatorio</small>
              </span>

              <button pButton type="button" icon="pi pi-trash" class="p-button-text"
                (click)="deleteStreaming(i)"></button>
            </div>
          </div>
          <div class="mt-3 flex flex-row">
            <button pButton type="button" icon="pi pi-plus" class="p-button-text" label="Añadir nuevo streaming"
              (click)="addStreaming()"></button>
          </div>
          <div class="flex flex-row justify-content-end">
            <button pButton type="submit" class="p-button" [disabled]="!streamingForm.valid" label="Guardar cambios"></button>
          </div>
        </form>
      </div>
    </ng-template>
  </p-card>
</div>