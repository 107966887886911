import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Label } from '../model/label.interface';

@Injectable({
  providedIn: 'root'
})
export class LabelService {

  baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  searchLabels(search: string): Observable<Label[]> {

    const url = `${this.baseUrl}/labels?query=${search}`;

    return this.http.get<Label[]>(url);
  }

  createLabel(value: string): Observable<Label> {

    const url = `${this.baseUrl}/labels`;

    return this.http.post<Label>(url, { value });
  }

  resolveLabels(ids: string[]): Observable<Label[]> {

    const url = `${this.baseUrl}/labels/resolve`;

    return this.http.post<Label[]>(url, { ids });
  }
}
