<p-card [header]="getProductAttribute('name')">
  <div class="flex flex-column">
    <div class="flex flex-row align-items-center">
      <h5>Identificador único del ticket:&nbsp;</h5>
      <p>{{ ticket.id }}</p>
      <button pButton pRipple type="button" icon="pi pi-copy" class="p-button-rounded p-button-text"
        (click)="copyLink(ticket.id)"></button>
    </div>

    <div class="flex flex-row align-items-center">
      <h5>Asginado a:&nbsp;</h5>
      <p *ngIf="ticket.ownedBy; else unassigned">
        <a [routerLink]="['/users', ticket.ownedBy]">
          {{ ticket.ownedByEmail }}</a>
      </p>
      <ng-template #unassigned>Esta entrada no está asignada</ng-template>
    </div>
    <h5>Personalización</h5>
    <div *ngIf="
        ticket.experiences && ticket.experiences.length > 0;
        else noexperiences
      ">
      <p *ngFor="let experience of ticket.experiences">
        {{ experience.quantity }}x {{ experience.name }}
      </p>
    </div>
    <ng-template #noexperiences>No hay personalización</ng-template>
    <h5 class="mb-3">Validación</h5>
    <p-tag *ngIf="ticket.validated">Validado</p-tag>
    <p-tag *ngIf="!ticket.validated">No validado</p-tag>
  </div>
  <ng-template pTemplate="footer">
    <div class="grid grid-nogutter justify-content-between">
      <div class="flex-grow-1"></div>
    </div>
  </ng-template>
</p-card>