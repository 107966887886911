import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../../environments/environment';
import * as fromLogin from './reducers/login.reducer';

export interface State {
    organization: fromLogin.State;
}

export const reducers: ActionReducerMap<State> = {
    organization: fromLogin.reducer,
};

const reducerKeys = ['organization'];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({ keys: reducerKeys })(reducer);
}

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {

        return reducer(state, action);
    };
}


export const metaReducers: MetaReducer<State>[] = !environment.production ? [debug, localStorageSyncReducer] : [localStorageSyncReducer];

export const getLoginState = createFeatureSelector<fromLogin.State>('organization');

export const getLoggedInUser = createSelector(
    getLoginState,
    fromLogin.getLoggedInUser
);