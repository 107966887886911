import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EventService } from '../../service/event.service';
import { CreatedEntity } from '../../model/created-entity.interface';
import { EventExperience, EventSponsor } from '../../model/event-detail.interface';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-event-detail-sponsors',
  templateUrl: './event-detail-sponsors.component.html',
  styleUrls: ['./event-detail-sponsors.component.scss']
})
export class EventDetailSponsorsComponent implements OnInit {

  @Input() eventId: string;
  @Input() sponsors: EventSponsor[];

  sponsorForm: FormGroup = new FormGroup({
    url: new FormControl(''),
    image: new FormControl('', [Validators.required])
  });
  display: boolean = false;
  creatingSponsor: boolean = false;

  constructor(private eventService: EventService, private messageService: MessageService) { }

  ngOnInit(): void {
  }

  addSponsor(): void {
    this.sponsorForm.reset();
    this.display = true;
  }

  createSponsor() {
    this.creatingSponsor = true;

    this.eventService.createSponsor(this.eventId, this.sponsorForm.value).subscribe((response: CreatedEntity) => {
      this.messageService.add({ severity: 'success', summary: 'Sponsor creado correctamente', detail: '' });
      const newSponsor: EventSponsor = this.sponsorForm.value;
      newSponsor.id = response.created;
      if (this.sponsors == undefined) this.sponsors = new Array<EventSponsor>();
      this.sponsors.push(newSponsor);
      this.display = false;
      this.creatingSponsor = false;
    }, () => {
      this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Revisa los datos y vuelve a intentarlo' });
      this.creatingSponsor = false;
    });
  }

  onUpload($event: any) {
    this.sponsorForm.get('image').setValue($event);
    this.sponsorForm.get('image').markAsDirty();
  }

  removeImage() {
    this.sponsorForm.get('image').setValue(undefined);
    this.sponsorForm.get('image').markAsDirty();
  }

  removeSponsorFromList($event) {
    this.sponsors = this.sponsors.filter(sponsor => sponsor.id != $event);
  }

  closeSidebar(): void {
    this.display = false;
  }

}
