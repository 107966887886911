import { Action, createReducer, on } from '@ngrx/store';
import { Organization } from '../../model/organization.interface';
import * as userActions from '../actions';

export interface State {
  organization: Organization;
}

export const initialState: State = {
  organization: undefined,
};

const loginReducer = createReducer(
  initialState,
  on(userActions.loginSuccess, (state, organization) => {

    return organization;
  })
);

export function reducer(state: State | undefined, action: Action): any {
  return loginReducer(state, action);
}

export const getLoggedInUser = (state: State) => {
  
  return state.organization;
};