import { Component, Input, OnInit } from '@angular/core';
import { LinkUtils } from '../../util/link.util';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-step-share',
  templateUrl: './step-share.component.html',
  styleUrls: ['./step-share.component.scss']
})
export class StepShareComponent implements OnInit {

  @Input() eventUrl: string;
  @Input() eventId: string;
  @Input() eventName: string;

  public qrCodeDownloadLink: SafeUrl = "";

  constructor() { }

  ngOnInit(): void {
  }

  copyLink(): void {

    LinkUtils.copy(this.eventUrl);
  }

}
