import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Attendant } from 'src/app/event/model/attendant.interface';
import { Sale } from 'src/app/event/model/sale.interface';
import { DeliveryService } from 'src/app/shared/services/delivery.service';
import { AdditionalExperience } from 'src/app/user/model/ticket-detail.interface';
import { UserService } from 'src/app/user/service/user.service';
import { EventService } from '../../service/event.service';
@Component({
  selector: 'app-attendant-detail-panel',
  templateUrl: './attendant-detail-panel.component.html',
  styleUrls: ['./attendant-detail-panel.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate('0.3s ease-in-out')
      ]),
      transition('* => void', [
        animate('0.3s ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class AttendantDetailPanelComponent implements OnChanges {
  @Input() eventId: string;
  @Input() selectedAttendant: Attendant;
  @Output() assistantDeleted: EventEmitter<string> = new EventEmitter<string>();

  selectedAttendantExperiences: AdditionalExperience[];
  selectedAttendantSales: Sale[];
  displayAddCustomizationDialog = false;
  pressedAssignProduct = false;
  displayAssignDialog: boolean = false;
  reassignMail: string;
  reassignMailCheck: boolean = false;

  displayDeleteAssistantDialog: boolean = false; // For deleting the attendant
  displayDeleteItemDialog: boolean = false; // For unassigning items/personalizations
  deleteTarget: { type: string, id: string } = { type: '', id: '' };

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private router: Router,
    private deliveryService: DeliveryService,
    private activatedRoute: ActivatedRoute,
    private eventService: EventService) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedAttendant'] && changes['selectedAttendant'].currentValue) {
      this.deliveryService.retrieveSalesByToken(this.selectedAttendant.ticketId).subscribe((sales: Sale[]) => {
        this.selectedAttendantSales = sales;
      });
      this.userService.getTokenAdditionalExperiencesById(this.selectedAttendant.ticketId).subscribe((response: AdditionalExperience[]) => {
        this.selectedAttendantExperiences = response;
      });
    }
  }
  hideCustomizationDialog(): void {
    this.displayAddCustomizationDialog = false;
  }
  collapsePanel(): void {
    this.selectedAttendant = null;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { id: null },
      queryParamsHandling: 'merge',
    });
  }
  regenerateToken(): void {
    this.userService.regenerateTicket(this.selectedAttendant.ticketId, this.eventId, this.selectedAttendant.ticketTypeId).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Ticket regenerado',
          detail: 'La imagen del ticket fue regenerada correctamente',
        });
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ticket no regenerado',
          detail: 'La imagen del ticket no pudo ser regenerada',
        });
      }
    );
  }
  resendToken(): void {
    this.userService.resendToken(this.selectedAttendant.ticketId).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Email enviado',
          detail: 'El email fue enviado correctamente',
        });
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: 'No se pudo reenviar el email',
        });
      }
    );
  }
  showAssignDialog(): void {
    this.reassignMail = undefined;
    this.reassignMailCheck = false;
    this.displayAssignDialog = true;
  }
  reassignTicket(): void {
    this.userService.reassignToken(this.selectedAttendant.ticketId, this.reassignMail).subscribe(
      (response: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Token reasignado',
          detail: 'El token se reasignó correctamente',
        });
        this.displayAssignDialog = false;
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: 'No se pudo reasignar el token',
        });
        this.displayAssignDialog = false;
      }
    );
  }
  viewAll(): void {
    this.router.navigate(['/users', this.selectedAttendant.userId, 'tokens', this.selectedAttendant.ticketId]);
  }

  hideAddProductDialog(): void {
    this.pressedAssignProduct = false;
  }
  validate(ticketId: string) {
    this.eventService.validateAttendant(ticketId).subscribe(() => {
      this.selectedAttendant.validated = true;
      // TODO: emit event upwards
    });
  }
  unvalidate(ticketId: string) {
    this.eventService.unvalidateAttendant(ticketId).subscribe(() => {
      this.selectedAttendant.validated = false;
      // TODO: emit event upwards
    })
  };

  confirmDeleteAssistant(): void {
    this.displayDeleteAssistantDialog = true;
  }

  hideDeleteAssistantDialog(): void {
    this.displayDeleteAssistantDialog = false;
  }

  deleteAssistant(): void {
    if (!this.selectedAttendant || !this.selectedAttendant.ticketId) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Asistente no seleccionado o ticket inválido.',
      });
      return;
    }

    this.eventService.deleteAssistant(this.selectedAttendant.ticketId, this.eventId).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Asistente Eliminado',
          detail: 'El asistente ha sido eliminado correctamente.',
        });
        this.displayDeleteAssistantDialog = false;
        this.assistantDeleted.emit(this.selectedAttendant.ticketId);
        this.collapsePanel();
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se pudo eliminar el asistente. Por favor, inténtelo de nuevo.',
        });
        this.displayDeleteAssistantDialog = false;
      }
    );
  }

    // Handle delete complement
    handleDeleteComplement(complementId: string): void {
      this.deleteTarget = { type: 'complement', id: complementId };
      this.displayDeleteItemDialog = true;
    }
  
    // Handle delete experience
    handleDeleteExperience(experienceId: string): void {
      this.deleteTarget = { type: 'experience', id: experienceId };
      this.displayDeleteItemDialog = true;
    }
  
    // Confirm deletion
    confirmDeletion(): void {
      if (this.deleteTarget.type === 'complement') {
        this.deleteComplement(this.deleteTarget.id);
      } else if (this.deleteTarget.type === 'experience') {
        this.deleteExperience(this.deleteTarget.id);
      }
      this.displayDeleteItemDialog = false;
      this.deleteTarget = { type: '', id: '' };
    }
  
  // Cancel deletion
  cancelDeletion(): void {
    this.displayDeleteItemDialog = false;
    this.deleteTarget = { type: '', id: '' };
  }

  // Delete complement
  deleteComplement(complementId: string): void {
    this.eventService.unassignComplement(this.selectedAttendant.ticketId, complementId).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Complemento Eliminado',
          detail: 'El complemento ha sido eliminado correctamente.',
        });
        // Remove the complement from the local list
        this.selectedAttendantSales = this.selectedAttendantSales.filter(c => c.productId !== complementId);
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se pudo eliminar el complemento. Por favor, inténtelo de nuevo.',
        });
      }
    );
  }

  // Delete experience
  deleteExperience(experienceId: string): void {
    this.eventService.unassignExperience(this.selectedAttendant.ticketId, experienceId).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Experiencia Eliminada',
          detail: 'La experiencia ha sido eliminada correctamente.',
        });
        // Remove the experience from the local list
        this.selectedAttendantExperiences = this.selectedAttendantExperiences.filter(e => e.id !== experienceId);
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se pudo eliminar la experiencia. Por favor, inténtelo de nuevo.',
        });
      }
    );
  }

}