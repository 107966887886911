import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';

@Component({
  selector: 'app-secondary-market-report',
  templateUrl: './secondary-market-report.component.html',
  styleUrls: ['./secondary-market-report.component.scss']
})
export class SecondaryMarketReportComponent implements OnInit, OnDestroy {
  @Input() timeframe: string = 'all-time';
  @ViewChild('chart', { static: true }) chartElement!: ElementRef;
  private chartInstance: echarts.ECharts | null = null;

  kpiData = [
    { title: 'Entradas reasignadas', value: '23.000' },
    { title: 'Total bruto facturado', value: '123k€' },
    { title: 'Ingresos', value: '12,3k€' },
    { title: 'Incremento medio', value: '18%' }
  ];

  readonly chartOptions: EChartsOption = {
    animation: true,
    animationDuration: 300,
    animationEasing: 'cubicOut',
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none'
      },
      formatter: '{b}: {c}€'
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}€'
      }
    },
    series: [{
      data: [12000, 15000, 18000, 16000, 14000, 20000, 25000, 28000, 32000, 30000, 27000, 22000],
      type: 'line',
      smooth: true,
      smoothMonotone: 'x',
      lineStyle: {
        width: 2
      },
      areaStyle: {
        opacity: 0.3
      },
      symbol: 'none'
    }]
  };

  ngOnInit() { }

  ngAfterViewInit() {
    this.initChart();
  }

  ngOnDestroy() {
    if (this.chartInstance) {
      this.chartInstance.dispose();
      this.chartInstance = null;
    }
  }

  private initChart() {
    if (this.chartElement?.nativeElement) {
      if (this.chartInstance) {
        this.chartInstance.dispose();
      }

      this.chartInstance = echarts.init(this.chartElement.nativeElement);
      this.chartInstance.setOption(this.chartOptions);

      const resizeHandler = () => {
        if (this.chartInstance) {
          this.chartInstance.resize();
        }
      };
      window.addEventListener('resize', resizeHandler);

      this.chartInstance.on('dispose', () => {
        window.removeEventListener('resize', resizeHandler);
      });
    }
  }
}