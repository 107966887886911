export const redirectionNamesMap: { [key: string]: string } = {
    '/messages': 'Mensajes',
    '/customise/improve-experience': 'Mejorar mi experiencia',
    '/customise/merch': 'Merchandising',
    '/customise/cashless': 'Cashless',
    '/customise/transport': 'Transporte',
    '/customise/accommodation': 'Dormir',
    '/customise/food': 'Comer',
    '/customise/drink': 'Beber',
    '/customise/activities': 'Actividades',
    '/streaming': 'Streaming',
    '/agenda': 'Agenda',
    '/moments': 'Momentos',
    '/venue': 'Cómo llego',
    '/': 'Wallet'
  };

  export function getEventSectionsSuffixes(services: string[]): { label: string, value: string }[] {

    /**
     * EXPLICACIÓN:
     * 1. Se elimina advertisements porque la llamada ya recibe "merch" si hay productos. Sería redundante.
     * 2. Se devuelve el sufijo de la URL, por lo que se añade "customise/" a todos los servicios que lo llevan en la app.
     * 3. Se añaden servicios "virtuales" para terminar de poblar el objeto, y así dar soporte a redirigir a mensajes, improve-experience, y wallet.
     */

    let response = services.filter(service => service !== 'advertisements');
  
    response = response.map(service => 
      service === 'streaming' || service === 'agenda' || service === 'moments' ? `/${service}` : `/customise/${service}`
    );
  
    response.unshift('/messages', '/');
    response.splice(2, 0, '/customise/improve-experience');
  
    const serviceOptions = response.map(service => ({
      label: redirectionNamesMap[service],
      value: service
    }));
  
    return serviceOptions;
  }

  export function getUniversalSectionSuffixes(): { label: string, value: string }[] {
    return [
      { label: redirectionNamesMap['/messages'], value: '/messages' },
      { label: redirectionNamesMap['/customise/improve-experience'], value: '/customise/improve-experience' },
      { label: redirectionNamesMap['/'], value: '/' },
    ];
  }