<div *ngIf="displayDialog" class="dimmed-background"></div>

<p-sidebar position="right" [baseZIndex]="10000" styleClass="custom-sidebar" header="Asignar producto"
  [(visible)]="displayDialog" [modal]="true" (onHide)="onDialogHide()">

  <ng-template pTemplate="header">
    <h3>Asignar producto</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <form [formGroup]="customizationForm">
      <div class="field">
        <label for="name" class="block">Producto</label>
        <p-dropdown [options]="products" appendTo="body" formControlName="customization" optionLabel="itemName"
          optionValue="id" placeholder="Selecciona una opción">
          <ng-template let-type pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>
                <p>{{ type.itemName }}</p>
                <p class="sub">
                  {{ type.available }} disponibles
                </p>
              </div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div *ngIf="selectedProduct?.sizes?.length" class="field">
        <label for="size" class="block">Variante</label>
        <p-dropdown [options]="selectedProduct.sizes" appendTo="body" formControlName="size" optionLabel="name"
          optionValue="id" placeholder="Selecciona una opción">
          <ng-template let-item pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>
                <p>{{ item.name }}</p>
                <p class="sub">{{ item.available }} disponibles</p>
              </div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div *ngIf="showQuantityField" class="field">
        <label for="quantity" class="block">Cantidad</label>
        <p-inputNumber id="quantity" formControlName="quantity"></p-inputNumber>
      </div>
      <div
        *ngIf="selectedProduct && selectedProduct.delivery && selectedProduct.delivery.options && selectedProduct.delivery.options.length > 0"
        class="field">
        <label for="deliveryOptions" class="block">Método de entrega</label>
        <p-multiSelect [options]="selectedProduct.delivery.options" appendTo="body" formControlName="deliveryOptions"
          placeholder="Selecciona opciones">
          <ng-template let-option pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>
                {{ option === 'collect' ? 'Recogida en el evento' : option === 'standard' ? 'Envío estándar' : option }}
              </div>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>

    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button (click)="cancelAddProduct()" label="Cancelar" styleClass="p-button-text p-button-danger"></p-button>
    <p-button (click)="confirmAddProduct()" label="Asignar producto" icon="pi pi-send" styleClass="p-button"
      [disabled]="customizationForm.invalid || loading || selectedExperienceStock <= 0"></p-button>
  </ng-template>
</p-sidebar>