import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { getLoggedInUser } from 'src/app/auth/store';
import { CreatedEntity } from '../../model/created-entity.interface';
import { EventExperience, EventTickets } from '../../model/event-detail.interface';
import { PriceChange } from '../../model/price-change.interface';
import { EventService } from '../../service/event.service';
import { PriceInputComponent } from 'src/app/shared/components/price-input/price-input.component';

@Component({
  selector: 'app-event-detail-experiences',
  templateUrl: './event-detail-experiences.component.html',
  styleUrls: ['./event-detail-experiences.component.scss']
})
export class EventDetailExperiencesComponent implements OnInit, OnChanges {

  @Input() eventId: string;
  @Input() experiences: EventExperience[];
  @ViewChild(PriceInputComponent) priceInputComponent: PriceInputComponent;

  includeTaxes: boolean = false;
  defaultPrice: number = 0;
  ticketFee: number = 0;

  ticketTypes: EventTickets[] = []

  experienceForm: FormGroup = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', []),
    images: new FormControl([], []),
    stock: new FormControl('', [Validators.required]),
    regularPrice: new FormControl(0, [Validators.required]),
    displayPrice: new FormControl(0, [Validators.required]),
    includeTaxes: new FormControl(false, []),
    fanDiscount: new FormControl('', []),
    ticketTypes: new FormControl('', []),
    sponsor: new FormGroup({
      image: new FormControl('', [])
    }),
    allowMultiple: new FormControl(false, [])
  });
  display: boolean = false;
  creatingExperience: boolean = false;

  constructor(private eventService: EventService, private messageService: MessageService, private store: Store) { }

  ngOnInit(): void {
    this.store.select(getLoggedInUser).subscribe(observer => {
      this.ticketFee = observer?.ticketFee;
    });


  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['eventId'] && changes['eventId'].currentValue) {
      this.eventService.getTickets(this.eventId).subscribe((response: EventTickets[]) => this.ticketTypes = response);
    }
  }

  addExperience(): void {
    this.experienceForm.reset();
    this.includeTaxes = false;
    this.defaultPrice = 0;
    this.priceInputComponent.reset();
    this.display = true;
    this.experienceForm.patchValue({
      regularPrice: 0,
      displayPrice: 0
    });
  }

  createExperience() {
    this.creatingExperience = true;

    const experienceId = this.experienceForm.get('id').value;
    if (experienceId) {
      this.eventService.updateExperience(this.eventId, experienceId, this.experienceForm.value).subscribe(() => {
        this.messageService.add({ severity: 'success', summary: 'Experiencia modificada correctamente', detail: 'Recarga la página para ver los cambios' });
        this.display = false;
        this.creatingExperience = false;
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Revisa los datos y vuelve a intentarlo' });
        this.creatingExperience = false;
      });
    } else {
      this.eventService.createExperience(this.eventId, this.experienceForm.value).subscribe((response: CreatedEntity) => {
        this.messageService.add({ severity: 'success', summary: 'Experiencia creada correctamente', detail: '' });
        const newExperience: EventExperience = this.experienceForm.value;
        newExperience.id = response.created;
        newExperience.sold = 0;
        this.experiences.push(newExperience);
        this.display = false;
        this.creatingExperience = false;
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Revisa los datos y vuelve a intentarlo' });
        this.creatingExperience = false;
      });
    }
  }

  updateExperience($event: EventExperience) {

    this.experienceForm.patchValue($event);

    this.includeTaxes = $event.includeTaxes;

    if (this.includeTaxes) {

      this.defaultPrice = $event.displayPrice / 100;

    } else {

      this.defaultPrice = $event.regularPrice / 100;
    }

    this.display = true;

  }

  removeExperienceFromList($event: string) {
    const index = this.experiences.findIndex(experience => experience.id === $event);

    if (index !== -1) {
      this.experiences.splice(index, 1);
    }
  }


  onExperiencePriceChange($event: PriceChange) {

    this.experienceForm.patchValue({
      regularPrice: $event.basePrice,
      displayPrice: $event.finalPrice,
      includeTaxes: $event.includeTaxes
    });
  }

  onUpload($event: any, group: string): void {

    if (group === 'sponsor') {

      this.experienceForm.get('sponsor').patchValue({ image: $event });

    } else {

      let images = this.experienceForm.get('images').value;

      if (!images) {
        images = [];
      }

      images.push($event);

      this.experienceForm.patchValue({ images: images });
    }

  }

  removeImage($event: any, group: string): void {

    if (group === 'sponsor') {

      this.experienceForm.get('sponsor').patchValue({ image: undefined });

    } else {

      let images = this.experienceForm.get('images').value;

      images = images.filter(image => image !== $event);

      this.experienceForm.patchValue({ images: images });
    }

  }


}
