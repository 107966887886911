import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PublicRelation } from '../../model/public-relation.interface';
import { RelationService } from '../../service/relation.service';
import { Observable } from 'rxjs';
import { Relation } from '../../model/relations/relation.interface';
import { LinkUtils } from '../../util/link.util';

@Component({
  selector: 'app-event-detail-public-relations',
  templateUrl: './event-detail-public-relations.component.html',
  styleUrls: ['./event-detail-public-relations.component.scss']
})
export class EventDetailPublicRelationsComponent implements OnChanges {

  @Input() eventId: string;
  @Input() eventUrl: string;

  relations$: Observable<Relation[]>;

  publicRelations: PublicRelation[] = [];
  selectedPublicRelations: PublicRelation;
  showCreateDialog: boolean = false;

  constructor(private relationService: RelationService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventId'] && changes['eventId'].currentValue) {
      this.relations$ = this.relationService.getRelations(this.eventId);
    }
  }

  openPublicRelations($event: PublicRelation): void {

  }

  pauseRelation(relationId: string, relation: Relation): void {

    if (relation.active === true) {
      relation.active = false;
    } else {
      relation.active = true;
    }

    this.relationService.updateRelation(this.eventId, relationId, relation).subscribe(() => {
      // TODO: show message success
    }, () => {
      // TODO: show message error
    });
  }

  removeRelation(relationId: string): void {

    this.relationService.removeRelation(this.eventId, relationId).subscribe(() => {
      // TODO: show message success
    }, () => {
      // TODO: show message error
    });
  }

  create(): void {

    this.showCreateDialog = true;
  }

  hideDialog(): void {

    this.showCreateDialog = false;
  }

  copyLink(relationSlug: string): void {

    LinkUtils.copy(this.eventUrl + "?invite=" + relationSlug);
  }

}
