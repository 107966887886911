<app-generic-list-item [image]="ticket?.imageUrl" [title]="ticket?.name" [description]="ticket?.description"
  [additionalInfo]="ticket?.displayPrice / 100 | currency : 'EUR' : 'symbol' : '1.2-2'" [sold]="ticket?.ticketsSold"
  [stock]="ticket?.ticketsNumber">
  <div item-actions class="ticket-actions">
    <button pButton class="p-button-text p-button-rounded" icon="pi pi-pencil"
      (click)="editTicket()"
      pTooltip="Editar entrada" tooltipPosition="top">
    </button>
    <button pButton class="p-button-text p-button-rounded" icon="pi pi-trash" 
      [disabled]="ticket?.ticketsSold > 0"
      (click)="removeTicket()"
      pTooltip="Eliminar entrada" tooltipPosition="top">
    </button>
    <button *ngIf="ticket?.id !== null" pButton class="p-button-text p-button-rounded" icon="pi pi-sort-alt" 
      (click)="showChangeTicketsDialog()"
      pTooltip="Modificar número de entradas" tooltipPosition="top">
    </button>
    <app-custom-toggle
      *ngIf="ticket?.id !== null"
      [isActive]="ticket.active" 
      (changed)="handleToggleChange($event)" 
      pTooltip="{{ ticket.active ? 'Activo' : 'Inactivo' }}" 
      tooltipPosition="top">
    </app-custom-toggle>
    <button *ngIf="ticket?.id !== null" pButton icon="pi pi-send" label="Enviar mensaje"
      (click)="createCampaign()"
      pTooltip="Enviar mensaje" tooltipPosition="top">
    </button>
  </div>
</app-generic-list-item>

<app-ticket-change-dialog
  [(visible)]="changeTicketsDialogVisible"
  [currentTickets]="ticket?.ticketsNumber"
  [maxRemovable]="ticketsAvailableToRemove"
  (apply)="applyTicketChanges($event)"
></app-ticket-change-dialog>

<app-show-message-dialog 
  section="ticketType" 
  [eventId]="eventId" 
  [referenceId]="ticket?.id"
  [show]="messageDialogVisible" 
  (hide)="onMessageDialogHide()"
></app-show-message-dialog>