<div class="detail-panel" [ngClass]="{'collapsed': !selectedAttendant}">
    <div *ngIf="selectedAttendant" [@slideInOut] [style]="{ width: '100%', height: '100%', overflowY: 'auto' }"
      class="custombar1">
      <div class="scrollable-content">
        <div class="panel-header flex flex-row justify-content-between">
          <div class="actions-left flex flex-row">
            <button class="p-button-text mr-3" pButton pRipple icon="pi pi-sync" pTooltip="Regenerar entrada"
              tooltipPosition="bottom" type="button" (click)="regenerateToken()"></button>
            <button class="p-button-text mr-3" pButton pRipple icon="pi pi-send" pTooltip="Reenviar entrada"
              tooltipPosition="bottom" type="button" (click)="resendToken()"></button>
            <button class="p-button-text mr-3" pButton pRipple icon="pi pi-users" pTooltip="Reasignar entrada"
              tooltipPosition="bottom" type="button" (click)="showAssignDialog()"></button>
            <button *ngIf="selectedAttendant.validated === false" pButton pRipple type="button" pTooltip="Confirmar"
              tooltipPosition="top" class="p-button-text" icon="pi pi-check-circle"
              (click)="validate(selectedAttendant.ticketId)"></button>
            <button *ngIf="selectedAttendant.validated === true" pButton pRipple type="button" pTooltip="Invalidar"
              tooltipPosition="top" class="p-button-text" icon="pi pi-undo"
              (click)="unvalidate(selectedAttendant.ticketId)"></button>
              <button
              pButton
              pRipple
              type="button"
              pTooltip="Eliminar asistente"
              tooltipPosition="top"
              class="p-button-text p-button-danger ml-3"
              icon="pi pi-trash"
              (click)="confirmDeleteAssistant()">
            </button>
          </div>
          <button pButton icon="pi pi-times" (click)="collapsePanel()" class="collapse-btn p-button-text"></button>
        </div>
        <div class="panel-title">
          <h3 class="attendant-name">
            <ng-container *ngIf="selectedAttendant.fullName; else showEmail">
              {{ selectedAttendant.fullName }}
            </ng-container>
            <ng-template #showEmail>
              {{ selectedAttendant.email }}
            </ng-template>
          </h3>
        </div>
  
        <div class="content-body">
            <div class="basic-info">
                <div>
                  <span class="mb-2 font-semibold">Tipo de Entrada</span>
                  <p>{{ selectedAttendant.ticketTypeName }}</p>
                </div>
      
                <div>
                  <span class="mb-2 font-semibold">Estado de Validación</span>
                  <p>{{ selectedAttendant.validated ? "Validado" : "No Validado" }}</p>
                </div>
      
                <div>
                  <span class="mb-2 font-semibold">Fecha de Compra</span>
                  <p>{{ selectedAttendant.purchasedAt | date : "dd/MM/yyyy HH:mm" }}</p>
                </div>
            </div>
  
            <div class="additional-experience-section">
              <span class="mb-2 font-semibold">Experiencias Adicionales</span>
              <app-complement-experience-list 
                type="experience"
                [experiences]="selectedAttendantExperiences"
                (deleteExperience)="handleDeleteExperience($event)">
              </app-complement-experience-list>
              <div class="add-button-container">
                <button pButton pRipple type="button" label="Añadir experiencia" icon="pi pi-plus"
                  (click)="displayAddCustomizationDialog = true" class="p-button-text add-button"></button>
              </div>
            </div>
            
            <div class="additional-complement-section">
              <span class="mb-2 font-semibold">Complementos Adicionales</span>
              <app-complement-experience-list 
                type="complement"
                [complements]="selectedAttendantSales"
                (deleteComplement)="handleDeleteComplement($event)">
              </app-complement-experience-list>
              <div class="add-button-container">
                <button pButton pRipple type="button" label="Añadir producto" icon="pi pi-plus"
                  (click)="pressedAssignProduct = true" class="p-button-text add-button"></button>
              </div>
            </div>
  
          <div class="content-footer">
            <button pButton pRipple class="viewAll-button p-button-text" type="button" label="Ver todo"
              (click)="viewAll()"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-add-product-dialog [tokenId]="selectedAttendant?.ticketId" [displayDialog]="pressedAssignProduct"
    (hideDialog)="hideAddProductDialog()"></app-add-product-dialog>
  <app-customization-dialog [tokenId]="selectedAttendant?.ticketId" [displayDialog]="displayAddCustomizationDialog"
    (hideDialog)="hideCustomizationDialog()"></app-customization-dialog>

<p-sidebar [(visible)]="displayAssignDialog" [modal]="true" position="right" [baseZIndex]="10000"
  styleClass="custom-sidebar">
  <ng-template pTemplate="header">
    <h3>Reasignar entrada</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="mb-3">
      <input type="email" placeholder="Escribe el nuevo email" pInputText [(ngModel)]="reassignMail" />
    </div>
    <div class="flex flex-row align-items-center">
      <p-checkbox [(ngModel)]="reassignMailCheck" value="New York" inputId="ny"></p-checkbox>
      <label class="ml-2" for="ny">Confirmo que quiero reasignar esta entrada</label>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button label="Reasignar" class="p-button-danger"
      [disabled]="reassignMail === undefined || reassignMailCheck === false" (click)="reassignTicket()"></p-button>
  </ng-template>
</p-sidebar>

<!-- Confirmation Dialog for Deleting Assistant -->
<p-dialog
  header="Confirmar Eliminación"
  [(visible)]="displayDeleteAssistantDialog"
  [modal]="true"
  [closable]="false"
  [responsive]="true"
  [style]="{ width: '350px' }">
  
  <div class="confirmation-content">
    <i class="pi pi-exclamation-triangle alert-icon"></i>
    <span class="alert-text">
      <strong>¡Cuidado!</strong> Eliminar el asistente no inicia la devolución en caso de que la entrada haya tenido gastos asociados. 
      Si deseas solicitar una devolución, por favor, <a href="mailto:support@nevent.es">contacta con soporte</a>.
    </span>
  </div>
  
  <ng-template pTemplate="footer">
    <button
      pButton
      label="Cancelar"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDeleteConfirmDialog()">
    </button>
    <button
      pButton
      label="Eliminar"
      icon="pi pi-check"
      class="p-button-danger"
      (click)="deleteAssistant()">
    </button>
  </ng-template>
</p-dialog>

<!-- Confirmation Dialog for Deleting Items -->
<p-dialog
  header="Confirmar Eliminación"
  [(visible)]="displayDeleteItemDialog"
  [modal]="true"
  [closable]="false"
  [responsive]="true"
  [style]="{ width: '350px' }">

  <div class="confirmation-content">
    <i class="pi pi-exclamation-triangle alert-icon"></i>
    <span class="alert-text">
      <strong>¡Cuidado!</strong> Desasignar una personalización o complemento no inicia la devolución en caso de su adquisición haya tenido gastos asociados por parte del usuario. 
      Si deseas solicitar una devolución, por favor, <a href="mailto:support@nevent.es">contacta con soporte</a>.
    </span>
  </div>

  <ng-template pTemplate="footer">
    <button
      pButton
      label="Cancelar"
      icon="pi pi-times"
      class="p-button-text"
      (click)="cancelDeletion()">
    </button>
    <button
      pButton
      label="Eliminar"
      icon="pi pi-check"
      class="p-button-danger"
      (click)="confirmDeletion()">
    </button>
  </ng-template>
</p-dialog>