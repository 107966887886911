import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EventDescription } from '../../model/event-detail.interface';

@Component({
  selector: 'app-event-detail-description',
  templateUrl: './event-detail-description.component.html',
  styleUrls: ['./event-detail-description.component.scss']
})
export class EventDetailDescriptionComponent implements OnInit, OnChanges {

  @Input() eventId: string;
  @Input() description: EventDescription;

  descriptionForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(20)]),
    description: new FormControl('', []),
    imageUrl: new FormControl('', [])
  })

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes): void {
    if (changes && changes.description && changes.description.currentValue) {
      this.descriptionForm.patchValue(this.description);
    }
  }

}
