<div formGroupName="assignation">
  <h3>Asignación de experiencias</h3>

  <div formGroupName="fields">
    <h4>Personalizar campos</h4>

    <div class="field-grid">
      <ng-container *ngFor="let field of ['name', 'surname', 'phone', 'fiscalId', 'genre', 'birth']">
        <div class="field-row" [formGroupName]="field">
          <div class="field-label">
            <p-checkbox
              [id]="field + 'Enabled'"
              [binary]="true"
              formControlName="enabled"
            ></p-checkbox>
            <label [for]="field + 'Enabled'">{{ getFieldLabel(field) }}</label>
          </div>
          <div class="field-control">
            <label [for]="field + 'Required'">Obligatorio</label>
            <p-inputSwitch [id]="field + 'Required'" formControlName="required"></p-inputSwitch>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <h4>Información adicional</h4>

  <div>
    <ng-container formArrayName="additional">
      <ng-container *ngFor="let additionalFieldForm of fields.controls; let i = index">
        <div class="additional-field" [formGroup]="additionalFieldForm">
          <div class="field-row">
            <div class="field-control">
              <input type="text" pInputText formControlName="name" placeholder="Título" />
            </div>
            <div class="field-control">
              <p-dropdown [options]="types" formControlName="type" optionLabel="name" optionValue="code" [appendTo]="'body'"></p-dropdown>
            </div>
            <div class="field-control">
              <label>Obligatorio</label>
              <p-inputSwitch formControlName="required"></p-inputSwitch>
            </div>
            <div class="field-control">
              <button pButton type="button" class="p-button-text p-button-danger" icon="pi pi-trash" (click)="deleteAdditionalField(i)"></button>
            </div>
          </div>
          
          <div class="field-row">
            <div class="field-control w-full">
              <textarea pInputTextarea formControlName="description" placeholder="Descripción (opcional)" rows="2" class="w-full"></textarea>
            </div>
          </div>

          <!-- Options for single-select and multi-select -->
          <div *ngIf="additionalFieldForm.get('type').value === 'single-select' || additionalFieldForm.get('type').value === 'multi-select'" class="options-container">
            <div formArrayName="options">
              <div *ngFor="let option of getOptions(additionalFieldForm)?.controls; let j = index" class="option-row">
                <div class="field-control">
                  <input type="text" pInputText [formControlName]="j" placeholder="Opción {{j + 1}}" />
                </div>
                <div class="field-control">
                  <button pButton type="button" class="p-button-text p-button-danger" icon="pi pi-trash" (click)="deleteOption(additionalFieldForm, j)"></button>
                </div>
              </div>
            </div>
            <button pButton type="button" class="p-button-text p-button-sm" icon="pi pi-plus" label="Añadir opción" (click)="addOption(additionalFieldForm)"></button>
          </div>
          <div *ngIf="additionalFieldForm.errors?.noOptions" class="p-error">
            Este campo requiere al menos una opción.
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <button pButton type="button" label="Añadir campo" class="p-button-text" icon="pi pi-plus" (click)="addField()"></button>
</div>