<div class="service text-center p-3 border-round-sm" (click)="enable(item?.id)"
    [ngClass]="isEnabled(item?.id) ? 'active' : 'non-active'">
    <div class="flex flex-column justify-content-start align-items-center h-full">
        <div class="flex flex-row w-100 justify-content-between align-items-center">
            <div>
                <i [class]="item?.icon" style="font-size: 2rem"></i>
            </div>
            <div *ngIf="isEnabled(item?.id)">
                <span class="active">Activo</span>
            </div>
        </div>
        <div class="flex flex-row w-100 justify-content-start">
            <h4>{{item?.title}}</h4>
        </div>
        <div class="flex flex-row w-100 justify-content-start">
            <p>{{item.description}}</p>
        </div>
        <div class="flex flex-row w-100 justify-content-start">
            <button *ngIf="!isEnabled(item?.id)" pButton type="button" label="Activar" class="p-button-text"></button>
            <button *ngIf="isEnabled(item?.id)" pButton type="button" label="Desactivar" class="p-button-text"></button>
        </div>
    </div>
</div>