<!-- create-event.component.html -->

<p-toast position="top-right"></p-toast>
<div class="card h-full">
  <div class="card-container justify-content-start border-round p-3">
    <!-- Dynamic Steps -->
    <p-steps [model]="stepItems" [activeIndex]="activeStep" [hidden]="activeStep === stepItems.length && success === true"></p-steps>

    <!-- Step 0: Descripción -->
    <ng-template [ngIf]="activeStep === 0">
      <app-step-description (next)="onNext($event)" (save)="onSave($event)"
        [data]="formValue?.value.description"></app-step-description>
    </ng-template>

    <!-- Step 1: Celebración -->
    <ng-template [ngIf]="activeStep === 1">
      <app-step-venue (next)="onNext($event)" (save)="onSave($event)" [data]="formValue?.value.venue"></app-step-venue>
    </ng-template>

    <!-- Step 2: Canales de venta -->
    <ng-template [ngIf]="activeStep === 2">
      <app-step-channels (next)="onNext($event)" (prev)="onPrev()"></app-step-channels>
    </ng-template>

    <!-- Step 3: Entradas -->
    <ng-template [ngIf]="activeStep === 3 && isEntradasStepAdded">
      <app-step-tickets (next)="onNext($event)" (prev)="onPrev()" [data]="formValue?.value.tickets" [startDate]="startDate" [endDate]="endDate"></app-step-tickets>
    </ng-template>

    <!-- Step 3: Revisión (When Entradas is not added) -->
    <ng-template [ngIf]="activeStep === 3 && !isEntradasStepAdded">
      <app-step-confirmation [eventId]="eventId" [loading]="loading" (next)="onNext($event)" (save)="onSave($event)"
        (complete)="onComplete($event)" [data]="data.value"></app-step-confirmation>
    </ng-template>

    <!-- Step 4: Revisión (After Entradas) -->
    <ng-template [ngIf]="activeStep === 4 && isEntradasStepAdded">
      <app-step-confirmation [eventId]="eventId" [loading]="loading" (next)="onNext($event)" (save)="onSave($event)"
        (complete)="onComplete($event)" [data]="data.value"></app-step-confirmation>
    </ng-template>

    <!-- Step 5: Share -->
    <ng-template [ngIf]="activeStep === stepItems.length && success === true">
      <app-step-share [eventUrl]="createdEvent?.url" [eventId]="createdEvent?.id"
        [eventName]="createdEvent?.url?.split('/').pop()"></app-step-share>
    </ng-template>
  </div>
</div>
