import { HttpBackend, HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SignedURL } from 'src/app/event/model/signed-url.interface';
import { environment } from 'src/environments/environment';
import { Merch } from '../model/merch';
import { MerchCreate } from '../model/merch-create.interface';
import { MerchDelivery } from '../model/merch-delivery.interface';
import { Sale } from 'src/app/event/model/sale.interface';
import { PagedResponse } from 'src/app/shared/model/paged-response.interface';

export interface MerchPage {
  content: Merch[];
  totalElements: number;
  totalPages: number;
  number: number;
  size: number;
}

@Injectable({
  providedIn: 'root'
})
export class MerchService {

  baseUrl: string = environment.apiUrl

  constructor(private http: HttpClient, private handler: HttpBackend) {
  }

  getMerch(): Observable<Merch[]>;
  getMerch(
    category: string,
    page: number,
    size: number,
    sort?: string,
    order?: number,
    search?: string
  ): Observable<PagedResponse<Merch>>;
  getMerch(
    category?: string,
    page?: number,
    size?: number,
    sort?: string,
    order?: number,
    search?: string
  ): Observable<Merch[] | PagedResponse<Merch>> {
    // Legacy endpoint
    if (category === undefined && page === undefined && size === undefined) {
      return this.http.get<Merch[]>(`${this.baseUrl}/merch`);
    }

    // Paginated endpoint
    let params = new HttpParams();
    
    if (category && category !== 'all') {
      params = params.set('category', category);
    }
    if (page !== undefined) {
      params = params.set('page', page.toString());
    }
    if (size !== undefined) {
      params = params.set('size', size.toString());
    }
    if (sort) {
      params = params.set('sort', sort);
    }
    if (order !== undefined) {
      params = params.set('order', order.toString());
    }
    if (search) {
      params = params.set('search', search);
    }

    return this.http.get<PagedResponse<Merch>>(`${this.baseUrl}/merch`, { params });
  }


  getMerchById(merchId: string): Observable<Merch> {

    const url = `${this.baseUrl}/merch/${merchId}`;

    return this.http.get<Merch>(url);
  }

  getMerchSales(merchId: string): Observable<Sale[]> {

    const url = `${this.baseUrl}/merch/${merchId}/sales`;

    return this.http.get<Sale[]>(url);
  }

  getMerchDeliveryInfo(merchId: string, tokenId: string): Observable<any> {

    const url = `${this.baseUrl}/merch/${merchId}/delivery/${tokenId}`;

    return this.http.get<any>(url);
  }

  sendMessage(merchId: string, body: { message: string; redirectToSuffix: string; }) {

    const url = `${this.baseUrl}/merch/${merchId}/messages`;

    return this.http.post<any>(url, body);
  }

  createMerch(data: MerchCreate): Observable<void> {

    const url = `${this.baseUrl}/merch`

    return this.http.post<void>(url, data);

  }

  updateMerch(merchId: string, data: MerchCreate): Observable<void> {

    const url = `${this.baseUrl}/merch/${merchId}`;

    return this.http.put<void>(url, data);
  }

  getMerchDelivery(merchId: string): Observable<MerchDelivery> {

    const url = `${this.baseUrl}/merch/${merchId}/delivery`;

    return this.http.get<MerchDelivery>(url);
  }

  updateDelivery(merchId: string, data: MerchDelivery): Observable<void> {

    const url = `${this.baseUrl}/merch/${merchId}/delivery`;

    return this.http.put<void>(url, data);
  }

  disableMerch(merchId: string, active: boolean): Observable<void> {

    const url = `${this.baseUrl}/merch/${merchId}/active?value=${active}`;

    return this.http.put<void>(url, undefined);

  }

  deleteMerch(merchId: string) {
    const url = `${this.baseUrl}/merch/${merchId}`

    return this.http.delete(url);
  }

  getSignedUrl(imageId: string, mimeType: string): Observable<SignedURL> {

    const url = `${this.baseUrl}/merch/${imageId}/upload`

    return this.http.get<SignedURL>(url, { params: { mimeType } });
  }

  uploadImage(fileuploadurl, contenttype, file): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': contenttype });
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      file,
      {
        headers: headers,
        reportProgress: true,
      });
    return this.http.request(req);
  }
}
