import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettingsService } from 'src/app/settings/service/settings-service.interface';

interface Channel {
  name: string;
  logo: string;
}

@Component({
  selector: 'app-step-channels',
  templateUrl: './step-channels.component.html',
  styleUrls: ['./step-channels.component.scss']
})
export class StepChannelsComponent implements OnInit {
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() prev: EventEmitter<void> = new EventEmitter();

  channelForm: FormGroup;
  selectedChannel: Channel | null = null;
  emailSent: boolean = false; // New property to track email status


  // Modal Properties
  displayModal: boolean = false;
  modalChannel: Channel | null = null;

  tabOptions = [
    { label: 'Ticketing', value: 'ticketing' },
    { label: 'Formularios', value: 'forms' },
  ];

  ticketingChannels: Channel[] = [
    { name: 'Nevent tickets', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/nevent-tickets.webp' },
    { name: 'Fourvenues', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/fourvenues.webp'},
    { name: 'Enterticket', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/enterticket.webp' },
    { name: 'Eventbrite', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/eventbrite.webp' },
    { name: 'Onebox', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/onebox.webp' },
    { name: 'Shotgun', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/shotgun.webp' },
    { name: 'Janto', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/janto.webp' },
    { name: 'Eventix', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/eventix.webp' },
    { name: 'Eventim', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/eventim.webp' },
    { name: 'TicketTailor', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/tickettailor.webp' },
  ];

  formChannels: Channel[] = [
    { name: 'Google Forms', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/google-forms.webp' },
    { name: 'Tally', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/tally.webp' },
    { name: 'Microsoft Forms', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/microsoft-forms.webp' },
    { name: 'Typeform', logo: 'https://prd-nevent-public.s3.eu-west-1.amazonaws.com/logos/typeform.webp' },
  ];

  constructor(private fb: FormBuilder, private tenantService: SettingsService) {
    this.channelForm = this.fb.group({
      activeTab: ['ticketing']
    });

    this.channelForm.get('activeTab')?.valueChanges.subscribe(() => {
      this.selectedChannel = null;
    });
  }

  ngOnInit() {
    this.selectedChannel = this.ticketingChannels.find(channel => channel.name === 'Nevent tickets') || null;
  }

  get displayedChannels(): Channel[] {
    return this.channelForm.get('activeTab')?.value === 'ticketing' 
      ? this.ticketingChannels 
      : this.formChannels;
  }

  selectChannel(channel: Channel): void {
    if (channel.name === 'Nevent tickets') {
      if (this.selectedChannel?.name !== 'Nevent tickets') {
        this.selectedChannel = channel;
      }
    } else {
      this.modalChannel = channel;
      this.displayModal = true;
    }
  }

  nextPage(): void {
    if (this.selectedChannel) {
      this.next.emit({
        next: 1,
        name: 'canales',
        selectedChannel: this.selectedChannel
      });
    }
  }

  prevPage(): void {
    this.prev.emit();
  }

  notifyWhenAvailable(): void {
    if (this.modalChannel) {
      this.tenantService.requestChannelIntegration(this.modalChannel.name).subscribe({
        next: (response) => {
          console.log('Channel integration request sent successfully:', response);
          this.emailSent = true;
        },
        error: (error) => {
          console.error('Error sending channel integration request:', error);
          alert('There was an error sending your request. Please try again later.');
        }
      });
    }
  }
}
