<p-sidebar [(visible)]="visible" position="right" [baseZIndex]="10000" styleClass="custom-sidebar" (onHide)="onCancel()" [appendTo]="'body'">
  <ng-template pTemplate="header">
    <h3 class="text-xl font-semibold mb-0">Modificar número de entradas</h3>
  </ng-template>

  <form class="p-4">
    <section>
      <h4 class="text-lg font-medium mb-2">Ajuste de entradas</h4>
      <p class="text-sm text-gray-600 mb-4">Modifica el número total de entradas para este ticket</p>
      
      <div class="field">
        <label for="ticketInput" class="font-medium block mb-2">
          Número total de entradas
        </label>
        <div class="flex flex-column">
          <p-inputNumber 
            [(ngModel)]="newTotal" 
            [showButtons]="true"
            [min]="minTotal"
            [max]="maxTotal"
            buttonLayout="horizontal"
            spinnerMode="horizontal"
            decrementButtonClass="p-button-danger"
            incrementButtonClass="p-button-success"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            [step]="1"
            styleClass="w-full"
            inputStyleClass="text-center"
            name="ticketInput">
          </p-inputNumber>
          <div class="flex justify-content-between mt-2 text-sm text-gray-600">
            <span>Mínimo: {{minTotal}}</span>
            <span>Máximo: {{maxTotal}}</span>
          </div>
        </div>
      </div>

      <div class="mt-6 p-3 border-round" [ngClass]="{
        'bg-green-100': difference > 0,
        'bg-red-100': difference < 0,
        'bg-gray-100': difference === 0
      }">
        <p class="m-0 text-center font-medium" [ngClass]="{
          'text-green-700': difference > 0,
          'text-red-700': difference < 0,
          'text-gray-700': difference === 0
        }">
          <ng-container *ngIf="difference > 0">
            Añadirás <strong>{{difference}}</strong> entradas.
          </ng-container>
          <ng-container *ngIf="difference < 0">
            Eliminarás <strong>{{-difference}}</strong> entradas.
          </ng-container>
          <ng-container *ngIf="difference === 0">
            No hay cambios en el número de entradas.
          </ng-container>
        </p>
      </div>

      <ng-container *ngIf="!isValid">
        <small class="p-error block mt-2 text-center">{{errorMessage}}</small>
      </ng-container>
    </section>
  </form>

  <ng-template pTemplate="footer">
    <div class="flex gap-2 p-4 border-top-1 border-gray-200">
      <p-button label="Cancelar" icon="pi pi-times" (onClick)="onCancel()" styleClass="p-button-outlined"></p-button>
      <p-button label="Aplicar cambios" icon="pi pi-check" (onClick)="onApply()" [disabled]="!isValid || difference === 0"></p-button>
    </div>
  </ng-template>
</p-sidebar>