import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, observable, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventContent } from '../model/event-detail.interface';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  baseUrl: string = environment.apiUrl

  constructor(private http: HttpClient) { }

  addContent(eventId: string, content: EventContent): Observable<any> {

    const url = `${this.baseUrl}/events/${eventId}/content`;

    return this.http.post<EventContent>(url, content).pipe(catchError(
      error => throwError(() => error)));
  }

  removeContent(eventId: string, contentId: string) {

    const url = `${this.baseUrl}/events/${eventId}/content/${contentId}`;

    return this.http.delete<void>(url);

  }

  getSignedUrl(eventId: string, contentId: string): Observable<any> {

    const url = `${this.baseUrl}/events/${eventId}/content/${contentId}`;

    return this.http.get<void>(url);
  }

  changeAccessControl(eventId: string, contentId: string, content: EventContent) {

    const url = `${this.baseUrl}/events/${eventId}/content/${contentId}`;

    return this.http.put<void>(url, content);
  }

  uploadFile(fileuploadurl, contenttype, file): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': contenttype });
    
    return this.http.put(fileuploadurl, file);
  }
}
