<p-card
  [header]="getProductAttribute('name')"
  [subheader]="product.quantity + ' unidades'"
>
  <div class="flex flex-column">
    <p *ngIf="product.size; else uniqueSize">Talla: {{ product.size }}</p>
    <ng-template #uniqueSize>
      <p>Talla: Única</p>
    </ng-template>
  </div>
  <ng-template pTemplate="footer">
    <div class="grid grid-nogutter justify-content-between">
      <div class="flex-grow-1"></div>
      <div>
        <button
          pButton
          class="p-button-text"
          *ngIf="product.id"
          label="Ver detalle de producto"
          [routerLink]="['/products', product.id]"
        ></button>
      </div>
    </div>
  </ng-template>
</p-card>
