import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Relation } from '../model/relations/relation.interface';
import { RelationCreate } from '../model/relations/relation-create.interface';

@Injectable({
  providedIn: 'root'
})
export class RelationService {

  baseUrl: string = environment.apiUrl

  constructor(private http: HttpClient) {
  }

  getRelations(eventId: string): Observable<Relation[]> {

    const url = `${this.baseUrl}/events/${eventId}/relations`

    return this.http.get<Relation[]>(url);

  }

  createRelation(eventId: string, relation: RelationCreate): Observable<Relation> {

    const url = `${this.baseUrl}/events/${eventId}/relations`

    return this.http.post<Relation>(url, relation);

  }

  updateRelation(eventId: string, relationId: string, relation: RelationCreate): Observable<Relation> {

    const url = `${this.baseUrl}/events/${eventId}/relations/${relationId}`

    return this.http.put<Relation>(url, relation);

  }

  removeRelation(eventId: string, relationId: string): Observable<void> {

    const url = `${this.baseUrl}/events/${eventId}/relations/${relationId}`

    return this.http.delete<void>(url);

  }
}
