<p-dialog header="Asociar consumo" [(visible)]="displayAddDialog" [style]="{ width: '40vw', height: '35vh' }"
  [modal]="true" (onHide)="onDialogHide()">
  <p>Asocia un consumo al token manualmente</p>
  <form [formGroup]="expenseForm">
    <div class="field flex flex-column">
      <p-inputNumber id="stock" formControlName="amount" mode="currency" currency="EUR" locale="es-ES"
        class="text-base text-color surface-overlay p-2 appearance-none outline-none focus:border-primary w-full"></p-inputNumber>
    </div>
    <button pButton pRipple *ngIf="!loading" [disabled]="!expenseForm.valid" label="Asociar consumo" class="w-full"
      (click)="addContent()"></button>
    <button pButton pRipple *ngIf="loading" [disabled]="loading" icon="pi pi-spin pi-spinner" iconPos="right"
      label="Asociar consumo" class="w-full"></button>
  </form>
</p-dialog>