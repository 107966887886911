import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { EventService } from '../../service/event.service';
import { Sale } from '../../model/sale.interface';
import { Table } from 'primeng/table';
import { DeliveryService } from 'src/app/shared/services/delivery.service';
import { Observable } from 'rxjs';
import { PagedResponse } from 'src/app/shared/model/paged-response.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-event-detail-sales',
  templateUrl: './event-detail-sales.component.html',
  styleUrls: ['./event-detail-sales.component.scss']
})
export class EventDetailSalesComponent implements OnInit, OnChanges {

  @Input() eventId: string;
  sales: Sale[];
  page: number = 0;
  rows: number = 25;

  constructor(private deliveryService: DeliveryService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.page = parseInt(params['page'] || '0');
      this.rows = parseInt(params['size'] || '25');
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventId'] && changes['eventId'].currentValue) {
      this.loadSales();
    }
  }

  private loadSales(): void {
    this.deliveryService.retrieveSales(this.eventId, this.page, this.rows)
      .subscribe((response: PagedResponse<Sale>) => {
        this.sales = response.content;
      });
  }

}
