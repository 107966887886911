import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../user/service/user.service';
import { User } from 'src/app/user/model/user.interface';

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss']
})
export class CreateDialogComponent implements OnInit {


  @Input() displayCreateDialog: boolean;
  @Output() hideDialog: EventEmitter<User> = new EventEmitter();

  createForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    name: new FormControl('', []),
    surname1: new FormControl('', []),
    fiscalId: new FormControl('', []),
    phone: new FormControl('', []),
    labels: new FormControl([], []),
    linkedin: new FormControl('', []),
    instagram: new FormControl('', []),
    twitter: new FormControl('', []),
    inviteMail: new FormControl(false, [])
  });

  loading: boolean = false;
  errorMessage: string = '';

  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }

  addUser(): void {
    this.loading = true;
    this.userService.createUser(this.createForm.value).subscribe((user: User) => {
      this.hideDialog.emit(user);
      this.loading = false;
    }, () => {
      // TODO show errors
      this.loading = false;
    });
  }

  onDialogHide(): void {

    this.hideDialog.emit(undefined);
  }

}
