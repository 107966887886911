<p-card>
    <ng-template pTemplate="content">
        <div class="flex flex-column align-items-center justify-content-center flex-wrap card-container">
            <div class="flex flex-row w-full align-items-center justify-content-center">
                <div>
                    <i class="pi pi-check-circle" style="font-size: 3rem; color: green"></i>
                </div>
                <div class="p-4">
                    <h2 class="title">Experiencia creada con éxito</h2>
                </div>

            </div>
            <div class="flex flex-row w-full align-items-center justify-content-start">
                <div class="flex flex-column w-full">
                    <div>
                        <h2>Comparte tu experiencia de una de estas formas</h2>
                    </div>
                    <div class="flex flex-row w-full align-items-center justify-content-between option-row">
                        <div>
                            <h4>Opción 1: A través de la URL</h4>
                            <p>Puedes utilizar esta URL en un botón de tu web o compartirlo directamente en redes
                                sociales.
                            </p>
                            <p>
                                <a target="_blank" [href]="eventUrl">{{ eventUrl }}</a>
                            </p>
                        </div>
                        <div class="w-3">
                            <div class="flex flex-row justify-content-center">
                                <button pButton pRipple type="button" icon="pi pi-copy" label="Copiar enlace"
                                    class="p-button-rounded p-button-text" (click)="copyLink(eventUrl)"></button>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row w-full align-items-center justify-content-between option-row">
                        <div>
                            <h4>Opción 2: A través del código QR</h4>
                            <p>Utiliza este código QR en un soporte físico o digital.</p>
                        </div>
                        <div class="w-3">
                            <div class="flex flex-row justify-content-center">
                                <app-event-qr [eventId]="eventId" [showImage]="false"
                                    [filename]="eventName"></app-event-qr>
                            </div>
                        </div>
                    </div>
                    <!--<div class="flex flex-row w-full align-items-center justify-content-between option-row">
                        <div>
                            <h4>Opcion 3: Directamente desde tu web (avanzado)</h4>
                            <p>Vende tu experiencia desde tu web copiando y pegando este código.</p>
                        </div>
                        <div class="w-3">
                            <div class="flex flex-row justify-content-center">
                                <button pButton pRipple type="button" icon="pi pi-code" label="Copiar código"
                                    class="p-button-rounded p-button-text"></button>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-arrow-right" iconPos="right" type="button"
            label="Ver todas las experiencias" class="p-button-text" [routerLink]="['/events']"></button></ng-template>
</p-card>