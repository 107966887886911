<app-generic-list-item [image]="experience?.images?.length>0?experience?.images[0]:''" [title]="experience?.name"
  [description]="experience?.description"
  [additionalInfo]="experience?.displayPrice / 100 | currency : 'EUR' : 'symbol' : '1.2-2'" [sold]="experience?.sold"
  [stock]="experience?.stock">
  <div item-actions>
    <button pButton class="p-button-text p-button-rounded" icon="pi pi-pencil" [disabled]="experience?.sold > 0"
      (click)="editExperience()"></button>
    <button pButton class="p-button-text p-button-rounded" icon="pi pi-trash" [disabled]="experience?.sold > 0"
      (click)="removeExperience()"></button>
    <button *ngIf="experience?.id !== null" pButton icon="pi pi-send" label="Enviar mensaje"
      (click)="createCampaign()"></button>
  </div>
</app-generic-list-item>

<app-show-message-dialog section="supplement" [eventId]="eventId" [referenceId]="experience.id"
  [show]="messageDialogVisible" (hide)="onMessageDialogHide($event)"></app-show-message-dialog>