import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Observable, catchError, concatMap, finalize, from, of, reduce, tap } from 'rxjs';
import { MomentService } from 'src/app/event/service/moment.service';

@Component({
  selector: 'app-event-detail-moments',
  templateUrl: './event-detail-moments.component.html',
  styleUrls: ['./event-detail-moments.component.scss']
})
export class EventDetailMomentsComponent implements OnInit {

  @Input() eventId: string;
  moments: any[] = [];
  uploadedFiles: string[] = [];
  @ViewChild('fileUpload') fileUpload: FileUpload;
  deletingMoments: { [key: string]: boolean } = {};
  hasFiles: boolean = false;
  isUploading: boolean = false;
  uploadProgress: string = '';
  uploadedCount: number = 0;
  totalFilesToUpload: number = 0;

  // Pagination properties
  currentPage: number = 0;
  totalPages: number = 0;
  pageSize: number = 50;
  totalElements: number = 0;

  isDeletingAll: boolean = false;

  constructor(private messageService: MessageService, private momentService: MomentService, private route: ActivatedRoute, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      this.loadMoments();
    });
  }

  loadMoments(): void {
    this.momentService.getMomentsByEventId(this.eventId, this.currentPage, this.pageSize).subscribe({
      next: (response) => {
        this.moments = response.moments;
        this.totalPages = response.totalPages;
        this.totalElements = response.totalElements;
        this.currentPage = response.currentPage;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar los momentos.' });
      }
    });
  }

  onPageChange(event: any): void {
    this.currentPage = Math.floor(event.first / event.rows);
    this.pageSize = event.rows;
    this.loadMoments();
  }

  onUpload(event) {
    const files: File[] = Array.from(event.files);
    this.isUploading = true;
    this.uploadedCount = 0;
    this.totalFilesToUpload = files.length;
    this.updateUploadProgress();
    
    this.uploadFiles(files).pipe(
      finalize(() => {
        this.isUploading = false;
        this.uploadProgress = '';
        this.loadMoments();
        this.fileUpload.clear();
        this.hasFiles = false;
      })
    ).subscribe({
      next: (successCount) => {
        if (successCount > 0) {
          this.messageService.add({ severity: 'success', summary: 'Momentos subidos', detail: `${successCount} de ${this.totalFilesToUpload} momentos se han subido correctamente!` });
        }
        if (successCount < this.totalFilesToUpload) {
          this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: `${this.totalFilesToUpload - successCount} momentos no se pudieron subir.` });
        }
        this.uploadedFiles = this.uploadedFiles.concat(files.map(f => f.name));
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Hubo un error al subir los momentos.' });
      }
    });
  }

  uploadFiles(files: File[]): Observable<number> {
    const batchSize = 1; // Number of files to upload in each batch
    const batches = this.createBatches(files, batchSize);
    
    return from(batches).pipe(
      concatMap((batch) => {
        return this.uploadBatch(batch).pipe(
          tap((response) => {
            this.uploadedCount += response.length;
            this.updateUploadProgress();
          }),
          catchError((error) => {
            console.error('Error uploading batch:', error);
            return of([]); // Return an empty array to continue with the next batch
          })
        );
      }),
      reduce((acc, curr) => acc + curr.length, 0) // Sum up all successfully uploaded files
    );
  }

  createBatches(files: File[], batchSize: number): File[][] {
    const batches = [];
    for (let i = 0; i < files.length; i += batchSize) {
      batches.push(files.slice(i, i + batchSize));
    }
    return batches;
  }

  uploadBatch(batch: File[]): Observable<any[]> {
    return this.momentService.uploadMoments(this.eventId, batch);
  }

  updateUploadProgress(): void {
    this.uploadProgress = `Subiendo ${this.uploadedCount} de ${this.totalFilesToUpload} momentos`;
  }

  deleteMoment(momentId: string): void {

    if (this.deletingMoments[momentId]) {
      return;
    }

    this.deletingMoments[momentId] = true;

    this.momentService.deleteMoment(momentId).subscribe({
      next: () => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Momento eliminado correctamente!' });
        this.loadMoments();
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al eliminar el momento.' });
      },
      complete: () => {
        this.deletingMoments[momentId] = false;
      }
    });
  }

  isDeleting(momentId: string): boolean {
    return this.deletingMoments[momentId] === true;
  }

  onSelect(event) {
    this.hasFiles = true;
  }

  onClear() {
    this.hasFiles = false;
  }

  deleteAllMoments(): void {
    this.confirmationService.confirm({
      message: '¿Estás seguro de que quieres borrar todos los momentos? Esta acción no se puede deshacer.',
      header: 'Confirmar borrado',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isDeletingAll = true;
        this.momentService.deleteAllMomentsByEventId(this.eventId).subscribe({
          next: () => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Todos los momentos han sido eliminados correctamente!' });
            this.loadMoments();
          },
          error: (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al eliminar todos los momentos.' });
          },
          complete: () => {
            this.isDeletingAll = false;
          }
        });
      },
      reject: () => {
        this.confirmationService.close();
      },
      acceptLabel: 'Sí',
      rejectLabel: 'No'
    });
  }

}
