<p-dialog header="Cambiar permisos de acceso" [(visible)]="displayUpdateContentDialog" [modal]="true"
  [style]="{ width: '50vw', height: '80vh' }" (onHide)="onDialogHide()">
  <form [formGroup]="contentForm">

    <div class="field">
      <label for="accessControl">Control de acceso *</label>
      <p-dropdown id="accessControl" [options]="accessControlOptions" formControlName="accessControl"
        placeholder="Selecciona una opción" optionLabel="value" optionValue="id">
        <ng-template let-option pTemplate="item">
          <div class="flex flex-row align-items-center">
            <div class="p-2">
              <i class="{{ option.icon }}"></i>
            </div>
            <div>{{ option.value }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <button pButton pRipple [disabled]="!contentForm.valid" label="Modificar acceso" class="w-full"
      (click)="updateContent()"></button>
  </form>
</p-dialog>