import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-expense-dialog',
  templateUrl: './expense-dialog.component.html',
  styleUrls: ['./expense-dialog.component.scss']
})
export class ExpenseDialogComponent implements OnInit {

  loading: boolean = false;

  @Input() tokenId: string = '';
  @Input() displayAddDialog: boolean = false;
  @Output() hideDialog: EventEmitter<any> = new EventEmitter();
  expenseForm: FormGroup = new FormGroup({
    amount: new FormControl('', [Validators.required, Validators.min(0)])
  });

  constructor(private userService: UserService, private messageService: MessageService) {

  }

  ngOnInit(): void {
  }

  addContent(): void {

    const amount = this.expenseForm.get('amount').value;

    this.userService.addBalance(this.tokenId, amount * 100).subscribe((response) => {
      this.messageService.add({ severity: 'success', summary: 'Saldo añadido', detail: '' });
      this.displayAddDialog = false;
      this.hideDialog.emit(amount);
    });
  }

  onDialogHide(): void {
    this.displayAddDialog = false;
    this.hideDialog.emit(undefined);
  }

}
