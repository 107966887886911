import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UserService } from '../../service/user.service';
import { Observable } from 'rxjs';
import { Purchase, PurchaseState } from '../../model/purchase.interface';
import { LinkUtils } from 'src/app/event/util/link.util';

@Component({
  selector: 'app-purchase-detail',
  templateUrl: './purchase-detail.component.html',
  styleUrls: ['./purchase-detail.component.scss']
})
export class PurchaseDetailComponent implements OnInit {

  purchase$: Observable<Purchase>;

  constructor(private activatedRoute: ActivatedRoute, private userService: UserService) {

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {

      const id = params.get('purchaseId');

      this.purchase$ = this.userService.getUserPurchaseById(id);

    });
  }

  ngOnInit(): void {
  }

  getSeverity(state: PurchaseState): string {

    if (state === PurchaseState.COMPLETE || state === PurchaseState.SUCCEEDED) return 'success';
    if (state === PurchaseState.FAILED) return 'danger';

    return 'info';
  }

  copyLink(text: string): void {

    LinkUtils.copy(text);

  }

}
