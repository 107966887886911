<div class="stepsdemo-content table-container">
  <p-card>
    <ng-template pTemplate="title"> Confirmación </ng-template>
    <ng-template pTemplate="subtitle">
      Revisa los detalles de tu experiencia
    </ng-template>
    <ng-template pTemplate="content">
      <div class="card">
        <div class="flex card-container indigo-container">
          <div class="flex-1 flex-column p-2">
            <h5>Nombre</h5>
            <p>{{ data.description.name }}</p>
            <h5>Descripción</h5>
            <div [innerHTML]="data.description.description"></div>
            <h5>Celebración</h5>
            <p>Lugar: {{ data.venue.location.formattedAddress }}</p>
            <p>
              Comienzo:
              {{ getLocalDate(data.venue.startDate) }}
            </p>
            <p>
              Fin:
              {{ getLocalDate(data.venue.endDate) }}
            </p>
            <p>
              Apertura de puertas:
              {{ getLocalTime(data.venue.openingTime) }}
            </p>
          </div>
          <div class="flex-1 flex-column p-2">
            <h5>Entradas</h5>
            <p *ngFor="let ticket of data.tickets">
              {{ ticket.name }}: {{ ticket.ticketsNumber }} a
              {{
              ticket.displayPrice / 100
              | currency : "EUR" : "symbol" : "1.2-2"
              }}
            </p>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="grid grid-nogutter justify-content-between">
        <div class="flex-none">
          <button pButton class="p-button-text" *ngIf="!eventId" label="Atrás" (click)="prevPage()"
            icon="pi pi-angle-left"></button>
        </div>
        <div class="flex-grow-1"></div>
        <div>
          <div *ngIf="eventId === undefined">
            <button pButton class="p-button-success" *ngIf="loading === false; else endBlocked"
              label="Crear experiencia" (click)="end()" icon="pi pi-check" iconPos="right"></button>
            <ng-template #endBlocked>
              <button pButton class="p-button-success" disabled="true" icon="pi pi-spin pi-spinner" iconPos="right"
                label="Crear experiencia"></button>
            </ng-template>
          </div>
          <div *ngIf="eventId !== undefined">
            <button pButton class="p-button" *ngIf="loading === false; else updateBlocked" label="Guardar cambios"
              (click)="update()"></button>
            <ng-template #updateBlocked>
              <button pButton class="p-button" disabled="true" icon="pi pi-spin pi-spinner" iconPos="right"
                label="Guardar cambios"></button>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </p-card>
</div>