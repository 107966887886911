import { Component, Input, OnInit } from '@angular/core';
import { EventTickets } from 'src/app/event/model/event-detail.interface';
import { Ticket } from 'src/app/event/model/ticket.interface';
import { TicketDetail } from '../../model/ticket-detail.interface';
import { LinkUtils } from 'src/app/event/util/link.util';

@Component({
  selector: 'app-purchase-detail-ticket-item',
  templateUrl: './purchase-detail-ticket-item.component.html',
  styleUrls: ['./purchase-detail-ticket-item.component.scss']
})
export class PurchaseDetailTicketItemComponent implements OnInit {

  @Input() ticket: TicketDetail;
  @Input() details: EventTickets[];

  constructor() { }

  ngOnInit(): void {
  }

  getProductAttribute(attributeName: string): string {

    if (!this.details || this.details.length === 0) {
      return '';
    }

    try {
      return this.details.filter((item: EventTickets) => item.id === this.ticket.typeId)[0][attributeName];
    } catch (e: any) {
      return 'Producto desconocido';
    }

  }

  copyLink(url: string) {

    LinkUtils.copy(url);
  }

}
