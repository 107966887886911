import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { EventService } from 'src/app/event/service/event.service';
import { ListService } from 'src/app/list/service/list.service';
import { getUniversalSectionSuffixes } from 'src/app/shared/util/MessageUtil';

@Component({
  selector: 'app-show-message-dialog',
  templateUrl: './show-message-dialog.component.html',
  styleUrls: ['./show-message-dialog.component.scss']
})
export class ShowMessageDialogComponent implements OnInit {

  @Input() eventId: string;
  @Input() referenceId: string;
  @Input() section: string;
  @Input() show: boolean = false;
  @Output() hide: EventEmitter<boolean> = new EventEmitter();

  message: string = '';
  displayRedirectionDialog: boolean = false;
  services: string[] = [];
  serviceOptions: SelectItem[] = [];
  customRedirection: boolean = false;
  selectedService: string = '/messages';
  loading: boolean = false;
  sending: boolean = false;

  constructor(private listService: ListService, private eventService: EventService) { }

  ngOnInit(): void {
    this.serviceOptions = getUniversalSectionSuffixes();
  }

  onDialogHide(): void {
    this.hide.emit();
  }

  sendMessage(): void {
  
    if (this.sending || !this.message || this.message.trim().length === 0 || this.loading) {
      return;
    }
  
    this.loading = true;

    switch (this.section) {
      case "ticketType":
        this.sendMessageToTicketType();
        break;
      case "complement":
        this.sendMessageToComplement();
        break;
      case "supplement":
        this.sendMessageToSupplement();
        break;
      case "list":
        this.sendMessageToList();
        break;
      default:
        break;
    }

  }

  sendMessageToList() {

    this.listService.sendPush(this.referenceId, this.message, this.selectedService).subscribe((response: any) => {
      this.hide.emit(true);
      this.loading = false;
      this.sending = false;
    }, () => {
      this.hide.emit(false);
      this.loading = false;
      this.sending = false;
    });
  }
  sendMessageToComplement() {
    throw new Error('Method not implemented.');
  }

  sendMessageToSupplement() {
    this.eventService.sendMessage("supplement", this.eventId, this.referenceId, this.message, this.selectedService).subscribe((response: any) => {
      this.hide.emit(true);
      this.loading = false;
      this.sending = false;
    }, () => {
      this.hide.emit(false);
      this.loading = false;
      this.sending = false;
    });
  }

  sendMessageToTicketType() {
    this.eventService.sendMessage("ticketType", this.eventId, this.referenceId, this.message, this.selectedService).subscribe((response: any) => {
      this.hide.emit(true);
      this.loading = false;
      this.sending = false;
    }, () => {
      this.hide.emit(false);
      this.loading = false;
      this.sending = false;
    });
  }

  showRedirectionDialog(): void {
    if (!this.message || this.message.trim().length === 0) {
      return;  // Do nothing if the message is not valid
    }
    this.displayRedirectionDialog = true;
  }
}
