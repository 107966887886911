import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { FormPart } from '../../model/form-part.interface';

@Component({
  selector: 'app-step-confirmation',
  templateUrl: './step-confirmation.component.html',
  styleUrls: ['./step-confirmation.component.scss']
})
export class StepConfirmationComponent implements OnInit, OnChanges {

  ticketInformation: any;

  @Output() next: EventEmitter<FormPart> = new EventEmitter()
  @Output() complete: EventEmitter<boolean> = new EventEmitter()
  @Input() data: any;
  @Input() eventId: string = undefined;
  @Input() loading: boolean;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.ticketInformation = changes['data'].currentValue;
    }
  }

  end() {
    this.complete.emit(true)
  }

  prevPage() {
    this.next.emit({
      next: -1,
      name: undefined,
      data: undefined
    });
  }

  getLocalDate(date) {
    if (!date) {
      return '';
    }

    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
  }

  getLocalTime(time) {
    if (!time) {
      return '';
    }

    return DateTime.fromISO(time).toLocaleString(DateTime.TIME_24_SIMPLE);
  }

}
