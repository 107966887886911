import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Purchase, PurchaseState } from '../../model/purchase.interface';

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.scss']
})
export class UserHistoryComponent implements OnInit {

  @Input() purchases: Observable<Purchase>;

  constructor() { }

  ngOnInit(): void {
  }

}
