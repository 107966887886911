<p-card *ngIf="purchases | async; let data">
  <ng-template pTemplate="title"
    >Histórico de compras ({{ data && data.length }})</ng-template
  >
  <ng-template pTemplate="subtitle"
    >Estas son las compras realizadas por el usuario</ng-template
  >
  <div>
    <p-timeline [value]="data" align="left">
      <ng-template pTemplate="content" let-purchase>
        <app-purchase-history-item
          [purchase]="purchase"
        ></app-purchase-history-item>
      </ng-template>
    </p-timeline>
  </div>
</p-card>
