import { Component, Input, OnInit } from '@angular/core';
import { PurchaseProduct } from '../../model/purchase-product.interface';
import { MerchService } from 'src/app/merch/service/merch.service';
import { Merch } from 'src/app/merch/model/merch';

@Component({
  selector: 'app-purchase-detail-products',
  templateUrl: './purchase-detail-products.component.html',
  styleUrls: ['./purchase-detail-products.component.scss']
})
export class PurchaseDetailProductsComponent implements OnInit {

  @Input() products: PurchaseProduct[];

  merch: Merch[];

  constructor(private merchService: MerchService) {
  }

  ngOnInit(): void {
    this.merchService.getMerch().subscribe((response: Merch[]) => this.merch = response);
  }

}
