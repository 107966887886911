<p-toast position="top-right"></p-toast>
<div class="card-container justify-content-start border-round p-3">
  <div class="flex flex-row align-items-center justify-content-between mb-3">
    <div class="flex-grow-1">
      <h2>{{ token?.id }}</h2>
    </div>
    <div class="flex flex-row align-items-center">
      <div>
        <button class="p-button-text mr-3" pButton pRipple icon="pi pi-search" pTooltip="Ver entrada"
          tooltipPosition="bottom" type="button" (click)="showTokenDialog()"></button>
      </div>
      <div>
        <button class="p-button-text mr-3" pButton pRipple icon="pi pi-sync" pTooltip="Regenerar entrada"
          tooltipPosition="bottom" type="button" (click)="generateTicket()"></button>
      </div>
      <div>
        <button class="p-button-text mr-3" pButton pRipple icon="pi pi-send" pTooltip="Reenviar entrada"
          tooltipPosition="bottom" type="button" (click)="resendEmail()"></button>
      </div>
      <div>
        <button class="p-button-text mr-3" pButton pRipple icon="pi pi-users" pTooltip="Reasignar entrada"
          tooltipPosition="bottom" type="button" (click)="showAssignDialog()"></button>
      </div>
    </div>
  </div>

  <div class="flex flex-row">
    <!-- The secondary menu -->
    <div>
      <p-menu [model]="items"></p-menu>
    </div>
    <!-- The content -->
    <div class="content ml-3">
      <!-- Section summary -->
      <div *ngIf="!section || section === 'summary'">
        <p-card>
          <ng-template pTemplate="title">Detalle de la entrada</ng-template>
          <ng-template pTemplate="subtitle">Estos son los detalles de la entrada que posee el fan</ng-template>
          <div class="flex flex-column" *ngIf="token">
            <div class="flex flex-row align-items-center">
              <h4>Evento:&nbsp;</h4>
              <a [routerLink]="['/events', token.eventId]">{{ token.eventId }}</a>
            </div>
            <div class="flex flex-row align-items-center">
              <h4>Identificador único:&nbsp;</h4>
              <p>{{ token.id }}</p>
            </div>
            <div class="flex flex-row align-items-center">
              <h4>Fecha de asignación:&nbsp;</h4>
              <p>{{ token.purchasedAt | date : "dd/MM/yyyy HH:mm" }}</p>
            </div>
            <div class="flex flex-row align-items-center">
              <h4>Fecha de última generación de la entrada:&nbsp;</h4>
              <p>
                {{ token.tokenLastGeneratedAt | date : "dd/MM/yyyy HH:mm" }}
              </p>
            </div>
            <div class="flex flex-row align-items-center">
              <h4>Estado de validación:&nbsp;</h4>
              <p *ngIf="token.validated; else noValidation">
                <p-tag severity="sucesss" value="VALIDADO"></p-tag>
              </p>
              <ng-template #noValidation>
                <p-tag severity="danger" value="NO VALIDADO"></p-tag>
              </ng-template>
            </div>
            <div class="flex flex-row align-items-center">
              <h4>Fecha de validación:&nbsp;</h4>
              <p *ngIf="token.validated; else noValidationDate">
                {{ token.validatedAt | date : "dd/MM/yyyy HH:mm" }}
              </p>
              <ng-template #noValidationDate>
                <p>No disponible</p>
              </ng-template>
            </div>
          </div>
        </p-card>
      </div>
      <div *ngIf="section==='assignation'">
        <p-card>
          <ng-template pTemplate="title">Datos de asignación</ng-template>
          <ng-template pTemplate="subtitle">Detalles de asignación de la experiencia</ng-template>
          <div class="flex flex-column" *ngIf="assignationInfo">
            <div class="flex flex-row align-items-center">
              <h4>Nombre:&nbsp;</h4>
              <p>{{ assignationInfo.ownerName }}</p>
            </div>
            <div class="flex flex-row align-items-center" *ngIf="assignationInfo.surname">
              <h4>Apellidos:&nbsp;</h4>
              <p>{{ assignationInfo.surname }}</p>
            </div>
            <div class="flex flex-row align-items-center" *ngIf="assignationInfo.genre">
              <h4>Género:&nbsp;</h4>
              <p>{{ assignationInfo.genre }}</p>
            </div>
            <div class="flex flex-row align-items-center" *ngIf="assignationInfo.fiscalId">
              <h4>Identificación fiscal:&nbsp;</h4>
              <p>{{ assignationInfo.fiscalId }}</p>
            </div>
            <div class="flex flex-row align-items-center" *ngIf="assignationInfo.phone">
              <h4>Teléfono:&nbsp;</h4>
              <p>{{ assignationInfo.phone }}</p>
            </div>
            <div class="flex flex-row align-items-center" *ngIf="assignationInfo.birth">
              <h4>Fecha de nacimiento:&nbsp;</h4>
              <p>{{ assignationInfo.birth }}</p>
            </div>
            <div *ngIf="assignationInfo.additional">
              <div *ngFor="let item of assignationInfo.additional | keyvalue">
                <h4>{{ item.key }}:&nbsp;</h4>
                <p *ngIf="isBoolean(item.value)">
                  {{ item.value ? "Si" : "No" }}
                </p>
                <p *ngIf="!isBoolean(item.value)">{{ item.value }}</p>
              </div>
            </div>
          </div>
        </p-card>
      </div>
      <!-- Experiences data -->
      <div *ngIf="section === 'experiences'">
        <p-card>
          <ng-template pTemplate="title">Personalización</ng-template>
          <ng-template pTemplate="subtitle">Cómo el fan ha personalizado su experiencia</ng-template>
          <p-table #experiencesTable [value]="experiences" styleClass="p-datatable-sm"
            [globalFilterFields]="['name', 'description', 'quantity']">
            <ng-template pTemplate="caption">
              <div class="flex">
                <button class="p-button" icon="pi pi-plus" label="Asignar personalización" pButton pRipple type="button"
                  (click)="displayAddCustomizationDialog = true"></button>
                <button pButton label="Borrar filtros" class="p-button-outlined ml-3" icon="pi pi-filter-slash"
                  (click)="clear(experiencesTable)"></button>
                <span class="p-input-icon-left ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="
                      experiencesTable.filterGlobal(
                        $event.target.value,
                        'contains'
                      )
                    " placeholder="Buscar" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 20%" pSortableColumn="name">
                  Nombre
                  <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                  <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th style="width: 30%" pSortableColumn="quantity">
                  Cantidad
                  <p-columnFilter type="text" field="quantity" display="menu"></p-columnFilter><p-sortIcon
                    field="quantity"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template let-experience pTemplate="body">
              <tr>
                <td>{{ experience.name }}</td>
                <td>{{ experience.quantity }}</td>
              </tr>
            </ng-template>
          </p-table>

          <ng-template #noExperiences>
            <p>No se ha personalizado la experiencia</p>
          </ng-template>
        </p-card>
      </div>
      <!-- Products data -->
      <div *ngIf="section === 'products'">
        <p-card>
          <ng-template pTemplate="title">Productos</ng-template>
          <ng-template pTemplate="subtitle">Explora los productos comprados por el usuario</ng-template>
          <app-sale-delivery-table [sales]="sales" [tokenId]="token?.id"></app-sale-delivery-table>
        </p-card>
      </div>
      <!-- Section tier -->
      <div *ngIf="section === 'wallet'" class="flex flex-column">
        <p-card>
          <ng-template pTemplate="title">Saldo disponible</ng-template>
          <h3> {{token?.walletBalance / 100 | currency : "EUR" : "symbol" : "1.2-2"}}</h3>
        </p-card>
        <p-card class="mt-3">
          <ng-template pTemplate="title">Movimientos</ng-template>
          <ng-template pTemplate="subtitle">Explora los movimientos registrados para este fan</ng-template>
          <p-table #expensesTable [value]="movements" styleClass="p-datatable-sm" [globalFilterFields]="[
              'createdAt',
              'paymentMethod',
              'movementType',
              'amount'
            ]">
            <ng-template pTemplate="caption">
              <div class="flex">
                <button class="p-button" icon="pi pi-plus" label="Añadir saldo" pButton pRipple type="button"
                  (click)="displayAddDialog = true"></button>
                <button pButton label="Borrar filtros" class="p-button-outlined ml-3" icon="pi pi-filter-slash"
                  (click)="clear(expensesTable)"></button>
                <span class="p-input-icon-left ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="
                      expensesTable.filterGlobal(
                        $event.target.value,
                        'contains'
                      )
                    " placeholder="Buscar" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 20%" pSortableColumn="createdAt">
                  Fecha
                  <p-columnFilter type="text" field="createdAt" display="menu"></p-columnFilter>
                  <p-sortIcon field="createdAt"></p-sortIcon>
                </th>
                <th style="width: 30%" pSortableColumn="paymentMethod">
                  Método de pago
                  <p-columnFilter type="text" field="paymentMethod" display="menu"></p-columnFilter><p-sortIcon
                    field="paymentMethod"></p-sortIcon>
                </th>
                <th style="width: 30%" pSortableColumn="movementType">
                  Movimiento
                  <p-columnFilter type="text" field="movementType" display="menu"></p-columnFilter><p-sortIcon
                    field="movementType"></p-sortIcon>
                </th>
                <th style="width: 30%" pSortableColumn="amount">
                  Importe (€)
                  <p-columnFilter type="text" field="amount" display="menu"></p-columnFilter><p-sortIcon
                    field="amount"></p-sortIcon>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template let-expense pTemplate="body">
              <tr>
                <td>{{ expense.createdAt | date : "dd/MM/yy HH:mm" }}</td>
                <td>{{ expense.paymentMethod }}</td>
                <td>{{ expense.movementType }}</td>
                <td>
                  {{
                  expense.amount / 100
                  | currency : "EUR" : "symbol" : "1.2-2"
                  }}
                </td>
                <td>
                  <div class="flex justify-content-center" *ngIf="expense.movementType === 'SEND'">
                    <button *ngIf="!expense.refunded; else refunded" pButton class="p-button-text" type="button"
                      icon="pi pi-undo" pTooltip="Reembolsar" tooltipPosition="bottom"
                      (click)="refund(expense.purchaseId)"></button>
                    <ng-template #refunded>
                      <span class="danger">Reembolsado</span>
                    </ng-template>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-card>
      </div>
      <!-- Section tokens -->
      <div *ngIf="section === 'messages'">
        <p-card>
          <ng-template pTemplate="title">Mensajes</ng-template>
          <ng-template pTemplate="subtitle">Comunícate directamente con tu fan</ng-template>
          <ng-template pTemplate="content">
            <div class="flex flex-row">
              <div class="flex-grow-1 mr-3">
                <p-editor placeholder="Envíale a tu fan un mensaje personalizado" [(ngModel)]="newMessage"
                  [style]="{ height: '100px' }">
                  <ng-template pTemplate="header">
                    <span class="ql-formats">
                      <select class="ql-header">
                        <option value="1"></option>
                        <option value="2"></option>
                        <option value="3"></option>
                        <option value="4"></option>
                        <option value="5"></option>
                        <option value="6"></option>
                        <option value="false" selected></option>
                      </select>
                      <button type="button" class="ql-bold" aria-label="Bold"></button>
                      <button type="button" class="ql-italic" aria-label="Italic"></button>
                      <button type="button" class="ql-underline" aria-label="Underline"></button>
                      <button type="button" class="ql-strike" aria-label="Strike"></button>
                      <button type="button" class="ql-list" value="ordered"></button>
                      <button type="button" class="ql-list" value="bullet"></button>
                    </span>
                  </ng-template>
                </p-editor>
              </div>
              <div>
                <p-button icon="pi pi-send" (click)="showRedirectionDialog()" [disabled]="!newMessage || newMessage?.trim().length === 0 || sending" [loading]="loading"></p-button>
              </div>
            </div>
            <p-dialog header="Confirmar envío de mensaje" [(visible)]="displayRedirectionDialog" [style]="{width: '50vw', height: '30vw'}">
              <div class="content">
                <div>
                  <p-checkbox id="customizeRedirection" [binary]="true" styleClass="mr-2" label="Quiero personalizar la sección destino del usuario cuando pulsa en la notificación." [(ngModel)]="customRedirection"></p-checkbox>
                  <div *ngIf="customRedirection" class="mt-3">
                    <p-dropdown class="mr-3" [options]="serviceOptions" [(ngModel)]="selectedService" placeholder="Selecciona una sección" ></p-dropdown>
                  </div>
                </div>
                <p-button styleClass="mt-3" label="Enviar" (click)="sendMessage()" [disabled]="!newMessage || newMessage?.trim().length === 0 || sending" [loading]="loading"></p-button>
              </div>
            </p-dialog>
            <div class="flex flex-column">
              <p-card class="message mt-3" [subheader]="message.publishedAt | date : 'full'"
                *ngFor="let message of messages">
                <div class="w-full flex flex-row">
                  <div class="flex-grow-1">
                    <div [innerHTML]="message.content"></div>
                    
                  </div>
                  <div>
                    <p-button styleClass="p-button-text" icon="pi pi-trash"
                      (click)="deleteMessage(message.id)"></p-button>
                  </div>
                </div>
              </p-card>
            </div>
          </ng-template>
        </p-card>
      </div>
    </div>
  </div>
</div>

<app-expense-dialog [tokenId]="token?.id" [displayAddDialog]="displayAddDialog"
  (hideDialog)="hideDialog()"></app-expense-dialog>

<app-customization-dialog [tokenId]="token?.id" [tokenExperiencesIds]="tokenExperiencesIds"
  [displayDialog]="displayAddCustomizationDialog" (hideDialog)="hideCustomizationDialog()"></app-customization-dialog>

<!-- Token Image Modal -->
<div *ngIf="showToken" class="token-modal-overlay">
  <div class="token-modal">
    <img *ngIf="!showErrorQR" [src]="tokenUrls['720']" [srcset]="
        tokenUrls['240'] +
        ' 240w, ' +
        tokenUrls['320'] +
        ' 320w, ' +
        tokenUrls['480'] +
        ' 480w, ' +
        tokenUrls['640'] +
        ' 640w, ' +
        tokenUrls['720'] +
        ' 720w, '
      " sizes="(max-width: 240px) 200px,
                (max-width: 320px) 280px,
                (max-width: 480px) 440px,
                (max-width: 640px) 600px,
                720px" alt="Token Image" />

    <!-- Display QR code if there is an error -->
    <qrcode *ngIf="showErrorQR" [qrdata]="fallbackQR" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>

    <button class="close-button" (click)="showToken = false">Cerrar</button>
  </div>
</div>

<p-dialog header="Header" [(visible)]="displayAssignDialog" [modal]="true" [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    <h2>Reasignar entrada</h2>
  </ng-template>
  <div class="mb-3">
    <input type="email" placeholder="Escribe el nuevo email" pInputText [(ngModel)]="reassignMail" />
  </div>
  <div class="flex flex-row align-items-center">
    <p-checkbox [(ngModel)]="reassignMailCheck" value="New York" inputId="ny"></p-checkbox>
    <label class="ml-2" for="ny">Confirmo que quiero reasignar esta entrada</label>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="Reasignar" class="p-button-danger"
      [disabled]="reassignMail && reassignMailCheck" (click)="reassignTicket()"></p-button>
  </ng-template>
</p-dialog>