import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { FormPart } from '../../model/form-part.interface';
import { EventService } from '../../service/event.service';
import { DateUtil } from 'src/app/shared/util/DateUtil';

@Component({
    selector: 'app-step-venue',
    templateUrl: './step-venue.component.html',
    styleUrls: ['./step-venue.component.scss']
})
export class StepVenueComponent implements OnInit, OnChanges {

    @Input() eventId: string;

    startDate: Date;
    endDate: Date;
    openingTime: Date;
    hiddenLocation: boolean = false;
    revealLocationAt: Date;
    searchFieldModel: any;

    latitude: number;
    longitude: number;

    minStartDate: Date = new Date();

    venueForm = new FormGroup({
        location: new FormGroup({
            placeId: new FormControl(''),
            name: new FormControl(''),
            url: new FormControl(''),
            formattedAddress: new FormControl('', [Validators.required]),
            addressComponents: new FormControl([]),
            formattedPhoneNumber: new FormControl(''),
            types: new FormControl([]),
            priceLevel: new FormControl(0),
            point: new FormControl({})
        }),
        startDate: new FormControl('', [Validators.required]),
        endDate: new FormControl('', [Validators.required]),
        openingTime: new FormControl('', []),
        zoneName: new FormControl('', [Validators.required]),
        hiddenLocation: new FormControl(false, []),
        revealLocationAt: new FormControl('', [])
    });

    @Output() next: EventEmitter<FormPart> = new EventEmitter();
    @Output() save: EventEmitter<FormPart> = new EventEmitter();
    @Input() data: any;
    @ViewChild('search')
    searchField: ElementRef;
    map: google.maps.Map;
    marker: google.maps.Marker;

    options: any;

    overlays: any[];


    constructor(@Inject(DOCUMENT) private document: Document,
        private renderer2: Renderer2, private eventService: EventService, private messageService: MessageService, private ref: ChangeDetectorRef) { }

    async ngOnInit(): Promise<void> {
        //const url = `https://maps.googleapis.com/maps/api/js?key=${environment.maps.apiKey}&libraries=places`;
        //await this.loadScript(url).then(() => this.initMap());
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

        const url = `https://maps.googleapis.com/maps/api/js?key=${environment.maps.apiKey}&libraries=places`;
        await this.loadScript(url).then(() => this.initMap());

        if (changes['data'] && changes['data'].currentValue) {

            this.venueForm.patchValue(changes['data'].currentValue);

            this.startDate = new Date(changes['data'].currentValue.startDate);

            this.hiddenLocation = changes['data'].currentValue.hiddenLocation;

            if (changes['data'].currentValue.endDate) {
                this.endDate = new Date(changes['data'].currentValue.endDate);
            }
            if (changes['data'].currentValue.openingTime) {
                this.openingTime = DateUtil.fromUTC(changes['data'].currentValue.openingTime);
            }

            if (changes['data'].currentValue.revealLocationAt) {
                this.revealLocationAt = DateUtil.fromUTC(changes['data'].currentValue.revealLocationAt);
            }

            const formattedAddress = changes['data'].currentValue.location.formattedAddress;
            const name = changes['data'].currentValue.location.name;

            if (name) {
                this.searchFieldModel = name + ', '
            } else {
                this.searchFieldModel = ''
            }

            this.searchFieldModel += formattedAddress;

            if (changes['data'].currentValue?.location?.point) {

                if (this.map) {
                    this.map.setCenter({ lat: changes['data'].currentValue?.location?.point.y, lng: changes['data'].currentValue?.location?.point.x });
                    this.map.setZoom(17);
                    this.marker.setPosition({ lat: changes['data'].currentValue?.location?.point.y, lng: changes['data'].currentValue?.location?.point.x });
                    this.marker.setVisible(true);
                }
            }

        }
    }

    nextPage(): void {

        this.updateReactiveForm();

        this.next.emit({
            next: 1,
            name: 'venue',
            data: this.venueForm
        });
    }

    prevPage(): void {

        this.updateReactiveForm();

        this.next.emit({
            next: -1,
            name: 'venue',
            data: this.venueForm
        });
    }

    updateReactiveForm(): void {

        this.venueForm.markAsDirty();

        if (this.startDate && (!this.endDate || this.endDate <= this.startDate)) {
            const defaultEndDate = new Date(this.startDate.getTime() + 2 * 60 * 60 * 1000);
            this.endDate = defaultEndDate;
        }     

        this.venueForm.patchValue({
            startDate: DateTime.fromISO(this.startDate?.toISOString()).toISO(),
            endDate: DateTime.fromISO(this.endDate?.toISOString()).toISO(),
            openingTime: DateTime.fromISO(this.openingTime?.toISOString()).toISO(),
            zoneName: DateTime.fromISO(this.startDate.toISOString()).zoneName,
            revealLocationAt: DateTime.fromISO(this.revealLocationAt?.toISOString()).toISO(),
            hiddenLocation: this.hiddenLocation
        });

    }

    updateEvent(): void {

        const value = {
            venue: this.venueForm.value
        };

        this.eventService.updateEvent(this.eventId, value, 'venue').subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Guardado con éxito', detail: '' });
            this.venueForm.markAsPristine();
        }, () => this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Revisa los datos y vuelve a intentarlo' }));
    }

    saveDraft(): void {
        this.save.emit({
            next: 0,
            name: 'description',
            data: this.venueForm
        });
    }

    private loadScript(url) {
        return new Promise((resolve, reject) => {
            if (!document.getElementById('google-maps-script')) {
                const script = this.renderer2.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                script.text = ``;
                script.id = 'google-maps-script';
                script.onload = resolve;
                script.onerror = reject;
                this.renderer2.appendChild(this.document.head, script);
            } else {
                resolve(undefined);
            }
        });
    }

    private initMap(): void {

        this.map = new google.maps.Map(document.getElementById('map'), {
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            center: { lat: 43.3648640123723, lng: -8.411139227544341 },
            zoom: 17,
        });

        const map = this.map;

        const searchBox = new google.maps.places.Autocomplete(this.searchField.nativeElement, {
            componentRestrictions: { country: ['ES', 'NL', 'PT'] },
        });

        this.marker = new google.maps.Marker({
            map,
            anchorPoint: new google.maps.Point(0, -29),
        });

        searchBox.addListener('place_changed', () => {

            this.marker.setVisible(false);

            const place = searchBox.getPlace();

            if (!place.geometry || !place.geometry.location) {

                // TODO mostrar pop-up para que confirme localización desconocida

                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                console.log("No details available for input: '" + place.name + "'");
                this.venueForm.patchValue({
                    location: {
                        name: place.name,
                        formattedAddress: place.name,
                        addressComponents: []
                    }
                });
                this.venueForm.markAsDirty();
                return;
            } else {

                //let geocoder = new google.maps.Geocoder();

                this.venueForm.patchValue({
                    location: {
                        placeId: place.place_id,
                        name: place.name,
                        url: place.url,
                        formattedAddress: place.formatted_address,
                        addressComponents: place.address_components,
                        formattedPhoneNumber: place.formatted_phone_number,
                        types: place.types,
                        priceLevel: place.price_level,
                        point: {
                            type: "Point",
                            coordinates: [place.geometry.location.lng(), place.geometry.location.lat()]
                        }
                    }
                });
                this.venueForm.markAsDirty();

                // If the place has a geometry, then present it on a map.
                if (place.geometry.viewport) {
                    this.map.fitBounds(place.geometry.viewport);
                } else {
                    this.map.setCenter(place.geometry.location);
                    this.map.setZoom(17); // Why 17? Because it looks good.
                }

                this.marker.setPosition(place.geometry.location);
                this.marker.setVisible(true);

                let address = "";

                if (place.address_components) {
                    address = [
                        (place.address_components[0] &&
                            place.address_components[0].short_name) ||
                        "",
                        (place.address_components[1] &&
                            place.address_components[1].short_name) ||
                        "",
                        (place.address_components[2] &&
                            place.address_components[2].short_name) ||
                        "",
                    ].join(" ");
                }
            }

            this.ref.detectChanges();
        });
    }

}