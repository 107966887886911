import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MomentService {

  baseUrl: string = environment.apiUrl

  constructor(private http: HttpClient) { }

  uploadMoments(eventId: string, files: File[]): Observable<any> {
    const url = `${this.baseUrl}/moments/${eventId}/upload`;
    const formData: FormData = new FormData();
    files.forEach(file => {
      formData.append('files', file, file.name);
    });

    return this.http.post(url, formData);
  }

  getMomentsByEventId(eventId: string, page: number = 0, size: number = 50): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());

    return this.http.get(`${this.baseUrl}/moments/event/${eventId}`, { params }).pipe(
      map((response: any) => ({
        moments: response.content.map(moment => ({
          ...moment,
          thumbnailUrl: this.getThumbnailUrl(moment),
          imageUrl: moment.imageKey
        })),
        totalPages: response.totalPages,
        totalElements: response.totalElements,
        currentPage: response.number
      }))
    );
  }

  deleteMoment(momentId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/moments/${momentId}`);
  }

  deleteAllMomentsByEventId(eventId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/moments/event/${eventId}`);
  }

  private getThumbnailUrl(moment: any): string {
    const smallVersion = moment.imageVersions?.find(v => v.name === 'small');
    return smallVersion ? smallVersion.key : moment.imageKey;
  }

}
