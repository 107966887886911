import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventSponsor } from '../../model/event-detail.interface';
import { EventService } from '../../service/event.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-sponsor-item',
  templateUrl: './sponsor-item.component.html',
  styleUrls: ['./sponsor-item.component.scss']
})
export class SponsorItemComponent implements OnInit {

  @Input() eventId: string;
  @Input() sponsor: EventSponsor;
  @Output() onRemove: EventEmitter<string> = new EventEmitter();

  constructor(private eventService: EventService, private confirmationService: ConfirmationService, private messageService: MessageService) {

  }

  ngOnInit(): void {
  }

  remove() {
    this.confirmationService.confirm({
      message: '¿Estás seguro de que quieres eliminar el sponsor?',
      accept: () => {
        this.eventService.deleteSponsor(this.eventId, this.sponsor.id).subscribe(() => {
          this.messageService.add({ severity: 'success', summary: 'Sponsor borrado correctamente', detail: '' });
          this.onRemove.emit(this.sponsor.id);
        }, () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Ha ocurrido un error',
            detail: 'Vuelve a intentarlo en unos minutos'
          });
        });
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }

}
