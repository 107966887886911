import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Coupon, RestrictionOption } from 'src/app/event/model/coupon.interface';

@Component({
  selector: 'app-add-coupon-dialog',
  templateUrl: './add-coupon-dialog.component.html',
  styleUrls: ['./add-coupon-dialog.component.scss']
})
export class AddCouponDialogComponent implements OnInit, OnChanges {
  @Input() displayDialog: boolean = false;
  @Input() couponToEdit: Coupon | null = null;
  @Input() ticketTypes: RestrictionOption[] = [];
  @Input() complements: RestrictionOption[] = [];
  @Input() experiences: RestrictionOption[] = [];
  @Input() existingCoupons: Coupon[] = [];
  @Output() hideDialog = new EventEmitter<void>();
  @Output() saveCoupon = new EventEmitter<Coupon>();

  couponForm: FormGroup;
  isEditMode: boolean = false;

  discountTypes = [
    { label: 'Porcentaje', value: 'percentage' },
    { label: 'Cantidad fija (€)', value: 'fixed' }
  ];

  restrictionTypes = [
    { label: 'Tickets', value: 'tickets' },
    { label: 'Complementos', value: 'complements' },
    { label: 'Experiencias', value: 'experiences' }
  ];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['couponToEdit'] && this.couponForm) {
      this.setFormValues(changes['couponToEdit'].currentValue);
    }
  }

  initForm(): void {
    this.couponForm = this.fb.group({
      name: [''],
      code: [this.generateCouponCode(), [Validators.required, this.couponCodeValidator(), this.duplicateCouponCodeValidator.bind(this)]],
      hasUsesLimit: [false],
      usesLimit: [{ value: null, disabled: true }, [Validators.required, Validators.min(1)]],
      discountType: [{ value: 'percentage', disabled: false }, Validators.required],
      discountValue: [null, [Validators.required, Validators.min(0), this.discountValueValidator()]],
      hasRestrictions: [false],
      restrictions: [[]],
      restrictedTicketTypes: [[]],
      restrictedComplements: [[]],
      restrictedExperiences: [[]]
    }, { validators: this.restrictionsValidator });

    this.couponForm.get('hasUsesLimit').valueChanges.subscribe(hasLimit => {
      const usesLimitControl = this.couponForm.get('usesLimit');
      if (hasLimit) {
        usesLimitControl.enable();
      } else {
        usesLimitControl.disable();
        usesLimitControl.setValue(null);
      }
    });

    this.couponForm.get('hasRestrictions').valueChanges.subscribe(hasRestrictions => {
      if (hasRestrictions) {
        this.couponForm.get('restrictions').enable();
      } else {
        this.couponForm.get('restrictions').disable();
        this.couponForm.get('restrictions').setValue([]);
        this.updateValidators([]);
      }
    });

    this.couponForm.get('restrictions').valueChanges.subscribe(value => {
      this.updateValidators(value);
    });

    if (this.couponToEdit) {
      this.setFormValues(this.couponToEdit);
    }
  }

  couponCodeValidator(): ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null; // Let required validator handle empty values
      }
      const valid = /^[A-Za-z0-9]{6,10}$/.test(value);
      return valid ? null : { invalidCouponCode: true };
    };
  }

  restrictionsValidator(form: FormGroup): ValidationErrors | null {
    const hasRestrictions = form.get('hasRestrictions').value;
    const restrictions = form.get('restrictions').value;
    
    if (hasRestrictions && (!restrictions || restrictions.length === 0)) {
      return { restrictionsRequired: true };
    }
    
    return null;
  }

  setFormValues(coupon: Coupon): void {
    if (coupon) {
      this.isEditMode = true;
      const hasUsesLimit = coupon.usesLimit !== null && coupon.usesLimit > 0;
      this.couponForm.patchValue({
        name: coupon.name,
        code: coupon.code,
        hasUsesLimit: hasUsesLimit,
        usesLimit: hasUsesLimit ? coupon.usesLimit : null,
        discountType: coupon.discountType || 'percentage',
        discountValue: coupon.discountValue,
        hasRestrictions: coupon.hasRestrictions,
        restrictions: coupon.restrictions,
        restrictedTicketTypes: coupon.restrictedTicketTypes,
        restrictedComplements: coupon.restrictedComplements,
        restrictedExperiences: coupon.restrictedExperiences
      });

      // Set custom minimum value for usesLimit based on current uses
      this.couponForm.get('usesLimit').setValidators([
        Validators.required,
        Validators.min(coupon.currentUses || 1)
      ]);      

      // Disable fields that shouldn't be editable after creation
      this.couponForm.get('code').disable();

      // Enable/Disable discountValue based on currentUses
      if (coupon.currentUses === 0) {
        this.couponForm.get('discountValue').enable();
        this.couponForm.get('discountType').enable(); 
      } else {
        this.couponForm.get('discountValue').disable();
        this.couponForm.get('discountType').disable();
      }

      // Enable the usage limit fields for editing
      this.couponForm.get('hasUsesLimit').enable();
      if (hasUsesLimit) {
        this.couponForm.get('usesLimit').enable();
      } else {
        this.couponForm.get('usesLimit').disable();
      }      

      if (coupon.hasRestrictions) {
        this.couponForm.get('restrictions').enable();
      }
    } else {
      this.isEditMode = false;
      this.couponForm.reset({
        code: this.generateCouponCode(),
        discountType: 'percentage',
        hasUsesLimit: false,
        usesLimit: null,
        hasRestrictions: false,
        restrictions: [],
        restrictedTicketTypes: [],
        restrictedComplements: [],
        restrictedExperiences: []
      });
      // Enable all fields for new coupon creation
      this.couponForm.enable();
      this.couponForm.get('discountType').enable();
      this.couponForm.get('usesLimit').disable();
    }
  }

  updateValidators(restrictions: string[]): void {
    const ticketTypesControl = this.couponForm.get('restrictedTicketTypes');
    const complementsControl = this.couponForm.get('restrictedComplements');
    const experiencesControl = this.couponForm.get('restrictedExperiences');

    if (restrictions?.includes('tickets')) {
      ticketTypesControl.enable();
    } else {
      ticketTypesControl.disable();
      ticketTypesControl.setValue([]);
    }

    if (restrictions?.includes('complements')) {
      complementsControl.enable();
    } else {
      complementsControl.disable();
      complementsControl.setValue([]);
    }

    if (restrictions?.includes('experiences')) {
      experiencesControl.enable();
    } else {
      experiencesControl.disable();
      experiencesControl.setValue([]);
    }
  }

  onSubmit(): void {
    if (this.couponForm.valid) {
      const formValue = this.couponForm.getRawValue();
      const couponData: Coupon = {
        ...formValue,
        usesLimit: formValue.hasUsesLimit ? formValue.usesLimit : null,
        id: this.couponToEdit ? this.couponToEdit.id : undefined,
        createdAt: this.couponToEdit ? this.couponToEdit.createdAt : new Date(),
        discountType: formValue.discountType
      };
      this.saveCoupon.emit(couponData);
    }
  }

  onCancel(): void {
    this.couponForm.reset({
      code: this.generateCouponCode(),
      discountType: 'percentage',
      hasRestrictions: false,
      usesLimit: 0,
      restrictions: [],
      restrictedTicketTypes: [],
      restrictedComplements: [],
      restrictedExperiences: []
    });
    this.hideDialog.emit();
  }

  generateCouponCode(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  duplicateCouponCodeValidator(control: AbstractControl): ValidationErrors | null {
    const code = control.value;
    if (!code) {
      return null; // Let required validator handle empty values
    }
    const isDuplicate = this.existingCoupons.some(coupon => 
      coupon.code === code && coupon.id !== this.couponToEdit?.id
    );
    return isDuplicate ? { duplicateCouponCode: true } : null;
  }

  discountValueValidator(): ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }
      const discountType = control.parent.get('discountType')?.value;
      const discountValue = control.value;
  
      if (discountType === 'percentage') {
        if (discountValue > 100) {
          return { maxPercentExceeded: true };
        }
        if (discountValue <= 0) {
          return { minDiscount: true };
        }
      } else if (discountType === 'fixed') {
        if (discountValue < 0) {
          return { minDiscount: true };
        }
        // No maximum for fixed discounts
      }
  
      return null;
    };
  }

}
