import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RelationService } from '../../service/relation.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Relation } from '../../model/relations/relation.interface';
import { UserService } from 'src/app/user/service/user.service';
import { UserPreview } from '../../model/user-preview.interface';
import { EventService } from '../../service/event.service';
import { EventTickets } from '../../model/event-detail.interface';

@Component({
  selector: 'app-create-relations-dialog',
  templateUrl: './create-relations-dialog.component.html',
  styleUrls: ['./create-relations-dialog.component.scss']
})
export class CreateRelationsDialogComponent implements OnChanges {

  @Input() eventId: string;
  @Input() showCreateDialog: boolean = false;
  @Output() onHide: EventEmitter<Relation> = new EventEmitter();


  relationForm: FormGroup;
  selectedUser: UserPreview;

  loading: boolean = false;
  results: UserPreview[] = [];
  tickets: EventTickets[] = [];

  showCreateUserDialog: boolean = false;

  constructor(private relationService: RelationService, private userService: UserService, private eventService: EventService) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.relationForm = new FormGroup({
      assignedToUser: new FormControl(true, [Validators.required]),
      userId: new FormControl(''),
      userEmail: new FormControl(''),
      linkName: new FormControl(''),
      sendMail: new FormControl(true, []),
      active: new FormControl(true, [Validators.required])
    });

    this.relationForm.get('assignedToUser').valueChanges.subscribe(assignedToUser => {
      if (assignedToUser) {
        // When assignedToUser is true, userId and userEmail are required
        this.relationForm.get('userId').setValidators([Validators.required]);
        this.relationForm.get('userEmail').setValidators([Validators.required]);
        this.relationForm.patchValue({
          linkName: null // Set linkName to null
        });
        // Clear validators for linkName as it is not required
        this.relationForm.get('linkName').clearValidators();
      } else {
        // When assignedToUser is false, linkName is required
        this.relationForm.get('linkName').setValidators([Validators.required]);
        this.relationForm.patchValue({
          userId: null, // Set userId to null
          userEmail: null // Set userEmail to null
        });
        // Clear validators for userId and userEmail as they are not required
        this.relationForm.get('userId').clearValidators();
        this.relationForm.get('userEmail').clearValidators();
      }
      // Update validity of the form controls after changing validators
      this.relationForm.get('userId').updateValueAndValidity();
      this.relationForm.get('userEmail').updateValueAndValidity();
      this.relationForm.get('linkName').updateValueAndValidity();
    });
  
    // Initial validation check in case the form is initialized in a state where the conditional logic should apply
    this.relationForm.get('assignedToUser').updateValueAndValidity();

  }


  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventId'] && changes['eventId'].currentValue) {
      this.eventService.getTickets(this.eventId).subscribe((response: EventTickets[]) => {
        this.tickets = response;
        let ticketsArray = new FormArray([]);

        for (let ticket of response) {
          ticketsArray.push(new FormGroup({
            id: new FormControl(ticket.id, [Validators.required]),
            name: new FormControl(ticket.name, [Validators.required]),
            enabled: new FormControl(false, [Validators.required]),
            reserved: new FormControl(false, [Validators.required]),
            amount: new FormControl('', [])
          }));
        }

        this.relationForm.setControl('ticketLimit', ticketsArray);
      });
    }
  }

  create(): void {
    this.loading = true;
    this.relationService.createRelation(this.eventId, this.relationForm.value).subscribe((response: Relation) => {
      this.loading = false;
      this.onHide.emit(response);
    }, () => {
      this.loading = false;
    });
  }

  hideDialog(dialogId: string, $event: any): void {

    if (dialogId === 'create-user') {

      this.showCreateUserDialog = false;
      this.selectedUser = $event;
      this.relationForm.patchValue({
        userId: $event.id,
        userEmail: $event.email
      });
    } else {

      this.onHide.emit(undefined);
    }
  }

  search($event: any): void {

    this.userService.autocomplete($event.query).subscribe((response: UserPreview[]) => {

      if (response.length > 0) {
        this.results = response;
      } else {
        this.results = [{
          id: 'create',
          email: "Crear usuario " + $event.query,
          fullName: "Crear usuario " + $event.query
        }];
      }
    });
  }

  selectResult($event: any): void {

    if ($event.id === 'create') {

      this.showCreateUserDialog = true;
    } else {

      this.selectedUser = $event;
      this.relationForm.patchValue({
        userId: $event.id,
        userEmail: $event.email
      });
    }
  }

  get ticketLimit() {
    return this.relationForm.controls["ticketLimit"] as FormArray;
  }



}
