import { Component, Input, OnInit } from '@angular/core';
import { EventVenue } from '../../model/event-detail.interface';

@Component({
  selector: 'app-event-detail-venue',
  templateUrl: './event-detail-venue.component.html',
  styleUrls: ['./event-detail-venue.component.scss']
})
export class EventDetailVenueComponent implements OnInit {

  @Input() eventId: string;
  @Input() venue: EventVenue;

  constructor() { }

  ngOnInit(): void {
  }



}
