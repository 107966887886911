import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from 'src/app/user/service/user.service';
import { MerchService } from 'src/app/merch/service/merch.service';
import {
  CartItem,
  TokenUpgradeType,
} from 'src/app/user/model/cart-item.interface';
import { Merch } from 'src/app/merch/model/merch';

@Component({
  selector: 'app-add-product-dialog',
  templateUrl: './add-product-dialog.component.html',
  styleUrls: ['./add-product-dialog.component.scss'],
})
export class AddProductDialogComponent implements OnInit {
  selectedProduct: Merch | null = null;
  selectedProductStock: number = 0;
  showQuantityField: boolean = false;
  loading: boolean = false;
  products: Merch[] = [];
  cart: CartItem[] = [];

  @Input() tokenId: string = '';
  @Input() displayDialog: boolean = false;
  @Output() hideDialog: EventEmitter<any> = new EventEmitter();

  customizationForm: FormGroup = new FormGroup({
    customization: new FormControl('', [Validators.required]),
    size: new FormControl({ value: '', disabled: true }),
    quantity: new FormControl({ value: 1, disabled: false }),
    deliveryOptions: new FormControl({ value: [], disabled: true }),
  });

  constructor(
    private userService: UserService,
    private merchService: MerchService
  ) {}

  ngOnInit(): void {
    this.customizationForm
      .get('customization')
      .valueChanges.subscribe((val) => {
        const selectedProduct = this.products.find((e) => e.id === val);
        this.selectedProduct = selectedProduct || null;
        if (selectedProduct) {
          if (!selectedProduct.variable) {
            this.selectedProductStock = selectedProduct.available;
            this.showQuantityField = true;
            this.customizationForm.get('quantity').enable();
            this.customizationForm
              .get('quantity')
              .setValidators([
                Validators.required,
                Validators.min(1),
                Validators.max(this.selectedProductStock),
              ]);
          } else {
            this.showQuantityField = false;
            this.customizationForm.get('size').enable();
            this.customizationForm.get('size').setValue('');
            this.customizationForm.get('quantity').disable();
            this.customizationForm.get('quantity').clearValidators();
            if (selectedProduct.sizes && selectedProduct.sizes.length) {
              this.selectedProduct.sizes = this.selectedProduct.sizes.filter(
                (size) => size.available > 0
              );
            }
          }
          this.customizationForm.get('quantity').updateValueAndValidity();

          if (
            selectedProduct.delivery &&
            selectedProduct.delivery.options &&
            selectedProduct.delivery.options.length > 0
          ) {
            this.customizationForm.get('deliveryOptions').enable();
          } else {
            this.customizationForm.get('deliveryOptions').disable();
          }
          this.customizationForm.get('deliveryOptions').setValue([]);
        }
      });

    this.customizationForm.get('size').valueChanges.subscribe((sizeVal) => {
      if (!sizeVal) {
        // No size selected
        this.showQuantityField = false;
        this.customizationForm.get('quantity').disable();
        this.customizationForm.get('quantity').clearValidators();
      } else {
        // A size is selected
        const selectedSize = this.selectedProduct?.sizes?.find(
          (s) => s.id === sizeVal
        );
        if (selectedSize) {
          this.selectedProductStock = selectedSize.available;
          this.showQuantityField = true;
          this.customizationForm.get('quantity').enable();
          this.customizationForm
            .get('quantity')
            .setValidators([
              Validators.required,
              Validators.min(1),
              Validators.max(this.selectedProductStock),
            ]);
          this.customizationForm.get('quantity').updateValueAndValidity();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tokenId'] && this.tokenId) {
      this.merchService.getMerch().subscribe((response: Merch[]) => {
        this.products = response.filter((p) => {
          if (!p.variable) {
            return p.available > 0;
          } else {
            // If product.variable is true, sum the available sizes
            const totalAvailable = Object.values(p.sizes).reduce(
              (acc: any, size) => acc + size.available,
              0
            );
            p.available = totalAvailable;
            return totalAvailable > 0;
          }
        });
      });
    }
  }

  onDialogHide(): void {
    this.displayDialog = false;
    this.hideDialog.emit(undefined);
  }

  cancelAddProduct(): void {
    this.onDialogHide();
  }

  confirmAddProduct(): void {
    this.cart = [
      {
        type: TokenUpgradeType.ADVERTISEMENT,
        id: this.customizationForm.get('customization').value,
        quantity: this.customizationForm.get('quantity').value,
        price: 0,
        variants: this.customizationForm.get('size').value
          ? [this.customizationForm.get('size').value]
          : null,
      },
    ];

    this.loading = true;

    this.userService.upgrade(this.tokenId, this.cart).subscribe(() => {
      this.onDialogHide();
      this.loading = false;
      // Reload the page
      window.location.reload();
    });
  }
}
