<p-dialog header="Añadir contenido" [(visible)]="displayImportDialog" [modal]="true"
  [style]="{ width: '50vw', height: '80vh' }" (onHide)="onDialogHide()">
  <div>
    <h3>Paso 1: Prepara la subida</h3>
    <p>Formatos permitidos: csv</p>
    <p>Cabecera del CSV: name,surname1,surname2,email,phone</p>
    <p>Campos obligatorios: email</p>
    <button pButton pRipple label="Descarga la plantilla CSV" icon="pi pi-download" type="button" class="p-button-text"
      (click)="downloadTemplate()"></button>
  </div>
  <div>
    <h3>Paso 2: Sube tu fichero</h3>
    <form [formGroup]="importForm">
      <div class="field">
        <p-fileUpload name="myfile[]" mode="basic" accept="text/csv" chooseLabel="Seleccionar fichero" [auto]="true"
          [customUpload]="true" (uploadHandler)="myUploader($event)"></p-fileUpload>
        <p *ngIf="file">Archivo seleccionado: {{ file?.name }}</p>
      </div>

      <button pButton pRipple *ngIf="!loading" [disabled]="!importForm.valid" label="Añadir contenido" class="w-full"
        (click)="addContent()"></button>
      <button pButton pRipple *ngIf="loading" [disabled]="loading" icon="pi pi-spin pi-spinner" iconPos="right"
        label="Añadir contenido" class="w-full"></button>
      <div style="color: red" [innerHTML]="errorMessage"></div>
    </form>
  </div>
</p-dialog>