import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventContent } from '../../model/event-detail.interface';
import { ContentService } from '../../service/content.service';

@Component({
  selector: 'app-update-content-dialog',
  templateUrl: './update-content-dialog.component.html',
  styleUrls: ['./update-content-dialog.component.scss']
})
export class UpdateContentDialogComponent implements OnInit {

  @Input() eventId: string;
  @Input() contentId: string;
  @Input() displayUpdateContentDialog: boolean;
  @Output() hideDialog: EventEmitter<any> = new EventEmitter<any>();

  accessControlOptions = [
    {
      id: "public",
      icon: "pi pi-globe",
      value: "Público"
    },
    {
      id: "fan",
      icon: "pi pi-users",
      value: "Exlcusivo para fans"
    }
    ,
    {
      id: "attendant",
      icon: "pi pi-user",
      value: "Exclusivo para asistentes"
    }
  ];

  contentForm: FormGroup = new FormGroup({
    accessControl: new FormControl('', [Validators.required])
  });

  constructor(private contentService: ContentService) { }

  ngOnInit(): void {
  }

  updateContent() {

    this.contentService.changeAccessControl(this.eventId, this.contentId, this.contentForm.value).subscribe(() => {
      this.hideDialog.emit({
        id: this.contentId,
        accessControl: this.contentForm.get('accessControl').value
      });
    });
  }

  onDialogHide():void{
    this.displayUpdateContentDialog = false;
    this.hideDialog.emit(undefined);
  }



}
