import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';

@Component({
  selector: 'app-general-market-report',
  templateUrl: './general-market-report.component.html',
  styleUrls: ['./general-market-report.component.scss']
})
export class GeneralMarketReportComponent implements OnInit, OnDestroy {
  @Input() timeframe: string = 'all-time';

  @ViewChild('chart') salesChartElement!: ElementRef;
  @ViewChild('ticketsChart') ticketsChartElement!: ElementRef;
  @ViewChild('upgradesChart') upgradesChartElement!: ElementRef;
  @ViewChild('merchChart') merchChartElement!: ElementRef;
  @ViewChild('rpSalesChart') rpSalesChartElement!: ElementRef;

  private charts: { [key: string]: echarts.ECharts } = {};
  private resizeHandler: () => void;

  kpiData = [
    { title: 'Asistentes', value: '27.023' },
    { title: 'Total bruto facturado', value: '314k€' },
    { title: 'Ticket medio', value: '55,36€' },
    { title: 'Upselling', value: '64%' },
    { title: 'Entradas reasignadas', value: '2375' }
  ];

  private readonly salesChartOptions: EChartsOption = {
    animation: true,
    animationDuration: 300,
    animationEasing: 'cubicOut',
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}€'
      }
    },
    series: [{
      data: [15000, 18000, 25000, 20000, 15000, 24000, 28000, 32000, 38000, 35000, 30000, 25000],
      type: 'line',
      smooth: true,
      areaStyle: {
        opacity: 0.3
      },
      lineStyle: {
        width: 2
      }
    }]
  };

  private readonly ticketsChartOptions: EChartsOption = {
    animation: true,
    animationDuration: 300,
    animationEasing: 'cubicOut',
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: ['VIP', 'Standard', 'Early Bird', 'Premium']
    },
    series: [{
      type: 'bar',
      data: [1500, 3000, 2500, 1800]
    }]
  };

  private readonly upgradesChartOptions: EChartsOption = {
    animation: true,
    animationDuration: 300,
    animationEasing: 'cubicOut',
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: ['VIP Access', 'Meet & Greet', 'Fast Pass', 'Premium Seating']
    },
    series: [{
      type: 'bar',
      data: [800, 1200, 600, 900]
    }]
  };

  private readonly merchChartOptions: EChartsOption = {
    animation: true,
    animationDuration: 300,
    animationEasing: 'cubicOut',
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: ['T-Shirts', 'Posters', 'Hats', 'Accessories']
    },
    series: [{
      type: 'bar',
      data: [500, 300, 200, 400]
    }]
  };

  private readonly rpSalesChartOptions: EChartsOption = {
    animation: true,
    animationDuration: 300,
    animationEasing: 'cubicOut',
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}€'
      }
    },
    yAxis: {
      type: 'category',
      data: ['Partner A', 'Partner B', 'Partner C', 'Partner D']
    },
    series: [{
      type: 'bar',
      data: [50000, 35000, 45000, 30000]
    }]
  };

  constructor() {
    this.resizeHandler = () => {
      Object.values(this.charts).forEach(chart => {
        chart?.resize();
      });
    };
  }

  ngOnInit() { }

  ngAfterViewInit() {
    // Initialize after view is ready
    setTimeout(() => {
      this.initCharts();
      this.handleResize();
    });
  }

  ngOnDestroy() {
    this.disposeCharts();
    window.removeEventListener('resize', this.resizeHandler);
  }

  private initCharts() {
    const chartConfigs = [
      { element: this.salesChartElement?.nativeElement, options: this.salesChartOptions },
      { element: this.ticketsChartElement?.nativeElement, options: this.ticketsChartOptions },
      { element: this.upgradesChartElement?.nativeElement, options: this.upgradesChartOptions },
      { element: this.merchChartElement?.nativeElement, options: this.merchChartOptions },
      { element: this.rpSalesChartElement?.nativeElement, options: this.rpSalesChartOptions }
    ];

    chartConfigs.forEach(config => {
      if (config.element) {
        if (this.charts[config.element.className]) {
          this.charts[config.element.className].dispose();
        }
        const chart = echarts.init(config.element);
        chart.setOption(config.options);
        this.charts[config.element.className] = chart;
      }
    });
  }

  private handleResize() {
    window.addEventListener('resize', this.resizeHandler);
  }

  private disposeCharts() {
    Object.values(this.charts).forEach(chart => {
      chart?.dispose();
    });
    this.charts = {};
  }
}