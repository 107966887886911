<div *ngIf="type === 'experience'">
  <div *ngFor="let experience of experiences">
    <app-generic-list-item 
      [image]="experience?.images?.length > 0 ? experience.images[0] : ''" 
      [title]="experience?.name"
      [description]="experience?.description" 
      [additionalInfo]="experience?.quantity + ' unidades'">
      <div item-actions class="ticket-actions">
        <button pButton class="p-button-text p-button-rounded p-button-danger" icon="pi pi-trash" 
          (click)="unassignExperience(experience.id)"
          pTooltip="Desasignar experiencia" tooltipPosition="top">
        </button>
      </div>
    </app-generic-list-item>
  </div>
  <div *ngIf="experiences?.length === 0">No hay experiencias adicionales.</div>
</div>

<div *ngIf="type === 'complement'">
  <div *ngFor="let complement of complements">
    <app-generic-list-item 
      [image]="complement?.images?.length > 0 ? complement.images[0] : ''" 
      [title]="complement?.name"
      [description]="complement?.description" 
      [additionalInfo]="complement?.quantity + ' unidades'">
      <div item-actions class="ticket-actions">
        <button pButton class="p-button-text p-button-rounded p-button-danger" icon="pi pi-trash" 
          (click)="unassignComplement(complement.productId)"
          pTooltip="Desasignar complemento" tooltipPosition="top">
        </button>
      </div>
    </app-generic-list-item>
  </div>
  <div *ngIf="complements?.length === 0">No hay complementos adicionales.</div>
</div>
