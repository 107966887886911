import { Component, Input, OnInit } from '@angular/core';
import { PurchaseTicket } from '../../model/purchase-ticket.interface';
import { EventExperience, EventTickets } from 'src/app/event/model/event-detail.interface';
import { EventService } from 'src/app/event/service/event.service';
import { PurchaseExperience } from '../../model/purchase-experience.interface';
import { Ticket } from 'src/app/event/model/ticket.interface';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-purchase-detail-tickets',
  templateUrl: './purchase-detail-tickets.component.html',
  styleUrls: ['./purchase-detail-tickets.component.scss']
})
export class PurchaseDetailTicketsComponent implements OnInit {

  @Input() eventId: string;
  @Input() purchaseId: string;
  //@Input() tickets: PurchaseTicket[];
  //@Input() experiences: PurchaseExperience[];
  tickets: Ticket[];

  experienceTypes: EventExperience[];
  ticketTypes: EventTickets[];

  constructor(private eventService: EventService, private userService: UserService) { }

  ngOnInit(): void {
    this.eventService.getTickets(this.eventId).subscribe((response: EventTickets[]) => this.ticketTypes = response);
    this.eventService.getExperiences(this.eventId).subscribe((response: any) => this.experienceTypes = response.experiences);
    this.userService.getTicketsForPurchase(this.purchaseId).subscribe((response: Ticket[]) => this.tickets = response);
  }

}
