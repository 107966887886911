import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Attendant } from '../../model/attendant.interface';
import { EventService } from '../../service/event.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ExportResponse } from 'src/app/shared/model/export-response.interface';
import { ContentUpload } from 'src/app/shared/model/content-upload.interface';
import { UserService } from 'src/app/user/service/user.service';
import { JobStatus } from 'src/app/user/model/job-status.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UserPreview } from '../../model/user-preview.interface';
import { PagedResponse } from 'src/app/shared/model/paged-response.interface';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-event-detail-attendees',
  templateUrl: './event-detail-attendees.component.html',
  styleUrls: ['./event-detail-attendees.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(200)
      ]),
      transition(':leave', [
        animate(200, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class EventDetailAttendeesComponent implements OnInit, OnChanges {
  @Input() eventId: string = '';
  selectedAttendant: Attendant | null = null;
  attendants: Attendant[] = [];
  ticketTypeOptions: any[] = [];
  blockDownload: boolean = false;
  loading: boolean = false;
  file: File | null = null;
  uploadJob: ContentUpload | null = null;
  intervalId: any;
  jobStatus: JobStatus | null = null;
  errorMessage: string = '';

  rowsPerPageOptions: number[] = [25, 50, 100];
  totalRecords: number = 0;
  pageSize: number = 25;
  loadingAttendants: boolean = false;
  currentPage: number = 0;
  first: number = 0;  

  showInvite: boolean = false;
  pressedAssignProduct: boolean = false;
  displayAddCustomizationDialog: boolean = false;
  showToken: boolean = false;
  showErrorQR: boolean = false;
  fallbackQR: string = '';
  tokenUrls: { [key: string]: string } = {};

  loadingSugestions: boolean = false;
  results: UserPreview[] = [];

  invitationTypes = [
    { id: 'individual', name: 'Individual' },
    { id: 'bulk', name: 'Masiva' },
  ];

  inviteForm: FormGroup = new FormGroup({
    invitationType: new FormControl('individual', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    ticketType: new FormControl('', [Validators.required]),
    notifyUser: new FormControl(false)
  });
  token: any;

  skeletonRows = Array(25).fill({});

  private attendantIdFromParams: string | null = null;

  // Search and sort
  searchTerm: string = '';
  searchSubject = new Subject<string>();
  sortField: string = 'purchasedAt';
  sortOrder: number = -1;


  constructor(
    private eventService: EventService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private route: ActivatedRoute,
  ) {
      this.searchSubject.pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(term => {
        this.searchTerm = term;
        this.loadEventDetails(0, this.pageSize, this.sortField, this.sortOrder);
        
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { 
            search: term || null,
            page: this.currentPage,
            size: this.pageSize,
            sort: this.sortField,
            order: this.sortOrder
          },
          queryParamsHandling: 'merge'
        });
      });
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      // Handle pagination params
      this.currentPage = params['page'] ? +params['page'] : 0;
      this.pageSize = params['size'] ? +params['size'] : 25;
      this.first = this.currentPage * this.pageSize;
      this.sortField = params['sort'] || 'purchasedAt';
      this.sortOrder = params['order'] ? +params['order'] : -1;
      this.searchTerm = params['search'] || '';

      // Handle selected attendant
      const attendantId = params['id'];
      if (attendantId) {
        this.attendantIdFromParams = attendantId;
      } else {
        this.selectedAttendant = null;
      }

      // Load data if we have an eventId
      if (this.eventId) {
        this.loadEventDetails(this.currentPage, this.pageSize, this.sortField, this.sortOrder);
      }
    });

    // Initialize forms
    this.initializeForm();
  }

  onSearch(event: any): void {
    this.searchSubject.next(event.target.value);
  }

  private initializeForm(): void {
    this.inviteForm.get('invitationType')?.valueChanges.subscribe((type) => {
      this.updateFormFields(type);
    });
    this.updateFormFields(this.inviteForm.get('invitationType')?.value);
  }

  search($event: any): void {
    this.userService.autocomplete($event.query).subscribe((response: UserPreview[]) => {
      if (response.length > 0) {
        this.results = response;
      } else {
        this.results = [{
          id: 'create',
          email: $event.query,
          fullName: "Invitar a nuevo usuario"
        }];
      }
    });
  }

  selectResult($event: any): void {
    this.inviteForm.patchValue({
      email: $event.email
    });
  }

  loadEventDetails(page: number = 0, size: number = this.pageSize, sortField: string = this.sortField, sortOrder: number = this.sortOrder): void {
    this.loading = true;
    
    this.eventService.retrieveAttendants(this.eventId, page, size, sortField, sortOrder, this.searchTerm).subscribe({
      next: (response: PagedResponse<Attendant>) => {
        this.attendants = response.content;
        this.totalRecords = response.totalElements;
        this.loading = false;

        // After attendants are loaded, set selectedAttendant if ID was provided
        if (this.attendantIdFromParams) {
          this.selectedAttendant = this.attendants.find(a => a.ticketId === this.attendantIdFromParams) || null;
          this.attendantIdFromParams = null;
        }
      },
      error: (error) => {
        console.error('Error loading attendants:', error);
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load attendants'
        });
      }
    });

    // Load ticket types
    this.eventService.retrieveTicketTypes(this.eventId).subscribe({
      next: (response: any[]) => {
        this.ticketTypeOptions = response;
      },
      error: (error) => {
        console.error('Error loading ticket types:', error);
      }
    });
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (event.first === undefined || event.rows === undefined) return;
    
    const page = event.first / event.rows;
    const size = event.rows;
    const sortField = event.sortField || this.sortField;
    const sortOrder = event.sortOrder || this.sortOrder;

    // Update URL with pagination state only
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { 
        page,
        size,
        sort: sortField,
        order: sortOrder,
        search: this.searchTerm || null,        
        ...(this.selectedAttendant ? { id: this.selectedAttendant.ticketId } : {})
      },
      queryParamsHandling: 'merge'
    });

    this.loadEventDetails(page, size, sortField, sortOrder);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['eventId'] && changes['eventId'].currentValue) {
      this.loadEventDetails(0, this.pageSize);
    }
  }

  findAndSelectAttendant(attendantTicketId: string): void {
    const attendant = this.attendants.find((a) => a.ticketId === attendantTicketId);

    if (attendant) {
      this.selectedAttendant = attendant;
      this.openUser({ data: attendant });
    } else {
      console.warn('Attendant not found');
    }
  }

  updateFormFields(type: string) {
    // Reset controls to avoid duplicates
    this.inviteForm.removeControl('ticketType');
    this.inviteForm.removeControl('email');
    this.inviteForm.removeControl('file');

    this.inviteForm.addControl(
      'ticketType',
      new FormControl('', [Validators.required])
    );
    if (type === 'individual') {
      this.inviteForm.addControl(
        'email',
        new FormControl('', [Validators.required, Validators.email])
      );
    } else if (type === 'bulk') {
      this.inviteForm.addControl(
        'file',
        new FormControl(null, [Validators.required])
      );
    }
  }

  validate(ticketId: string) {
    this.eventService.validateAttendant(ticketId).subscribe(() => {
      const attendantIndex = this.attendants.findIndex(a => a.ticketId === ticketId);

      if (attendantIndex !== -1) {
        this.attendants[attendantIndex] = {
          ...this.attendants[attendantIndex],
          validated: true
        };
      }
    });
  }

  unvalidate(ticketId: string) {
    this.eventService.unvalidateAttendant(ticketId).subscribe(() => {
      const attendantIndex = this.attendants.findIndex(a => a.ticketId === ticketId);

      if (attendantIndex !== -1) {
        this.attendants[attendantIndex] = {
          ...this.attendants[attendantIndex],
          validated: false
        };
      }
    });
  }

  downloadTemplate(): void {
    window.open('/assets/templates/import.csv', '_blank');
  }

  exportList(): void {
    this.blockDownload = true;
    this.eventService.export(this.eventId).subscribe(
      (response: ExportResponse) => {
        window.open(response.url, '_blank');
        this.blockDownload = false;
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: '',
        });
        this.blockDownload = false;
      }
    );
  }

  myUploader(event: any): void {
    this.file = event.files[0];
    this.inviteForm.patchValue({ file: this.file });
    this.inviteForm.get('file')?.updateValueAndValidity();
  }

  showInviteDialog(): void {
    this.inviteForm.reset({ invitationType: 'individual' });
    this.showInvite = true;
  }

  confirmInvite(individualType: string): void {
    if (individualType === 'individual') {
      this.confirmationService.confirm({
        message:
          'Vas a enviar una invitación a ' +
          this.inviteForm.get('email')?.value +
          ' para que asista a la experiencia. ¿Estás seguro?',
        header: 'Revisa la invitación',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.eventService
            .inviteUser(this.eventId, this.inviteForm.value)
            .subscribe(
              () => {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Usuario invitado',
                  detail:
                    'El usuario ha sido invitado con éxito',
                });
                this.showInvite = false;
              },
              () => {
                this.messageService.add({
                  severity: 'error',
                  summary: 'La invitación ha fallado',
                  detail: 'Por favor, vuelve a intentarlo de nuevo',
                });
              }
            );
        },
        reject: () => {
          this.showInvite = false;
        },
      });
    } else {
      let ticketTypeId = this.inviteForm.get('ticketType')?.value;
      let notifyUser = this.inviteForm.get('notifyUser')?.value;

      this.userService.getSignedURL().subscribe(
        (response: ContentUpload) => {
          this.uploadJob = response;
          if (this.uploadJob.signedUrl !== undefined) {
            this.userService.uploadContent(response.signedUrl, this.file!).subscribe(
              (response: any) => {
                if (response.status !== undefined && response.status == 200) {
                  this.userService.executeImport(this.uploadJob.id, ticketTypeId, this.eventId, notifyUser).subscribe(
                    () => {
                      const intervalId = setInterval(() => {
                        this.userService.getImportStatus(this.uploadJob!.id).subscribe(
                          (response: JobStatus) => {
                            this.jobStatus = response;
                            if (response.state === 'FINISH_OK' || response.state === 'FINISH_KO') {
                              clearInterval(intervalId);
                              this.cancelInvite();
                              this.loading = false;
                              if (response.state === 'FINISH_KO') {
                                this.messageService.add({
                                  severity: 'error',
                                  summary: 'Error al importar',
                                  detail: 'Por favor, revisa el archivo y vuelve a intentarlo',
                                });
                              } else {
                                this.messageService.add({
                                  severity: 'success',
                                  summary: 'Importación completada',
                                  detail: 'Los usuarios han sido invitados correctamente',
                                });
                              }
                            }
                          },
                          () => {
                            clearInterval(intervalId);
                            this.loading = false;
                          }
                        );
                      }, 3000);
                      this.intervalId = intervalId;
                    },
                    (err: HttpErrorResponse) => {
                      this.loading = false;
                      switch (err.status) {
                        case 409:
                          this.errorMessage = '';
                          break;
                        case 413:
                          this.errorMessage = ``;
                          break;
                        default:
                          this.errorMessage = '';
                          break;
                      }
                    }
                  );
                }
              },
              () => (this.loading = false)
            );
          } else {
            this.loading = false;
          }
        },
        () => (this.loading = false)
      );
    }
  }

  cancelInvite(): void {
    this.showInvite = false;
  }

  clear(table: Table): void {
    this.searchTerm = '';
    table.clear();
    this.loadEventDetails();
  }

  openUser(event: any): void {
    if (event && event.data) {
      this.selectedAttendant = event.data;

      // Update URL maintaining pagination state
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { 
          id: this.selectedAttendant.ticketId,
          page: this.currentPage,
          size: this.pageSize
        },
        queryParamsHandling: 'merge'
      });
    } else {
      this.selectedAttendant = event;
      
      // Clear attendant ID from URL while maintaining pagination
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { 
          page: this.currentPage,
          size: this.pageSize
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  getFilteredTicketTypes(): any[] {
    return this.ticketTypeOptions.filter((type) => type.ticketsNumber - type.ticketsSold > 0);
  }

  onAssistantDeleted(ticketId: string): void {
    this.attendants = this.attendants.filter(attendant => attendant.ticketId !== ticketId);
  }

}
