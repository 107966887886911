import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-generic-list-item',
  templateUrl: './generic-list-item.component.html',
  styleUrls: ['./generic-list-item.component.scss']
})
export class GenericListItemComponent implements OnInit {
  @Input() image?: string;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() description?: string;
  @Input() additionalInfo?: string;
  @Input() sold?: number;
  @Input() stock?: number;
  @Input() price?: string;

  @Output() deleteItem: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit() {
  }

}