import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: { breadcrumb: 'Home' }
  },
  {
    path: 'events', loadChildren: () => import('./event/event.module').then(m => m.EventModule),
    data: { breadcrumb: 'Experiencias' }
  },
  {
    path: 'users', loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    data: { breadcrumb: 'Fans' }
  },
  {
    path: 'lists', loadChildren: () => import('./list/list.module').then(m => m.ListModule),
    data: { breadcrumb: 'Segmentos' }
  },
  {
    path: 'products', loadChildren: () => import('./merch/merch.module').then(m => m.MerchModule),
    data: { breadcrumb: 'Complementos' }
  },
  {
    path: 'campaigns', loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule),
    data: { breadcrumb: 'Campañas' }
  },
  {
    path: 'web', loadChildren: () => import('./web/web.module').then(m => m.WebModule),
    data: { breadcrumb: 'Web' }
  },
  {
    path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    data: { breadcrumb: 'Mi organización' }
  },
  {
    path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
