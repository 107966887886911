import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { Organization } from 'src/app/auth/model/organization.interface';
import { SignedURL } from 'src/app/event/model/signed-url.interface';
import { environment } from 'src/environments/environment';
import { Invoice } from '../model/invoice.interface';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {


  baseUrl: string = environment.apiUrl


  private paymentComplete = new Subject<any>();

  paymentComplete$ = this.paymentComplete.asObservable();

  constructor(private http: HttpClient, private handler: HttpBackend) { }

  getTenantInfo(): Observable<Organization> {

    const url = `${this.baseUrl}/tenants`

    return this.http.get<Organization>(url);

  }

  updateTenant(tenantForm: FormGroup): Observable<any> {

    const url = `${this.baseUrl}/tenants`;

    return this.http.put(url, tenantForm.getRawValue());
  }

  getSignedUrl(mimeType: string): Observable<SignedURL> {

    const url = `${this.baseUrl}/tenants/updateLogo`

    return this.http.get<SignedURL>(url, { params: { mimeType } });
  }

  uploadImage(fileuploadurl, contenttype, file): Observable<any> {

    const headers = new HttpHeaders({'Content-Type': contenttype});
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      file,
      {
        headers: headers,
        reportProgress: true, //This is required for track upload process
      });
    return this.http.request(req);
  }

  requestChannelIntegration(channelName: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/tenants/requestChannelIntegration`, { channelName });
  }

  downloadInvoice(type: string, invoiceId: string): any {
    const url = `${this.baseUrl}/billing/${type}/${invoiceId}/download`;
    return this.http.get<any>(url);
  }
  
  getInvoicesList(): Observable<Invoice[]> {
    const url = `${this.baseUrl}/billing`
    return this.http.get<Invoice[]>(url);
  }

}
