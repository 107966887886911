import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Advertisement } from 'src/app/event/model/advertisement.interface';
import { CartItem, TokenUpgradeType } from '../../model/cart-item.interface';

@Component({
  selector: 'app-customization-dialog',
  templateUrl: './customization-dialog.component.html',
  styleUrls: ['./customization-dialog.component.scss']
})
export class CustomizationDialogComponent implements OnInit {

  selectedExperienceStock: number = 0;
  allowMultiple: boolean = false;
  loading: boolean = false;
  experiences: Advertisement[] = [];
  cart: CartItem[] = [];

  @Input() tokenExperiencesIds: string[] = [];
  @Input() tokenId: string = '';
  @Input() displayDialog: boolean = false;
  @Output() hideDialog: EventEmitter<any> = new EventEmitter();

  customizationForm: FormGroup = new FormGroup({
    customization: new FormControl('', [Validators.required]),
    quantity: new FormControl({value: 1, disabled: true})
  });

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.customizationForm.get('customization').valueChanges.subscribe(val => {
      const selectedExperience = this.experiences.find(e => e.id === val);
      if (selectedExperience) {
        this.selectedExperienceStock = selectedExperience.stock;
        this.allowMultiple = selectedExperience.allowMultiple;
        if (selectedExperience.allowMultiple) {
          this.customizationForm.get('quantity').enable();
          this.customizationForm.get('quantity').setValidators([
            Validators.required,
            Validators.max(selectedExperience.stock)
          ]);
          this.customizationForm.get('quantity').updateValueAndValidity();
        } else {
          this.customizationForm.get('quantity').disable();
          this.customizationForm.get('quantity').setValue(1);
          this.customizationForm.get('quantity').clearValidators();
          this.customizationForm.get('quantity').updateValueAndValidity();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tokenId'] && this.tokenId) {
      this.userService.getCustomization(this.tokenId, 'improve-experience').subscribe((response: Advertisement[]) => {
        // Filter experiences to include only those with stock greater than 0 and not already assigned to the token in case allowMultiple is false
        this.experiences = response.filter(e => e.stock > 0 && !(e.allowMultiple === false && this.tokenExperiencesIds.includes(e.id)));
      });
    }
  }

  onDialogHide(): void {
    this.displayDialog = false;
    this.hideDialog.emit(undefined);
  }

  cancelCustomization(): void {
    this.onDialogHide();
  }

  confirmCustomization(): void {

      this.cart = [{
        type: TokenUpgradeType.EXPERIENCE,
        id: this.customizationForm.get('customization').value,
        quantity: this.customizationForm.get('quantity').value,
        price: 0,
        variants: []
      }]

      this.loading = true;

    this.userService.upgrade(this.tokenId, this.cart).subscribe(() => {
      this.onDialogHide();
      this.loading = false;
      // Reload the page
      window.location.reload();
    });

  }

}
