<div class="card h-full">
  <div class="card-container justify-content-start p-3">
    <div class="block border-round mb-3 table-container">
      <p-table 
      #usersTable 
      [value]="loading ? skeletonRows : users" 
      selectionMode="single" 
      [(selection)]="selectedUser"
      (onRowSelect)="openUser($event)" 
      styleClass="p-datatable-sm"
      [lazy]="true" 
      [paginator]="true" 
      [rows]="pageSize" 
      [rowsPerPageOptions]="rowsPerPageOptions"
      [totalRecords]="totalRecords"
      [first]="first"
      (onLazyLoad)="loadUsers($event)"
      [showCurrentPageReport]="true"
      [sortField]="sortField"
      [sortOrder]="sortOrder"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} usuarios">
      <ng-template pTemplate="caption">
        <div class="flex justify-content-end w-full">
          <span class="p-input-icon-left w-21rem">
            <i class="pi pi-search"></i>
            <input 
              pInputText 
              type="text" 
              class="w-full"
              [value]="searchTerm"
              (input)="onSearch($event)" 
              placeholder="Buscar por nombre, email y/o teléfono" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 20%" pSortableColumn="name">
            Nombre
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th style="width: 30%" pSortableColumn="email">
            Email
            <p-sortIcon field="email"></p-sortIcon>
          </th>
          <th style="width: 12.5%" pSortableColumn="phone">
            Teléfono
            <p-sortIcon field="phone"></p-sortIcon>
          </th>
          <th style="width: 12.5%" pSortableColumn="state">
            Estado
            <p-sortIcon field="state"></p-sortIcon>
          </th>
          <th style="width: 12.5%" pSortableColumn="totalPurchases">
            Experiencias
            <p-sortIcon field="totalPurchases"></p-sortIcon>
          </th>
          <th style="width: 12.5%" pSortableColumn="totalIncome">
            Valor
            <p-sortIcon field="totalIncome"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
        <ng-template let-user pTemplate="body">
          <tr [pSelectableRow]="user">
            <td>
              <div *ngIf="loading">
                <p-skeleton width="100%" height="24px"></p-skeleton>
              </div>
              <ng-container *ngIf="!loading">
                {{ getFullName(user.name, user.surname1, user.surname2) }}
              </ng-container>
            </td>
            <td>
              <div *ngIf="loading">
                <p-skeleton width="100%" height="24px"></p-skeleton>
              </div>
              <ng-container *ngIf="!loading">{{ user?.email }}</ng-container>
            </td>
            <td>
              <div *ngIf="loading">
                <p-skeleton width="80%" height="24px"></p-skeleton>
              </div>
              <ng-container *ngIf="!loading">{{ user.phone == null ? "-" : user.phone }}</ng-container>
            </td>
            <td>
              <div *ngIf="loading">
                <p-skeleton width="100%" height="24px"></p-skeleton>
              </div>
              <div *ngIf="!loading" class="flex flex-row align-items-center justify-content-center">
                <p-tag [value]="getState(user.state)"></p-tag>
              </div>
            </td>
            <td>
              <div *ngIf="loading">
                <p-skeleton width="60%" height="24px"></p-skeleton>
              </div>
              <ng-container *ngIf="!loading">{{ user.events && user.events.length }}</ng-container>
            </td>
            <td>
              <div *ngIf="loading">
                <p-skeleton width="80%" height="24px"></p-skeleton>
              </div>
              <ng-container *ngIf="!loading">
                {{ user.totalIncome / 100 | currency : "EUR" : "symbol" : "1.2-2" }}
              </ng-container>
            </td>
            <td>
              <div *ngIf="loading">
                <p-skeleton width="24px" height="24px"></p-skeleton>
              </div>
              <ng-container *ngIf="!loading">
                <i class="pi pi-angle-right"></i>
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <p-speedDial [model]="items" direction="up" class="custom-speed-dial">
    <ng-template pTemplate="item" let-item>
      <button pButton [icon]="item.icon" class="custom-speed-dial-button"
        pTooltip="{{item.tooltipOptions.tooltipLabel}}"
        tooltipPosition="{{item.tooltipOptions.tooltipPosition}}">
      </button>
    </ng-template>
  </p-speedDial>
</div>

<app-import-dialog [displayImportDialog]="displayImportDialog" (hideDialog)="hideDialog('import')"></app-import-dialog>
<app-create-dialog [displayCreateDialog]="displayCreateDialog" (hideDialog)="hideDialog('create')"></app-create-dialog>