import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-toggle',
  templateUrl: './custom-toggle.component.html',
  styleUrls: ['./custom-toggle.component.scss']
})
export class CustomToggleComponent implements OnInit {

  @Input() isActive: boolean = false;
  @Output() changed = new EventEmitter<boolean>();

  toggle() {
    this.isActive = !this.isActive;
    this.changed.emit(this.isActive);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
