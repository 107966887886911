<p-sidebar [(visible)]="show" position="right" [baseZIndex]="10000" styleClass="custom-sidebar" (onHide)="onDialogHide()">
    <h3>Enviar una notificación</h3>
    <div class="flex flex-row">
        <div class="flex-grow-1 mr-3">
            <p-editor placeholder="Escribe lo que quieras decirle a los asistentes" [(ngModel)]="message"
                [style]="{ height: '100px' }">
                <ng-template pTemplate="header">
                    <span class="ql-formats">
                        <select class="ql-header">
                            <option value="1"></option>
                            <option value="2"></option>
                            <option value="3"></option>
                            <option value="4"></option>
                            <option value="5"></option>
                            <option value="6"></option>
                            <option value="false" selected></option>
                        </select>
                        <button type="button" class="ql-bold" aria-label="Bold"></button>
                        <button type="button" class="ql-italic" aria-label="Italic"></button>
                        <button type="button" class="ql-underline" aria-label="Underline"></button>
                        <button type="button" class="ql-strike" aria-label="Strike"></button>
                        <button type="button" class="ql-list" value="ordered"></button>
                        <button type="button" class="ql-list" value="bullet"></button>
                    </span>
                </ng-template>
            </p-editor>
        </div>
    </div>
    <div class="mt-3">
        <p-checkbox id="customizeRedirection" [binary]="true" styleClass="mr-2"
            label="Quiero personalizar la sección destino del asistente cuando pulsa en la notificación."
            [(ngModel)]="customRedirection"></p-checkbox>
        <div *ngIf="customRedirection" class="mt-3">
            <p-dropdown class="mr-3" [options]="serviceOptions" [(ngModel)]="selectedService"
                placeholder="Selecciona una sección"></p-dropdown>
        </div>
    </div>
    <div class="flex justify-content-end mt-4">
        <p-button label="Enviar" icon="pi pi-send" (click)="sendMessage()"
            [disabled]="!message || message?.trim().length === 0" [loading]="loading"></p-button>
    </div>
</p-sidebar>