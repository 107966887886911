import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventComponent } from './event.component';
import { EventRoutingModule } from './event-routing.module';
import { CoreModule } from '../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { Button, ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { CreateEventComponent } from './components/create-event/create-event.component';
import { StepsModule } from 'primeng/steps';
import { CardModule } from 'primeng/card';
import { StepDescriptionComponent } from './components/step-description/step-description.component';
import { StepVenueComponent } from './components/step-venue/step-venue.component';
import { StepTicketsComponent } from './components/step-tickets/step-tickets.component';
import { StepConfirmationComponent } from './components/step-confirmation/step-confirmation.component';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { TagModule } from 'primeng/tag';
import { EditorModule } from 'primeng/editor';
import { GoogleMapsModule } from '@angular/google-maps';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import { EventDetailDescriptionComponent } from './components/event-detail-description/event-detail-description.component';
import { EventDetailVenueComponent } from './components/event-detail-venue/event-detail-venue.component';
import { EventDetailTicketsComponent } from './components/event-detail-tickets/event-detail-tickets.component';
import { EventDetailContentComponent } from './components/event-detail-content/event-detail-content.component';
import { AddContentDialogComponent } from './components/add-content-dialog/add-content-dialog.component';
import { UpdateContentDialogComponent } from './components/update-content-dialog/update-content-dialog.component';
import { ImageModule } from 'primeng/image';
import { ToastModule } from 'primeng/toast';
import { EventDetailExperiencesComponent } from './components/event-detail-experiences/event-detail-experiences.component';
import { ExperienceItemComponent } from './components/experience-item/experience-item.component';
import { DividerModule } from 'primeng/divider';
import { TicketItemComponent } from './components/ticket-item/ticket-item.component';
import { EventDetailAttendeesComponent } from './components/event-detail-attendees/event-detail-attendees.component';
import { SharedModule } from '../shared/shared.module';
import { EventDetailSalesComponent } from './components/event-detail-sales/event-detail-sales.component';
import { EventDetailSponsorsComponent } from './components/event-detail-sponsors/event-detail-sponsors.component';
import { SponsorItemComponent } from './components/sponsor-item/sponsor-item.component';
import { PanelModule } from 'primeng/panel';
import { MultiSelectModule } from 'primeng/multiselect';
import { EventDetailSummaryComponent } from './components/event-detail-summary/event-detail-summary.component';
import { StepTicketsAssignationComponent } from './components/step-tickets/components/step-tickets-assignation/step-tickets-assignation.component';
import { EventDetailMessagesComponent } from './components/event-detail-messages/event-detail-messages.component';
import { StepShareComponent } from './components/step-share/step-share.component';
import { EventQrComponent } from './components/event-qr/event-qr.component';
import { EventDetailPublicRelationsComponent } from './components/event-detail-public-relations/event-detail-public-relations.component';
import { CreateRelationsDialogComponent } from './components/create-relations-dialog/create-relations-dialog.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SelectButtonModule } from 'primeng/selectbutton';
import { EventDetailStreamingComponent } from './components/event-detail/event-detail-streaming/event-detail-streaming.component';
import { EventDetailCatalogItemComponent } from './components/event-detail-catalog-item/event-detail-catalog-item.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { EventDetailMomentsComponent } from './components/event-detail/event-detail-moments/event-detail-moments.component';
import { EventDetailCouponsComponent } from './components/event-detail/event-detail-coupons/event-detail-coupons.component';
import { AddCouponDialogComponent } from './components/event-detail/event-detail-coupons/add-coupon-dialog/add-coupon-dialog.component';
import { OrderListModule } from 'primeng/orderlist';
import { StepChannelsComponent } from './components/step-channels/step-channels.component';
import { AttendantDetailPanelComponent } from './components/attendant-detail-panel/attendant-detail-panel.component';
import { SidebarModule } from 'primeng/sidebar';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CustomToggleComponent } from './components/ticket-item/components/custom-toggle/custom-toggle.component';
import { TicketChangeDialogComponent } from './components/ticket-item/components/ticket-change-dialog/ticket-change-dialog.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { SecondaryMarketReportComponent } from './components/event-detail-summary/reports/secondary-market-report/secondary-market-report.component';
import { GeneralMarketReportComponent } from './components/event-detail-summary/reports/general-market-report/general-market-report.component';
import { SkeletonModule } from 'primeng/skeleton';

@NgModule({
    declarations: [
        EventComponent,
        CreateEventComponent,
        StepDescriptionComponent,
        StepVenueComponent,
        StepTicketsComponent,
        StepConfirmationComponent,
        EventDetailComponent,
        EventDetailDescriptionComponent,
        EventDetailVenueComponent,
        EventDetailTicketsComponent,
        EventDetailContentComponent,
        AddContentDialogComponent,
        UpdateContentDialogComponent,
        EventDetailExperiencesComponent,
        ExperienceItemComponent,
        TicketItemComponent,
        EventDetailAttendeesComponent,
        EventDetailSalesComponent,
        EventDetailSponsorsComponent,
        SponsorItemComponent,
        StepTicketsAssignationComponent,
        EventDetailSummaryComponent,
        EventDetailMessagesComponent,
        StepShareComponent,
        EventQrComponent,
        EventDetailPublicRelationsComponent,
        CreateRelationsDialogComponent,
        EventDetailStreamingComponent,
        EventDetailCatalogItemComponent,
        EventDetailMomentsComponent,
        EventDetailCouponsComponent,
        AddCouponDialogComponent,
        StepChannelsComponent,
        AttendantDetailPanelComponent,
        CustomToggleComponent,
        TicketChangeDialogComponent,
        SecondaryMarketReportComponent,
        GeneralMarketReportComponent
    ],
    providers: [
        ConfirmationService,
        MessageService
    ],
    imports: [
        CommonModule,
        CoreModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        EventRoutingModule,
        TableModule,
        ButtonModule,
        InputTextModule,
        InputNumberModule,
        InputSwitchModule,
        MenuModule,
        StepsModule,
        CardModule,
        DropdownModule,
        CheckboxModule,
        InputMaskModule,
        InputTextareaModule,
        FileUploadModule,
        TooltipModule,
        DialogModule,
        CalendarModule,
        TagModule,
        EditorModule,
        MenuModule,
        GoogleMapsModule,
        ConfirmDialogModule,
        ImageModule,
        ToastModule,
        DividerModule,
        PanelModule,
        MultiSelectModule,
        InputSwitchModule,
        AutoCompleteModule,
        SelectButtonModule,
        ScrollPanelModule,
        OrderListModule,
        SidebarModule,
        ToggleButtonModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        SkeletonModule
    ]
})
export class EventModule { }
