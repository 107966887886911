import { Component, Input, OnInit } from '@angular/core';
import { EventContent } from '../../model/event-detail.interface';
import { ContentService } from '../../service/content.service';
import { LinkUtils } from '../../util/link.util';

@Component({
  selector: 'app-event-detail-content',
  templateUrl: './event-detail-content.component.html',
  styleUrls: ['./event-detail-content.component.scss']
})
export class EventDetailContentComponent implements OnInit {

  displayAddContentDialog: boolean;
  displayUpdateContentDialog: boolean;
  selectedContentId: string;

  @Input() eventId: string;
  @Input() content: EventContent[];

  constructor(private contentService: ContentService) { }

  ngOnInit(): void {
    this.displayAddContentDialog = false;
  }

  showAddContentDialog() {
    this.displayAddContentDialog = true;
  }

  updateContent(elementId: string): void {
    this.selectedContentId = elementId;
    this.displayUpdateContentDialog = true;
  }

  getAccessControl(accessControl): string | undefined {
    if (!accessControl) {
      return undefined;
    }
    if (accessControl === 'attendant') {
      return 'Asistentes';
    }
    if (accessControl === 'fan') {
      return 'Fans';
    }
    if (accessControl === 'public') {
      return 'Público';
    }

    return undefined;
  }


  copyLink(contentId: string) {

    this.contentService.getSignedUrl(this.eventId, contentId).subscribe(response => {

      window.open(response.url, '_blank')
    });
  }

  removeContent(elementId: string): void {

    this.contentService.removeContent(this.eventId, elementId).subscribe(() => {
      const index = this.content.findIndex(element => element.id === elementId);
      if (index !== -1) {
        this.content.splice(index, 1);
      }
    });
  }

  hideDialog(dialogName: string, $event: any): void {

    if (dialogName === 'add') {
      this.displayAddContentDialog = false;
      if ($event) {
        this.content.push($event);
      }
      return;
    }
    if (dialogName === 'update') {
      this.displayUpdateContentDialog = false;
      if ($event) {
        const index = this.content.findIndex(element => element.id === $event.id);
        if (index !== -1) {
          this.content[index].accessControl = $event.accessControl;
        }
      }
      return;
    }
  }

  closeDialog() {
    this.displayAddContentDialog = false;
    this.displayUpdateContentDialog = false;
  }

  roundSize(size: number): string {

    if (size < 1024) {
      return size + ' B';
    }

    if (size >= 1024 && size < 1048576) {
      return (Math.round(size / 1024 * 100) / 100).toFixed(0) + ' KB';
    }

    return (Math.round(size / 1024 / 1024 * 100) / 100).toFixed(2) + ' MB';
  }

}
