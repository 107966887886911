import { Component, Input, OnInit } from '@angular/core';
import { EventService } from '../../service/event.service';
import { MessageService } from 'primeng/api';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-event-detail-catalog-item',
  templateUrl: './event-detail-catalog-item.component.html',
  styleUrls: ['./event-detail-catalog-item.component.scss']
})
export class EventDetailCatalogItemComponent implements OnInit {

  @Input() eventId: string;
  @Input() services: string[];
  @Input() item: any;

  constructor(private eventService: EventService, private messageService: MessageService, private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {

    this.$gaService.event('catalog_view', 'catalog', 'Module disable', 1, undefined, {
      eventId: this.eventId
    });
  }

  /**
   * Checks if the service is enabled for the given event
   * 
   * @param serviceName the service to check
   * @returns boolean value representing if the service is enabled
   */
  isEnabled(serviceName: string): boolean {

    if (!this.services) {

      return false;
    }

    return this.services.indexOf(serviceName) !== -1;
  }

  enable(service: string): void {
    if (this.isEnabled(service)) {

      this.eventService.disableService(this.eventId, service).subscribe(() => {
        this.services.splice(this.services.indexOf(service), 1);
        this.messageService.add({ severity: 'success', summary: 'Se ha desactivado el servicio', detail: '' });
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Inténtalo de nuevo en unos minutos' });

      });

      this.$gaService.event('module_disable', 'catalog', 'Module disable', 1, undefined, {
        eventId: this.eventId,
        service: service
      });

    } else {
      this.eventService.enableService(this.eventId, service).subscribe(() => {
        if (!this.services) this.services = [];
        this.services.push(service);
        this.messageService.add({ severity: 'success', summary: 'Se ha activado el servicio', detail: '' });
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Inténtalo de nuevo en unos minutos' });
      });

      this.$gaService.event('module_enable', 'catalog', 'Module enable', 1, undefined, {
        eventId: this.eventId,
        service: service
      });
    }
  }

}
