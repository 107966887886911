<div class="card h-full w-full">
  <p-card *ngIf="purchase$ | async as purchase">
    <ng-template pTemplate="title">Detalle de compra</ng-template>
    <ng-template pTemplate="subtitle">Consulta el detalle completo de compra</ng-template>
    <div class="flex flex-column">
      <div class="flex flex-row align-items-center">
        <h4>Estado:&nbsp;</h4>
        <p-tag [severity]="getSeverity(purchase.state)" [value]="purchase?.state"></p-tag>
      </div>
      <div class="flex flex-row align-items-center">
        <h4>Identificador de la compra:&nbsp;</h4>
        <p>{{ purchase.id }}</p>
        <button pButton pRipple type="button" icon="pi pi-copy" class="p-button-rounded p-button-text"
          (click)="copyLink(purchase.id)"></button>
      </div>
      <div class="flex flex-row align-items-center">
        <h4>Nombre de la experiencia:&nbsp;</h4>
        <a [routerLink]="['/events', purchase.eventId]">{{
          purchase.eventName
          }}</a>
      </div>
      <div class="flex flex-row align-items-center">
        <h4>Importe total:&nbsp;</h4>
        <p *ngIf="purchase?.totalPrice; else free">
          {{
          purchase.totalPrice / 100 | currency : "EUR" : "symbol" : "1.2-2"
          }}
        </p>
        <ng-template #free>
          <p>{{ 0 | currency : "EUR" : "symbol" : "1.2-2" }}</p>
        </ng-template>
      </div>
      <div>
        <h4>Entradas</h4>
        <app-purchase-detail-tickets [eventId]="purchase.eventId"
          [purchaseId]="purchase.id"></app-purchase-detail-tickets>
      </div>
      <div>
        <h4>Productos</h4>
        <app-purchase-detail-products [products]="purchase.products"></app-purchase-detail-products>
      </div>
    </div>
  </p-card>
</div>