<div class="flex flex-row timeline-item" (click)="openPurchaseDetail()">
  <!-- Col: purhcase detail-->
  <div class="flex-column w-6 p-2">
    <!-- Price -->
    <div class="flex flex-row mt-3 align-items-baseline">
      <div>
        <h3 *ngIf="purchase?.totalPrice; else free">
          {{
            purchase.totalPrice / 100 | currency : "EUR" : "symbol" : "1.2-2"
          }}
        </h3>
        <ng-template #free>
          <h3>{{ 0 | currency : "EUR" : "symbol" : "1.2-2" }}</h3>
        </ng-template>
      </div>
      <div class="ml-3">
        <p class="secondary">{{ purchase?.paymentMethod }}</p>
      </div>
    </div>
    <div class="flex flex-row mt-3">
      <p class="secondary">{{ purchase?.createdAt | date : "medium" }}</p>
    </div>
    <!-- Status -->
    <div class="flex flex-row mt-3">
      <p-tag
        [severity]="getSeverity(purchase.state)"
        [value]="purchase?.state"
      ></p-tag>
    </div>
    <!-- Detail-->
    <div class="flex flex-row justify-content-between mt-3">
      <div pTooltip="Entradas" tooltipPosition="top">
        <i class="pi pi-ticket mr-2" style="font-size: 1rem"></i>
        <span>{{ purchase.tickets?.length }}</span>
      </div>
      <div pTooltip="Personalización" tooltipPosition="top">
        <i class="pi pi-angle-double-up mr-2" style="font-size: 1rem"></i>
        <span>{{ purchase.experiences?.length }}</span>
      </div>
      <div pTooltip="Complementos" tooltipPosition="top">
        <i class="pi pi-shopping-cart mr-2" style="font-size: 1rem"></i>
        <span>{{ purchase.products?.length }}</span>
      </div>
    </div>
  </div>
  <!-- Col: event detail -->
  <div class="flex flex-column w-5">
    <div class="mt-3">
      <p>Experiencia</p>
      <h4>{{ purchase?.eventName }}</h4>
    </div>
  </div>
  <div class="flex flex-column align-items-end justify-content-center">
    <button
      pButton
      type="button"
      class="p-button-text p-button-rounded"
      icon="pi pi-chevron-right"
    ></button>
  </div>
</div>
