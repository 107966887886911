<p-toast position="top-right"></p-toast>
<div class="card h-full">
  <div class="card-container justify-content-start border-round p-3">
    <div class="flex flex-row align-items-center justify-content-between mb-3" style="flex: 0 1 auto;">
      <div class="flex-grow-1">
        <h4>{{ fullName !== 'Desconocido'? fullName: user?.email}}</h4>
      </div>
      <div class="flex flex-row align-items-center">
        <!-- Actions row -->
      </div>
    </div>

    <div class="flex flex-row">
      <!-- The secondary menu -->
      <div>
        <p-menu [model]="items"></p-menu>
      </div>
      <!-- The content -->
      <div class="content ml-3">
        <!-- Section summary -->
        <div *ngIf="!section || section === 'summary'">
          <p-card>
            <ng-template pTemplate="title">Ciclo de vida</ng-template>
            <ng-template pTemplate="subtitle">Resumen de fidelización del fan</ng-template>
            <div class="flex align-items-start justify-content-start align-items-center analytics-row">
              <div #chartTotalTokens class="box-25"></div>
              <div #chartTotalPurchases class="box-25"></div>
              <div #chartTotalPurchased class="box-25"></div>
              <div #chartTotalUpselling class="box-25"></div>
            </div>
          </p-card>
        </div>
        <!-- Section data -->
        <div *ngIf="section === 'profile'">
          <p-card>
            <ng-template pTemplate="title">Datos del fan</ng-template>
            <ng-template pTemplate="subtitle">Datos personales y estado del fan</ng-template>
            <div class="flex flex-column">
              <div>
                <span class="mb-2 font-semibold">Nombre</span>
                <p>{{ getFullName() }}</p>
              </div>
              <div *ngIf="user?.email">
                <div>
                  <span class="mb-2 font-semibold">Correo electrónico</span>
                </div>
                <div class="flex flex-row align-items-center">
                  <a [href]="'mailto:' + user?.email">{{ user?.email }}</a>
                  <p-button icon="pi pi-copy" styleClass="p-button-rounded p-button-text"
                    (click)="copyToClipboard(user?.email)"></p-button>
                </div>
              </div>
              <div>
                <span class="mb-2 font-semibold">Teléfono</span>
                <div class="flex flex-row align-items-center" *ngIf="user?.phone; else empty">
                  <a [href]="'tel:' + user?.phone">{{ user?.phone }}</a>
                  <p-button icon="pi pi-copy" styleClass="p-button-rounded p-button-text"
                    (click)="copyToClipboard(user?.phone)"></p-button>
                </div>
                <ng-template #empty>
                  <p>Desconocido</p>
                </ng-template>
              </div>
              <div>
                <span class="mb-2 font-semibold">Estado</span>
                <p><p-tag [value]="getState(user?.state)"></p-tag></p>
              </div>
              <div>
                <span class="mb-2 font-semibold">Identificación fiscal</span>
                <p *ngIf="user?.fiscalId; else empty">{{ user?.fiscalId }}</p>
              </div>
              <div>
                <span class="mb-2 font-semibold">Fecha de nacimiento</span>
                <p *ngIf="user?.birthDate; else empty">{{ user?.birthDate | date : "dd/MM/yyyy" }}</p>
              </div>
              <div>
                <span class="mb-2 font-semibold">Género</span>
                <p *ngIf="user?.genre; else empty">{{ user?.genre }}</p>
              </div>
            </div>
          </p-card>
        </div>
        <!-- Section public profile -->
        <div *ngIf="section === 'public'">
          <app-public-profile [user]="user"></app-public-profile>
        </div>
        <!-- Section tier -->
        <div *ngIf="section === 'tier'">
          <p-card>
            <ng-template pTemplate="title">Etiquetas</ng-template>
            <ng-template pTemplate="subtitle">Añade las etiquetas que quieras al usuario</ng-template>
            <div class="flex flex-column">
              <app-label-input [labels]="user?.labels" (onLabelAdd)="onLabelAdd($event)"
                (onLabelRemove)="onLabelRemove($event)"></app-label-input>
            </div>
          </p-card>
        </div>
        <!-- Section tokens -->
        <div *ngIf="section === 'tokens'">
          <app-token-history [userId]="user?.id"></app-token-history>
        </div>
        <!-- Section purchases -->
        <div *ngIf="section === 'purchases'">
          <app-user-history [purchases]="purchases$"></app-user-history>
        </div>
      </div>
    </div>
  </div>
</div>