<div class="grid mb-4">
    <div class="col-6">
      <div class="grid h-full">
        <div class="col-4" *ngFor="let kpi of kpiData">
          <p-card styleClass="h-full">
            <div class="kpi-card">
              <div class="kpi-title">{{ kpi.title }}</div>
              <div class="kpi-value">{{ kpi.value }}</div>
            </div>
          </p-card>
        </div>
      </div>
    </div>
    <div class="col-6">
      <p-card styleClass="h-full">
        <div class="chart-container">
          <div class="chart-header">
            <h4 class="chart-title">Cronología de reasignaciones</h4>
            <p class="chart-subtitle">Asignaciones a lo largo del tiempo</p>
          </div>
          <div #chart class="chart" style="height: 200px;"></div>
        </div>
      </p-card>
    </div>
  </div>