import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Coupon } from '../model/coupon.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  createCoupon(eventId: string, coupon: Coupon): Observable<Coupon> {
    return this.http.post<Coupon>(`${this.apiUrl}/events/${eventId}/coupons`, coupon);
  }

  getCouponsByEventId(eventId: string): Observable<Coupon[]> {
    return this.http.get<Coupon[]>(`${this.apiUrl}/events/${eventId}/coupons`);
  }

  updateCoupon(eventId: string, couponId: string, coupon: Coupon): Observable<Coupon> {
    return this.http.put<Coupon>(`${this.apiUrl}/events/${eventId}/coupons/${couponId}`, coupon);
  }

  deleteCoupon(eventId: string, couponId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/events/${eventId}/coupons/${couponId}`);
  }
}