import { DateTime } from "luxon";

export class DateUtil {

  static fromUTC(utcDate: string | Date): Date {

    let formatedDate = utcDate;

    if (typeof formatedDate === 'string') {

      if (formatedDate.endsWith('Z')) {
        formatedDate = formatedDate.substring(0, formatedDate.length - 1);
      }

      if (!formatedDate.includes('.')) {
        formatedDate += '.000';
      }

      if (!formatedDate.endsWith('Z')) {
        formatedDate += 'Z';
      }
    }

    return DateTime.fromISO(new Date(formatedDate).toISOString(), { zone: "UTC" }).toJSDate()

    //return DateTime.fromISO(new Date(formatedDate).toISOString()).toJSDate()
  }
}