import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Message } from '../../model/message.interface';
import { EventService } from '../../service/event.service';
import { MessageService, SelectItem } from 'primeng/api';
import { getEventSectionsSuffixes } from 'src/app/shared/util/MessageUtil';

@Component({
  selector: 'app-event-detail-messages',
  templateUrl: './event-detail-messages.component.html',
  styleUrls: ['./event-detail-messages.component.scss']
})
export class EventDetailMessagesComponent implements OnChanges {

  @Input() eventId: string;

  newMessage: string = '';
  loading: boolean = false;

  accessControl: string = 'attendant';
  messages: Message[] = [];
  displayRedirectionDialog: boolean = false;
  services: string[] = [];
  serviceOptions: SelectItem[] = [];
  customRedirection: boolean = false;
  selectedService: string = '/messages';

  constructor(private eventService: EventService, private messageService: MessageService) { }


  ngOnChanges(changes: SimpleChanges): void {

    if (changes['eventId'] && changes['eventId'].currentValue) {
      this.eventService.getMessages(this.eventId).subscribe((response: Message[]) => this.messages = response);
      this.fetchSectionsSuffixes();
    }
  }

  showRedirectionDialog(): void {
    if (!this.newMessage || this.newMessage.trim().length === 0) {
      return;  // Do nothing if the message is not valid
  }
    this.displayRedirectionDialog = true;
  }

  fetchSectionsSuffixes(): void {

    this.eventService.getServices(this.eventId).subscribe((response: any) => {
      this.serviceOptions = getEventSectionsSuffixes(response);
    });
  }

  sendMessage(): void {

    if (!this.newMessage || this.newMessage.trim().length === 0 || this.loading) {
      return;
    }

    this.loading = true;
  
    this.eventService.postMessage(this.eventId, { content: this.newMessage, redirectToSuffix: this.selectedService }).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Mensaje publicado correctamente' });
        this.messages.unshift({
          id: '',
          content: this.newMessage,
          publishedAt: new Date()
        });
        this.newMessage = '';
        this.loading = false;
      },
      () => {
        this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Por favor, vuelve a intentarlo' });
        this.loading = false;
      }
    );
  }

  deleteMessage(messageId: string): void {

    this.eventService.deleteMessage(this.eventId, messageId).subscribe(() => {

      this.messageService.add({ severity: 'success', summary: 'Mensaje borrado correctamente' });

    }, () => this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Por favor, vuelve a intentarlo' }));
  }

}
