import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Message } from 'src/app/event/model/message.interface';
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() message: Message;
  @Output() delete: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }
  ngOnInit(): void {
  }
  deleteMessage(): void {
    this.delete.emit(this.message.id);
  }
}