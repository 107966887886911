import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { forkJoin } from 'rxjs';
import { Coupon } from 'src/app/event/model/coupon.interface';
import { CouponService } from 'src/app/event/service/coupon.service';
import { EventService } from 'src/app/event/service/event.service';
import { LinkUtils } from 'src/app/event/util/link.util';
import { MerchService } from 'src/app/merch/service/merch.service';

@Component({
  selector: 'app-event-detail-coupons',
  templateUrl: './event-detail-coupons.component.html',
  styleUrls: ['./event-detail-coupons.component.scss']
})
export class EventDetailCouponsComponent implements OnInit {

  @Input() eventId: string;

  coupons: Coupon[] = [];
  displayAddCouponDialog: boolean = false;
  selectedCoupon: any = null;

  ticketTypes: any[] = [];
  complements: any[] = [];
  experiences: any[] = [];

  constructor(
    private messageService: MessageService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private merchService: MerchService,
    private couponService: CouponService
  ) { }
  

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      this.loadCoupons();
      this.loadEventData();
    });

  }

  loadCoupons(): void {
    this.couponService.getCouponsByEventId(this.eventId).subscribe(
      response => {
        this.coupons = response;
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar los cupones' });
      }
    );
  }

  loadEventData(): void {
    forkJoin({
      ticketTypes: this.eventService.retrieveTicketTypes(this.eventId),
      complements: this.merchService.getMerch(),
      experiences: this.eventService.getExperiences(this.eventId)
    }).subscribe(
      results => {
        this.ticketTypes = results.ticketTypes.map(ticketType => ({
          id: ticketType.id,
          name: ticketType.name
        }));
         this.complements = results.complements.map(complement => ({
          id: complement.id,
          name: complement.itemName
        }));

        this.experiences = results.experiences[0]?.experiences.map(experience => ({
          id: experience.id,
          name: experience.name
        })) || [];

      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar los datos relativos a los cupones' });
      }
    );
  }

  clear(table: Table) {
    table.clear();
  }

  copyCoupon(code: string): void {
    LinkUtils.copy(code);
    this.messageService.add({ severity: 'success', summary: 'Copiado', detail: 'El cupón ha sido copiado al portapapeles' });
  }

  deleteCoupon(id: string): void {
    this.couponService.deleteCoupon(this.eventId, id).subscribe(
      () => {
        this.coupons = this.coupons.filter(coupon => coupon.id !== id);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Cupón eliminado exitosamente' });
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al eliminar el cupón' });
      }
    );
  }

  hideAddCouponDialog(): void {
    this.displayAddCouponDialog = false;
    this.selectedCoupon = null;
  }

  addCoupon(): void {
    this.selectedCoupon = null;
    this.displayAddCouponDialog = true;
  }

  editCoupon(coupon: any): void {
    this.selectedCoupon = { ...coupon };
    this.displayAddCouponDialog = true;
  }

  onCouponSaved(coupon: Coupon): void {

    if (this.isDuplicateCouponCode(coupon.code, coupon.id)) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El código del cupón ya existe para este evento' });
      return;
    }

    if (this.selectedCoupon) {
      // Update existing coupon
      this.couponService.updateCoupon(this.eventId, coupon.id, coupon).subscribe(
        updatedCoupon => {
          const index = this.coupons.findIndex(c => c.id === updatedCoupon.id);
          if (index !== -1) {
            this.coupons[index] = updatedCoupon;
          }
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Cupón actualizado exitosamente' });
          this.hideAddCouponDialog();
        },
        error => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar el cupón' });
        }
      );
    } else {
      // Add new coupon
      this.couponService.createCoupon(this.eventId, coupon).subscribe(
        newCoupon => {
          this.coupons.push(newCoupon);
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Cupón creado exitosamente' });
          this.hideAddCouponDialog();
        },
        error => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al crear el cupón' });
        }
      );
    }
  }
  
  isDuplicateCouponCode(code: string, id?: string): boolean {
    return this.coupons.some(coupon => coupon.code === code && coupon.id !== id);
  }

}
