import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class WithCredentialsInterceptor implements HttpInterceptor {

  constructor(private cookieService: CookieService) { }

  private static setAuthorizationHeader(req: HttpRequest<any>, accessToken: string): HttpRequest<any> {

    if (accessToken && accessToken !== '' && !req.url.includes('amazonaws.com')) {

      const authorizationToken = `Bearer ${accessToken}`;
      const headers = req.headers.set('Authorization', authorizationToken);
      return req.clone({ headers });
    } else {

      return req;
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    const authorizedRequest = this.setHeaders(request);
    const handledRequest = next.handle(authorizedRequest);
    // @ts-ignore
    const interceptionOperator = catchError(error => {
      if (error.error instanceof ErrorEvent) {
        // client-side error
      } else {
        // server-side error
        if (error.status === 401 || error.status === 403) {
          window.location.replace(`${environment.authUrl}?appName=${environment.appName}&origin=${window.location}`);
        } else {
          return throwError(error);
        }
      }
    });
    return handledRequest.pipe(interceptionOperator);
  }

  private setHeaders = (req: HttpRequest<any>): HttpRequest<any> => {

    const accessToken = this.cookieService.get('access_token');

    req = WithCredentialsInterceptor.setAuthorizationHeader(req, accessToken);

    return req;
  }

}
