import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CreatedEvent } from '../../model/created-event.interface';
import { FormPart } from '../../model/form-part.interface';
import { EventService } from '../../service/event.service';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {

  newEvent: any;
  startDate: Date;
  activeStep = 0;
  success = false;
  eventId: string = undefined;
  createdEvent: CreatedEvent;
  loading = false;
  endDate: Date;

  data = new FormGroup({
  });

  formValue: any;

  baseSteps = [
    { label: 'Descripción' },
    { label: 'Celebración' },
    { label: 'Canales de venta' },
    { label: 'Revisión' }
  ];
    stepItems = [...this.baseSteps];

    isEntradasStepAdded = false;

  constructor(private eventService: EventService, private activatedRoute: ActivatedRoute, private router: Router, private messageService: MessageService) { }

  ngOnInit(): void {
    const id = this.activatedRoute.params.subscribe((params) => {
      if (params['id']) {
        this.eventId = params['id'];
        this.eventService.getEventById(params['id']).subscribe((data: any) => {
          this.formValue = data;
        });
      }
    });
  }

  onNext($event: FormPart) {
    if ($event.next === 1) {
      this.activeStep += $event.next;

      // If navigating from "Canales de venta" and "Entradas" not added, insert it
      if ($event.name === 'canales' && !this.isEntradasStepAdded) {
        const canalesIndex = this.stepItems.findIndex(step => step.label === 'Canales de venta');
        if (canalesIndex !== -1) {
          // Insert "Entradas" after "Canales de venta"
          this.stepItems.splice(canalesIndex + 1, 0, { label: 'Entradas' });
          this.isEntradasStepAdded = true;
        }
      }
    } else if ($event.next === -1) {

      this.activeStep += $event.next; // Decrement activeStep

      // If navigating back from "Entradas", remove it
      if ($event.name === 'tickets' && this.isEntradasStepAdded) {
        const entradasIndex = this.stepItems.findIndex(step => step.label === 'Entradas');
        if (entradasIndex !== -1) {
          this.stepItems.splice(entradasIndex, 1);
          this.isEntradasStepAdded = false;
        }
      }
    }

    if ($event.name !== undefined && $event.data !== undefined) {
      this.data.setControl($event.name, $event.data);
      if ($event.name === 'venue') {
        this.startDate = new Date($event.data.value.startDate);
        this.endDate = new Date($event.data.value.endDate);
      }
    }
  }

  onSave($event: FormPart) {
    // Update the form part
    // FIXME: extract common logic
    if ($event.name !== undefined && $event.data !== undefined) {
      this.data.setControl($event.name, $event.data);
      if ($event.name === 'venue') {
        this.startDate = new Date($event.data.value.startDate);
        this.endDate = new Date($event.data.value.endDate);
      }
    }

    const formattedData = this.formatPaymentMethodData(this.data.value);

    this.eventService.createDraft(formattedData).subscribe((response: CreatedEvent) => {
      this.messageService.add({ severity: 'success', summary: 'Borrador guardado', detail: '' });
      this.router.navigate(['/events', response.id]);
    });

  }

  onComplete($event: boolean) {
    this.loading = true;
    if ($event) {

      const formattedData = this.formatPaymentMethodData(this.data.value);

      if (this.eventId !== undefined) {
        this.eventService.updateEvent(this.eventId, formattedData, '').subscribe((response: CreatedEvent) => {
          this.loading = false;
          if (response.url !== undefined) {
            this.messageService.add({ severity: 'success', summary: 'Experiencia actualizada', detail: '' });
            this.success = true;
            this.createdEvent = response;
            this.activeStep = this.stepItems.length;
          }
        }, () => {
          this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Revisa los datos y vuelve a intentarlo' });
          this.loading = false;
        });

      } else {
        this.eventService.createEvent(formattedData).subscribe((response: CreatedEvent) => {
          this.loading = false;
          this.createdEvent = response;
          if (response.url !== undefined) {
            this.messageService.add({ severity: 'success', summary: 'Experiencia creada', detail: '' });
            this.success = true;
            this.createdEvent = response;
            this.activeStep = this.stepItems.length; 
          }
        }, () => {
          this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Revisa los datos y vuelve a intentarlo' });
          this.loading = false;
        });
      }
    }
  }

  private formatPaymentMethodData(formData: any): any {
    const formattedData = { ...formData };
    
    // Check if tickets array exists
    if (formattedData.tickets && Array.isArray(formattedData.tickets)) {
      formattedData.tickets = formattedData.tickets.map(ticket => {
        const formattedTicket = { ...ticket };
        
        // Transform payment method data structure
        if (formattedTicket.paymentMethod || formattedTicket.paymentDirections) {
          formattedTicket.paymentMethod = {
            type: formattedTicket.paymentMethod,
            directions: formattedTicket.paymentMethod === 'other' ? formattedTicket.paymentDirections : undefined
          };
          
          // Remove the separate paymentDirections field
          delete formattedTicket.paymentDirections;
        }
        
        return formattedTicket;
      });
    }
    
    return formattedData;
  }

  onPrev() {

    this.activeStep -= 1;

    // If navigating back from "Entradas", remove it from steps
    if (this.activeStep === this.stepItems.findIndex(step => step.label === 'Entradas') - 1 && this.isEntradasStepAdded) {
      const entradasIndex = this.stepItems.findIndex(step => step.label === 'Entradas');
      if (entradasIndex !== -1) {
        this.stepItems.splice(entradasIndex, 1);
        this.isEntradasStepAdded = false;
      }
    }
  }

}
