<div class="stepsdemo-content table-container">
  <p-card>
    <ng-template pTemplate="title"> Canales de venta </ng-template>
    <ng-template pTemplate="subtitle">
      Conecta tus canales y centraliza la gestión
    </ng-template>
    <div class="content-wrapper">
      <!-- Form Section -->
      <form [formGroup]="channelForm" class="p-fluid">
        
        <!-- Category Chips -->
        <div class="mb-6 flex justify-center">
          <div class="channel-tabs-container">
            <div class="channel-tabs">
              <button 
                *ngFor="let tab of tabOptions" 
                type="button" 
                class="chip-button" 
                [class.active]="channelForm.get('activeTab')?.value === tab.value"
                (click)="channelForm.get('activeTab')?.setValue(tab.value)"
                tabindex="0"
                [attr.aria-selected]="channelForm.get('activeTab')?.value === tab.value"
              >
                {{ tab.label }}
              </button>
            </div>
          </div>
        </div>
        
        <!-- Channel Containers -->
        <div class="channel-container p-5 bg-gray-50 rounded-2xl shadow-sm max-w-4xl mx-auto">
          <div class="channel-grid">
            <div 
              *ngFor="let channel of displayedChannels" 
              class="channel-option" 
              [class.selected]="channel === selectedChannel"
              (click)="selectChannel(channel)"
              tabindex="0"
              role="button"
              [attr.aria-pressed]="channel === selectedChannel"
            >
              <div class="channel-logo">
                <img [src]="channel.logo" [alt]="channel.name">
              </div>
            </div>
          </div>
        </div>
        
      </form>
      
      <!-- Footer Buttons -->
      <div class="footer-buttons flex justify-between align-items-center mt-4">
        <button pButton label="Atrás" (click)="prevPage()" icon="pi pi-angle-left" class="p-button-text"></button>
        <button pButton label="Siguiente" (click)="nextPage()" icon="pi pi-angle-right" iconPos="right" [disabled]="!selectedChannel" class="p-button-raised"></button>
      </div>
    </div>
  </p-card>

  <!-- Modal Dialog -->
  <p-dialog 
    header="{{ modalChannel?.name }}" 
    [(visible)]="displayModal" 
    [modal]="true" 
    [closable]="true" 
    [dismissableMask]="true"
    [style]="{ width: '800px' }"
    [transitionOptions]="'400ms cubic-bezier(0.25, 0.8, 0.25, 1)'"
  >
    <div class="modal-content" *ngIf="!emailSent; else thankYouTemplate">
      <h2 class="text-center">Para activar {{ modalChannel?.name }}, contacta con nuestro equipo de Customer Success</h2>
      <div class="modal-image-container">

        <img src="https://i.imgur.com/pQooujg.png" alt="Under Construction" width="380" height="380">
      </div>
      <p class="text-center">Nuestro equipo te ayudará a activar y configurar este canal para que sólo te preocupes de vender.</p>
      <p class="text-center">
        <button pButton label="Siguiente" (click)="notifyWhenAvailable()" class="p-button-raised"></button>
      </p>
    </div>

    <!-- Thank You Template -->
    <ng-template #thankYouTemplate>
      <div class="modal-content">
        <h2 class="text-center">Gracias por contactar con Customer Success 🎉</h2> 
        <div class="modal-image-container">
          <img src="https://i.imgur.com/pQooujg.png" alt="Under Construction" width="380" height="380">
        </div>
        <p class="text-center">Te contactaremos a la mayor brevedad.</p>
      </div>
    </ng-template>
  </p-dialog>
</div>
