import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventContent } from '../../model/event-detail.interface';
import { ContentService } from '../../service/content.service';

@Component({
  selector: 'app-add-content-dialog',
  templateUrl: './add-content-dialog.component.html',
  styleUrls: ['./add-content-dialog.component.scss']
})
export class AddContentDialogComponent implements OnInit {


  @Input() eventId: string;
  @Input() displayAddContentDialog: boolean;
  @Output() hideDialog: EventEmitter<EventContent> = new EventEmitter<EventContent>();

  loading: boolean = false;
  file: File;
  errorMessage: string;

  contentForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(300)]),
    url: new FormControl('', []),
    contentType: new FormControl('', [Validators.required]),
    mediaType: new FormControl('', [Validators.required]),
    size: new FormControl('', [Validators.required]),
    accessControl: new FormControl('', [Validators.required])
  });

  selectedContentType = '';

  contentTypeOptions = [
    {
      id: "external_url",
      icon: "pi pi-link",
      value: "Enlace"
    },
    {
      id: "file",
      icon: "pi pi-file",
      value: "Archivo"
    }
  ];

  accessControlOptions = [
    {
      id: "public",
      icon: "pi pi-globe",
      value: "Público"
    },
    {
      id: "fan",
      icon: "pi pi-users",
      value: "Exlcusivo para fans"
    }
    ,
    {
      id: "attendant",
      icon: "pi pi-user",
      value: "Exclusivo para asistentes"
    }
  ];

  constructor(private contentService: ContentService) { }

  ngOnInit(): void {
  }

  contentTypeChange(): void {
    if (this.selectedContentType === 'external_url') {
      this.contentForm.get('mediaType').setValue('text/plain');
      this.contentForm.get('size').setValue(0);
    } else {
      this.contentForm.get('mediaType').setValue(undefined);
      this.contentForm.get('mediaType').setValue(undefined);
    }
  }

  addContent(): void {

    this.errorMessage = undefined;
    this.loading = true;
    this.contentService.addContent(this.eventId, this.contentForm.value).subscribe((response: any) => {
      if (response.signedUrl) {
        this.contentService.uploadFile(response.signedUrl, this.file.type, this.file).subscribe((result: any) => {
          const content = this.contentForm.value;
          content.id = response.id;
          this.loading = false;
          this.hideDialog.emit(content);
        }, (err) => {
          // If error when uploading the file, delete the content
          this.contentService.removeContent(this.eventId, response.id).subscribe(() => {
            this.loading = false;
            this.hideDialog.emit(this.contentForm.value);
          });
        });
      } else {
        const content = this.contentForm.value;
        content.id = response.id;
        this.loading = false;
        this.hideDialog.emit(content);
      }
    }, (err: HttpErrorResponse) => {
      switch (err.status) {
        case 409:
          this.errorMessage = '<p>Ya existe un fichero con este nombre, por favor, escoge otro.</p>';
          break;
        case 413:
          this.errorMessage = `<p>No hay espacio suficiente, <a href="${environment.info.improvePlan}" target="_blank">actualiza tu plan</a> para continuar compartiendo contenido.</p>`
          break;
        default:
          this.errorMessage = '<p>Lo sentimos, ha ocurrido un error.</p>';
          break;
      }
      this.loading = false;
    });
  }

  myUploader(files: any): void {

    this.file = files.files[0];

    this.contentForm.patchValue({
      name: this.file.name,
      mediaType: this.file.type,
      size: this.file.size
    });

  }

  onDialogHide(): void {
    this.displayAddContentDialog = false;
    this.contentForm.reset();
    this.hideDialog.emit(undefined);
  }

}
