import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DomainExists } from '../model/domain-exists.interface';
import { Organization } from '../model/organization.interface';
import { AuthService } from '../service/auth.service';
import { getLoggedInUser } from '../store';

@Injectable({
  providedIn: 'root'
})
export class IsConfiguredGuard implements CanActivate {

  constructor(private cookieService: CookieService, private store: Store, private authService: AuthService, private router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.getLoggedInUser();
  }

  getLoggedInUser() {

    return this.store.select(getLoggedInUser).pipe(
      map(data => {
        if (data?.id) {
          // If there is orgnization data on the storage, the user is able to activate the route
          return true;
        } else {
          // If not, we check if we have an authentication cookie on the storage
          const access_token = this.cookieService.get('access_token');

          if (!access_token || access_token === '') {
            // If we do not have an access token, we return false
            window.location.replace(`${environment.authUrl}?appName=${environment.appName}&origin=${window.location}`);
            return false;
          } else {

            // If we have a cookie, we make a request to the backend
            this.authService.isTenantConfigured().subscribe((isConfigured: DomainExists) => {
              if (isConfigured.exists === false) {

                // If the tenant is not configured, we redirect to the complete profile page
                this.router.navigate(['/auth/complete']);
                return false;
              } else {

                // If the tenant is configured, we return true and access the page
                return true;
              }
            }, () => {
              // If any error happens we block the page loading
              return false
            });
          }
        }
        return true;
      })
    );
  }

}
