<div *ngIf="organizationId === '6547c6a70f6fa76418cb3c1a' || organizationId === '65e314de635a695866e5568f' || organizationId === '6356fad159c5940dca3292dd'; else defaultTemplate">
  <div class="event-summary p-3">
    <div class="flex align-items-center justify-content-between mb-4">
      <h2 class="text-xl m-0">Resumen del evento</h2>
      <div class="flex gap-2">
        <p-dropdown 
          [options]="timeframeOptions" 
          [(ngModel)]="selectedTimeframe"
          optionLabel="label"
          optionValue="value"
          styleClass="mr-2"
          (onChange)="onTimeframeChange($event.value)">
          
          <ng-template let-option pTemplate="item">
            <span class="dropdown-item">
              <span class="dropdown-prefix">Periodo:</span>
              <strong class="dropdown-bold">{{ option.label }}</strong>
            </span>
          </ng-template>

          <ng-template let-selected pTemplate="selectedItem">
            <span class="dropdown-item">
              <span class="dropdown-prefix">Periodo:</span>
              <strong class="dropdown-bold">{{ selected.label }}</strong>
            </span>
          </ng-template>
          
        </p-dropdown>
        
        <p-dropdown 
          [options]="reportOptions" 
          [(ngModel)]="selectedReport"
          optionLabel="label"
          optionValue="value"
          (onChange)="onReportChange($event.value)">
          
          <ng-template let-option pTemplate="item">
            <span class="dropdown-item">
              <span class="dropdown-prefix">Reporte:</span>
              <strong class="dropdown-bold">{{ option.label }}</strong>
            </span>
          </ng-template>
          
          <ng-template let-selected pTemplate="selectedItem">
            <span class="dropdown-item">
              <span class="dropdown-prefix">Reporte:</span>
              <strong class="dropdown-bold">{{ selected.label }}</strong>
            </span>
          </ng-template>
          
        </p-dropdown>
      </div>
    </div>

    <ng-container *ngIf="selectedReport === 'general'">
      <app-general-market-report [timeframe]="selectedTimeframe"></app-general-market-report>
    </ng-container>

    <ng-container *ngIf="selectedReport === 'secondary'">
      <app-secondary-market-report [timeframe]="selectedTimeframe"></app-secondary-market-report>
    </ng-container>
  </div>
</div>

<ng-template #defaultTemplate>
  <!-- OLD CODE USING MONGODB ATLAS CHARTS -->
  <h3>Resumen de las ventas</h3>
  <div class="flex align-items-start justify-content-start align-items-center analytics-row">
    <div #chartSalesStatus class="box-25"></div>
    <div #chartTotalBilled class="box-25"></div>
    <div #chartTotalUpselling class="box-25"></div>
  </div>
  <div class="flex mt-3">
    <div #chartTokensByType class="box-50"></div>
    <div #chartExperiencesByType class="box-50"></div>
  </div>
  <div class="flex mt-3">
    <div #chartProductsByType class="box-50"></div>
    <div #chartSalesByAffiliate class="box-50"></div>
  </div>
  <div class="flex mt-3"></div>
</ng-template>
