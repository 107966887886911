import { Component, Input, OnInit } from '@angular/core';
import { PurchaseProduct } from '../../model/purchase-product.interface';
import { Merch } from 'src/app/merch/model/merch';

@Component({
  selector: 'app-purchase-detail-product-item',
  templateUrl: './purchase-detail-product-item.component.html',
  styleUrls: ['./purchase-detail-product-item.component.scss']
})
export class PurchaseDetailProductItemComponent implements OnInit {

  @Input() product: PurchaseProduct;
  @Input() details: Merch[];

  constructor() { }

  ngOnInit(): void {
  }

  getProductAttribute(attributeName: string): string {

    if (!this.details || this.details.length === 0) {
      return '';
    }

    try {
      return this.details.filter((item: Merch) => item.id === this.product.id)[0][attributeName];
    } catch (e: any) {
      return 'Producto desconocido';
    }
    
  }

}
