import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FilterSelection } from '../model/filter-selection.interface';
import { Observable } from 'rxjs';
import { ListInfo } from '../model/list-info.interface';
import { ExportResponse } from 'src/app/shared/model/export-response.interface';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getLists(): Observable<any[]> {
    const url = `${this.baseUrl}/lists`;

    return this.http.get<any[]>(url);
  }

  create(body: { name: string; filters: FilterSelection[] }): Observable<any> {

    const url = `${this.baseUrl}/lists`;

    return this.http.post<any>(url, body);
  }

  getList(listId: string): Observable<ListInfo> {

    const url = `${this.baseUrl}/lists/${listId}`;

    return this.http.get<ListInfo>(url);
  }

  sendPush(listId: string, message: string, redirectToSuffix: string): Observable<void> {

    const url = `${this.baseUrl}/lists/${listId}/message`;

    return this.http.post<void>(url, { message, redirectToSuffix });
  }

  remove(listId: string): Observable<void> {

    const url = `${this.baseUrl}/lists/${listId}`;

    return this.http.delete<void>(url);
  }

  getListResults(listId: string): Observable<any[]> {

    const url = `${this.baseUrl}/lists/${listId}/results`;

    return this.http.get<any[]>(url);
  }

  export(listId: string): Observable<ExportResponse> {

    const url = `${this.baseUrl}/export/list/${listId}/members`;

    return this.http.get<ExportResponse>(url);
  }

}
